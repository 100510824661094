import { EDIT_REGISTRATION, DISPLAY_SPINNER, EDIT_GA_DATA, DISPLAY_SWEET_ALERT} from '../constants/action-types';

export function editRegistration(payload) {
  return { type: EDIT_REGISTRATION, payload };
}

export function displaySpinner(payload) {
  return { type: DISPLAY_SPINNER, payload };
}

export function editGAData(payload) {
  return { type: EDIT_GA_DATA, payload };
}

export function displaySweetAlert(payload) {
  return { type: DISPLAY_SWEET_ALERT, payload };
}
