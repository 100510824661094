import React, {Component} from 'react';
import ReactCardCarousel from 'react-card-carousel';
import "./xcube-card-carousel.scss";

class XcubeCardCarousel extends Component {
    images = [];
    render() {
        this.props.images.map((image, i) => {
           this.images.push(<img src={image} key={i}/>)
        });
        return (
            <div id="xcube-card-carousel">
                <ReactCardCarousel autoplay={ this.props.autoplay } autoplay_speed={ this.props.speed }>
                    {this.images}
                </ReactCardCarousel>
            </div>
        );
    }
}

export default XcubeCardCarousel;