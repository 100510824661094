import React, {Component} from 'react';
import "./xcube-input-file.scss";
import previewImg from "./assets/prize.svg";
import { setDataService } from '../../services/setDataService';

class XcubeInputFile extends Component {
    image;
    constructor(props) {
        super(props);
        this.state = {
          image: ''
        }
    }

    handleChange = function(e){
        let reader = new FileReader();
        let _this = this;
        reader.readAsDataURL(e[0]);
        reader.onloadend = () => {
            _this.image = reader.result;
            setDataService.uploadImage(_this.image).then((result) => {
               if(result.data.message === 'success') {
                 _this.props.onChange({'value': result.data.body, 'name': _this.props.name});
                 _this.setState({
                   image: result.data.body.thumbnail_url
                 });
               }
            });
        };
    };

    render() {
        return (
            <div id="xCubeInputFile">
                <label className="xcube-input-label">{this.props.label}</label>
                <label htmlFor={this.props.id} className="xcube-file-upload">
                    {this.props.label}
                    <img src={this.state.image ? this.state.image : this.props.previewImg ? this.props.previewImg : previewImg} alt=""/>
                </label>
                <input id={this.props.id} type="file" name={this.props.name} onChange={ (e) => this.handleChange(e.target.files) }/>
            </div>
        );
    }
}

export default XcubeInputFile;
