import {useState} from "react";
import Modal from "react-bootstrap/Modal";
import React from "react";
import Row from "react-bootstrap/Row";
import "./insert-modal.scss";
import winner from "./assets/winner-name.svg";
import link from "./assets/link.svg";
import prizeName from "./assets/prize-name.svg";
import prizeUniqueId from "./assets/prize.svg";
import image from "./assets/image.svg";
import game from "./assets/game.svg";
import sponsor from "./assets/sponsor.svg";
import company from "./assets/company.svg";
import XcubeFilledButton
    from "../../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import XcubeInput from "../../../../../../../shared/components/xcube-input/xcube-input";

function EmailInsertModal(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>

            <button onClick={handleShow} id="emailInsert" className="invisible"></button>

            <Modal show={show} onHide={handleClose}
                   aria-labelledby="contained-modal-title-vcenter" id="emailDialog"
                   centered>
                <Modal.Header closeButton>
                    <div>
                        <Row>
                            <div className="col-md-10">
                            <span>
                                Insert
                            </span>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-md-10">
                                <small>
                                    Click on the tile to insert a new element
                                    into the email body.
                                </small>
                            </div>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mt-md-4">
                        <div className="col-md-5">
                            <div className="media">
                                <img className="mr-3" src={winner} alt="image"/>
                                <div className="media-body">
                                    <h5 className="mt-0">Winner name</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-5">
                            <div className="media">
                                <img className="mr-3" src={link} alt="image"/>
                                <div className="media-body">
                                    <h5 className="mt-0">Link</h5>
                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row className="mt-md-4">
                        <div className="col-md-5">
                            <div className="media">
                                <img className="mr-3" src={prizeName} alt="image"/>
                                <div className="media-body">
                                    <h5 className="mt-0">Prize name</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-5">
                            <div className="media">
                                <img className="mr-3" src={prizeUniqueId} alt="image"/>
                                <div className="media-body">
                                    <h5 className="mt-0">Prize Unique ID</h5>
                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row className="mt-md-4">
                        <div className="col-md-5">
                            <div className="media">
                                <img className="mr-3" src={image} alt="image"/>
                                <div className="media-body">
                                    <h5 className="mt-0">Image</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-5">
                            <div className="media">
                                <img className="mr-3" src={game} alt="image"/>
                                <div className="media-body">
                                    <h5 className="mt-0">Game / Campaign name</h5>
                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row className="mt-md-4">
                        <div className="col-md-5">
                            <div className="media">
                                <img className="mr-3" src={sponsor} alt="image"/>
                                <div className="media-body">
                                    <h5 className="mt-0">Sponsor name</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-5">
                            <div className="media">
                                <img className="mr-3" src={company} alt="image"/>
                                <div className="media-body">
                                    <h5 className="mt-0">Company logo</h5>
                                </div>
                            </div>
                        </div>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <XcubeInput placeholder="Type to search"/>
                    <XcubeFilledButton text="Cancel" btnState="negative" />
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default EmailInsertModal;
