import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import copyImg from "../../../dashboard-campaign/components/campaign-game/assets/copy.svg";
import removeImg from "../../../dashboard-campaign/components/campaign-game/assets/remove.svg";
import "./dashboard-account-team.scss";
import { getDataService } from '../../../../../../shared/services/getDataService';
import NewGroup from './components/new-group';
import { dataHelper } from '../../../../../../shared/helpers/data-helper';
import avatar from "./assets/avatar.svg";
import NewUser from './components/new-user';
import EditUser from "./components/edit-user";
import { setDataService } from '../../../../../../shared/services/setDataService';

class DashboardAccountTeam extends Component {

    constructor(props){
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.changeTitle('Account - Team');
        getDataService.getTeamsPage().then((resp) => {
           // console.log(resp);
           this.setState(resp.data.body);
        });
    }

    renderUsers = () => {
        let users = [];
        this.state.users.map((user) => {
            users.push(
                <tr key={user.id}>
                    <td>
                        <div className="media">
                            <img className="mr-3 user_image" src={user.user_image?.thumbnail_url ? user.user_image.thumbnail_url : avatar} alt="Generic placeholder image"/>
                            <div className="media-body">
                                <div>{user.first_name + " " + user.last_name}</div>
                                <div><p>Rocking Brame since {dataHelper.formatDateHelper(user.createdAt, 'DD/MM/YYYY')}</p></div>
                                <div className="btn-group options" role="group">
                                    <EditUser data={user} groups={this.state.groups}/>
                                    <a href={"mailto:" + user.email}><img src={copyImg} />Contact</a>
                                    <a onClick={() => this.deleteUser(user.id)}><img src={removeImg} />Delete </a>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>{user.role}</td>
                    <td>
                        {user.groups.length > 0
                        ? user.groups[0]?.name
                        : 'Main group'
                        }
                    </td>
                    {/* <td>
                        <div>
                            Full
                        </div>
                        <div className="options2">
                            All campaigns
                        </div>
                    </td> */}
                </tr>
            )
        });
        return users;
    }

    renderGroups = () => {
        let groups = [];
        this.state.groups.map((group) => {
            groups.push(
                <tr key={group.id}>
                    <td>
                        <div className="media">
                            <div className="media-body">
                                <div>{group.name}</div>
                                <div><p>{dataHelper.formatDateHelper(group.createdAt, 'DD/MM/YYYY')}</p></div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div>
                            {group.type}
                        </div>
                        {/* <div className="options2">
                            Quiz Em Up
                        </div> */}
                    </td>
                </tr>
            );
        });
        return groups;
    }

    deleteUser = (id) => {
        setDataService.deleteEmployee(id).then((resp) => {
            if(resp.data.message === 'success'){
              window.location.reload();
            }
          });
    }

    render() {
        return (
            <div id="dashboardAccountTeam" className="pt-md-5">
                <div className="container">
                    <Row className="mb-2">
                        <div className="col-md-6 section-head">
                            <h1>
                                Members & Access
                            </h1>
                        </div>
                        <div className="col-md-6 new-campaign-btn">
                            <NewUser data={this.state} />
                        </div>
                    </Row>
                    <Row className="tabLead">
                        <div className="col-md-12 mt-md-3">
                            <table className="table" id="leads-table">
                                <thead>
                                    <tr>
                                        <th scope="col">User</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">Group</th>
                                        {/* <th scope="col">Access</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.users &&
                                        this.renderUsers()
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Row>

                    <Row className="mb-2">
                        <div className="col-md-6 section-head">
                            <h1>
                                Groups
                            </h1>
                        </div>
                        <div className="col-md-6 new-campaign-btn">
                            <NewGroup data={this.state} />
                        </div>
                    </Row>
                    <Row className="tabLead">
                        <div className="col-md-12 mt-md-3">
                            <table className="table" id="leads-table">
                                <thead>
                                <tr>
                                    <th scope="col">Group name</th>
                                    <th scope="col">Group rule</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.groups &&
                                        this.renderGroups()
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
}

export default DashboardAccountTeam;
