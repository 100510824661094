import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import "./campaign-general.scss";
import XcubeFilledButton
    from "../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import XcubeInput from "../../../../../../shared/components/xcube-input/xcube-input";
import XcubeSelect from "../../../../../../shared/components/xcube-select/xcube-select";
import XcubeColorPicker from "../../../../../../shared/components/xcube-color-picker/xcube-color-picker";
import XcubeInputFile from "../../../../../../shared/components/xcube-input-file/xcube-input-file";
import editIcon from "../campaign-status/assets/edit-icon.svg";
import {getDataService} from "../../../../../../shared/services/getDataService";
import {setDataService} from "../../../../../../shared/services/setDataService";
import { toast } from 'react-toastify';
import Col from 'react-bootstrap/Col';
import XcubeTextarea from '../../../../../../shared/components/xcube-textarea/xcube-textarea';
import XcubeInputFilePdf from '../../../../../../shared/components/xcube-input-file-pdf/xcube-input-file-pdf';
import alert from "./assets/alert.png";
import { dataHelper } from '../../../../../../shared/helpers/data-helper';

class CampaignGeneral extends Component {
    campaignId;
    campaignData;
    editUrl;
    constructor(props){
        super(props);
        this.state = {
            campaignData: {},
            templates: [],
            text_source: '1',
            background_image: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.updateState = this.updateState.bind(this);
        this.submitData = this.submitData.bind(this);

        // this.handleSubmit = this.handleSubmit.bind(this);
    }

    handlePolicyChanges = (event) => {
        // console.log(event);
        this.state.campaignData.policy === 'no policy for campaign' ? this.setState( prevState => ({
            campaignData: {...prevState.campaignData, policy: {}}
        })) : this.setState(prevState => ({
            campaignData: {...prevState.campaignData, policy : this.state.campaignData.policy }
        }));
        // this.state.campaignData.policy === 'no policy for campaign' ? this.state.campaignData.policy = {} : this.state.campaignData.policy = this.state.campaignData.policy
        let policy = this.state.campaignData.policy;
        policy[event.name] = event.value;

        this.handleChange({name: 'policy', value: policy});
    };


    handleChange(event) {
        // console.log(event);
        const value = event.value;
        const name = event.name;
        this.setState(prevState => ({
            campaignData: {
                ...prevState.campaignData,
                [name]: value
            }
        }));
    }

    updateState = function (value, name){
        this.setState(prevState => ({
            campaignData: {
                ...prevState.campaignData,
                [name]: value
            }
        }));
    };

    updateTextSource = (event, name) => {
        this.setState({
            [name]: event.value
        });
    };

    componentDidMount() {
        this.props.changeTitle('Campaign - General');
        this.campaignId = this.props.match.params.id;
        this.editUrl = `https://editor.brame.ch/?id=${this.campaignId}`;
        getDataService.campaignGeneralPage(this.campaignId).then((data) => {
            if(data.data.body.policy === "no policy for campaign"){
                data.data.body.policy = {}
            }
            this.setState({
                campaignData: data.data.body
            });

        });

        getDataService.getAllTemplates().then((template) => {
            this.setState({
                templates: template.data.body
            })
        });
    }

     submitData(){
        setDataService.updateCampaignData(this.state.campaignData, this.campaignId).then((res:any) => {
            toast.success('Campaign successfully updated!');
        });
        // Store the return of the `open` command in a variable
    };

    render() {
        return (
            <div id="campaignGeneral" className="pt-md-5">
                <div className="container">
                    <Row>
                        <div className="col-md-12 section-head">
                            <h1>
                                Basics
                            </h1>
                            <p>Set up the basic information to change the campaign’s template. We’ll automatically adapt the template based on your brand logo and accent colors. <br/>
                                To change your campaign page’s content, click “Edit Page”.</p>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-4 btn-group-basics">
                            <XcubeInput
                              label="Campaign name"
                              placeholder="Campaign name"
                              type="text"
                              name="name"
                              value={this.state.campaignData?.name || ''}
                              onChange={this.handleChange}
                              id={'name'}
                            />
                        </div>
                        <div className="col-md-4 btn-group-basics">
                            <XcubeInput
                              label="Campaign description (optional)"
                              placeholder="Campaign description"
                              type="text"
                              name="description"
                              value={this.state.campaignData?.description || ''}
                              onChange={this.handleChange}
                              id={'description'}
                            />
                        </div>
                        <div className="col-md-4 btn-group-basics">
                            <XcubeSelect
                              label="Selected template"
                              options={this.state.templates}
                              name="template_id"
                              id="template_id"
                              onChange={this.handleChange}
                              required={false}
                              defaultValue={this.state.campaignData.template_id}
                            />
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-4 btn-group-basics">
                            <XcubeInputFile
                              onChange={this.handleChange}
                              name="logo"
                              previewImg={this.state.campaignData?.logo_object?.thumbnail_url}
                              label="Logo image"
                              id={"logo_img"}/>
                        </div>
                        <div className="col-md-4 brand">
                            <div className="btn-group-brand">
                                <form action="" className="color-picker">
                                    <div className="primaryColorPicker">
                                        <p>Primary:</p> <XcubeColorPicker propColor={this.state.campaignData?.primary_color || ''} onChange={this.updateState} name="primary_color"/>
                                    </div>
                                    <div className="secondaryColorPicker">
                                        <p>Secondary:</p> <XcubeColorPicker propColor={this.state.campaignData?.secondary_color || ''} onChange={this.updateState} name="secondary_color"/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        {/*<div className="col-md-2 mt-md-3">*/}
                        {/*    <XcubeFilledButton btnState="normal" text="Save changes" onClick={this.submitData}/>*/}
                        {/*</div>*/}
                        <div className="col-md-2 mt-md-3">
                            <a href={this.editUrl} className="edit-page">
                                <img src={editIcon} alt=""/> Edit page
                            </a>
                        </div>
                    </Row>
                    <div className="connect-info">
                        <Row>
                            <div className="col-md-12">
                                <hr/>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-md-12 section-head">
                                <h1>
                                    Social media & SEO
                                </h1>
                                <p>The following information will be used to represent your campaign in web search results and when the page is shared via the social media.
                                    You can also add your social media accounts here if you want to list them in the Connect section.</p>
                            </div>
                        </Row>
                        {/*<Row>*/}
                            {/*<div className="col-md-4">*/}
                                {/*<XcubeSelect label="Mode" defaultValue="Pre-fill using Account data"/>*/}
                            {/*</div>*/}
                        {/*</Row>*/}
                        <Row>
                            <div className="col-md-4">
                                <XcubeInput
                                  label="Title"
                                  placeholder="My awesome campaign"
                                  type="text"
                                  name="seo_title"
                                  value={this.state.campaignData?.seo_title || ''}
                                  onChange={this.handleChange}
                                  id={'seo_title'}
                                />
                            </div>
                            <div className="col-md-4">
                                <XcubeInput
                                  label="Description"
                                  placeholder="Join the fun!"
                                  type="text"
                                  name="seo_description"
                                  value={this.state.campaignData?.seo_description || ''}
                                  onChange={this.handleChange}
                                  id={'seo_description'}
                                />
                            </div>
                            <div className="col-md-4">
                                <XcubeInputFile
                                  onChange={this.handleChange}
                                  name="seo_image"
                                  previewImg={this.state.campaignData?.seo_image_object?.thumbnail_url}
                                  label="SEO image"
                                  id={"seo_image"}
                                />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-md-4">
                                <XcubeInput
                                  label="Instagram (optional)"
                                  placeholder="@brand"
                                  type="text"
                                  name="instagram"
                                  value={this.state.campaignData?.instagram || ''}
                                  onChange={this.handleChange}
                                  id={'instagram'}
                                />
                            </div>
                            <div className="col-md-4">
                                <XcubeInput
                                  label="Facebook (optional)"
                                  placeholder="facebook.com/brand"
                                  type="text"
                                  name="facebook"
                                  value={this.state.campaignData?.facebook || ''}
                                  onChange={this.handleChange}
                                  id={'facebook'}
                                />
                            </div>
                            <div className="col-md-4">
                                <XcubeInput
                                  label="Twitter (optional)"
                                  placeholder="twitter.com/brand"
                                  type="text"
                                  name="twitter"
                                  value={this.state.campaignData?.twitter || ''}
                                  onChange={this.handleChange}
                                  id={'twitter'}
                                />
                            </div>
                        </Row>
                    </div>
                    <Row>
                        {/*<div className="col-md-2 mt-md-3">*/}
                        {/*    <XcubeFilledButton btnState="normal" text="Save changes"/>*/}
                        {/*</div>*/}
                    </Row>
                    <div className="privacy-terms">
                        <Row>
                            <div className="col-md-12">
                                <hr/>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-md-6 section-head">
                                <h1>
                                    Privacy & Terms of Use
                                </h1>
                                <p>Set up how you would like to create the Privacy Policy and Terms of Use pages of this campaign.
                                    You can pre-fill our templates or paste your legal texts here.</p>
                            </div>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <p>Settings</p>
                            </Col>
                        </Row>
                        <Row>
                            <div className="col-md-4">
                                <XcubeSelect
                                    label="Text sources"
                                    options={[
                                        {id: 'text', name: 'Plain text'},
                                        {id: 'attachments', name: 'Attachments'},
                                    ]}
                                    onChange={this.handlePolicyChanges}
                                    id={'text_source'}
                                    name={'source'}
                                    errorName={'Text sources'}
                                    defaultValue={this.state?.campaignData.policy?.source ? this.state?.campaignData.policy?.source : 'attachments'}
                                    required={true}
                                />
                            </div>
                            <div className="col-md-4">
                                <XcubeInputFile
                                    name="background"
                                    onChange={this.handlePolicyChanges}
                                    previewImg={this.state?.campaignData.policy?.background?.thumbnail_url}
                                    label={'Background'}
                                    id={'background_image'}
                                />
                            </div>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <p>Content</p>
                            </Col>
                        </Row>
                        {this.state?.campaignData?.policy?.source === 'text'
                        ?
                            <Row>
                                <Col md={12}>
                                    <XcubeTextarea
                                        label="Privacy Policy text"
                                        id="privacy-policy-text"
                                        name="privacy_policy"
                                        onChange={this.handlePolicyChanges}
                                        value={this.state.campaignData.policy.privacy_policy}
                                    />
                                </Col>
                                <Col md={12}>
                                    <XcubeTextarea
                                        label="Terms of Use text"
                                        id="terms-of-use-text"
                                        name="terms_of_use"
                                        onChange={this.handlePolicyChanges}
                                        value={this.state.campaignData.policy.terms_of_use}
                                    />
                                </Col>
                            </Row>
                        :
                            <Row>
                                <Col md={4}>
                                    <XcubeInputFilePdf
                                        name="privacy_policy"
                                        onChange={this.handlePolicyChanges}
                                        label={'Privacy Policy'}
                                        id={'privacy_policy'}
                                    />
                                    {dataHelper.validURL(this.state.campaignData?.policy?.privacy_policy) &&
                                        <a className="preview-link" href={this.state.campaignData?.policy?.privacy_policy } target="_blank">Preview Policy</a>
                                    }
                                </Col>
                                <Col md={4}>
                                    <XcubeInputFilePdf
                                        name="terms_of_use"
                                        onChange={this.handlePolicyChanges}
                                        label={'Terms of Use'}
                                        id={'terms_of_use'}
                                    />
                                    {dataHelper.validURL(this.state.campaignData?.policy?.terms_of_use) &&
                                        <a className="preview-link" href={this.state.campaignData?.policy?.terms_of_use } target="_blank">Preview Terms</a>
                                    }
                                </Col>
                            </Row>
                        }
                        <Row className="mt-md-3 changes">
                            <span>
                                <img src={alert} /> If you’re using the pre-fill information, make sure to change the company information with your own.
                            </span>
                        </Row>
                        <Row>
                            <div className="col-md-3 mt-md-3 mb-3">
                                <XcubeFilledButton
                                  btnState="normal"
                                  text="Save changes"
                                  onClick={this.submitData}/>
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default CampaignGeneral;
