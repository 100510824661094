import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import OpenPathButton from "../../../components/open-path-button/open-path-button";
import "./action.scss";

class Action extends Component {

    render() {
        return (
            <div id="action-about-page">
                <div className="container">
                    <Row>
                        <div className="col-md-12 hero-text">
                            <h1>START. STRIKE. SUCCEED.</h1>
                            <p>We offer all of our customers the opportunity to test our software to see if it’s right for them. <br/>
                                See if it’s right for you.
                            </p>
                            <OpenPathButton text="Book a demo" path="/book-a-demo"/>
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Action;