import React, {Component} from 'react';
import "./hero.scss";
import XcubeFilledButton from "../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import Player from "../../reactPlayer";

class Hero extends Component {
    state = {
        open: false
    };
    
    onOpenModal = () => {
        this.setState(prevState => ({
            open: !prevState.open
        }));
    };

    render() {
        return (
            <div id="hero-home-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 hero-text">
                            <h4>Play with your brand.</h4>
                            <h1>BE THE GAME CHANGER.</h1>
                            <XcubeFilledButton text="Play the video" btnState="normal" onClick={this.onOpenModal}/>
                            <Player open={this.state.open} toggleModal={this.onOpenModal} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Hero;