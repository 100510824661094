import React, {Component} from 'react';
import XcubeFilledButton from "../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import image from "../check-out-software/assets/Laptop.png";
import "./check-out-software.scss";
class CheckOutSoftware extends Component {
    render() {
        return (
            <div id="checkOutSoftware-home-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <img src={image} alt="" className="img-fluid"/>
                        </div>
                        <div className="col-md-5 intro-text">
                            <h3>
                                simple. efficient. <br/>
                                valuable. <br/>
                            </h3>
                            <p>
                                Brame’s software solution uses game concepts that you can fully customize to your company’s corporate identity to avoid complexity, stress and overpricing.                            </p>
                            <XcubeFilledButton text="Check out the software" btnState="normal"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CheckOutSoftware;