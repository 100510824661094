import React, { useState } from 'react';
import { setDataService } from '../../services/setDataService';
import { dataHelper } from '../../helpers/data-helper';
import previewImg
  from '../../../pages/dashboard/components/dashboard-campaign/components/campaigns/assets/previewImg.svg';
import editImg
  from '../../../pages/dashboard/components/dashboard-campaign/components/campaign-game/assets/edit.svg';
import copyImg
  from '../../../pages/dashboard/components/dashboard-campaign/components/campaign-game/assets/copy.svg';
import removeImg
  from '../../../pages/dashboard/components/dashboard-campaign/components/campaign-game/assets/remove.svg';
import statsImg
  from '../../../pages/dashboard/components/dashboard-campaign/components/campaigns/assets/statsImg.svg';
import {displaySweetAlert} from "../../../redux/actions";
import store from '../../../redux/store';
import {Link} from 'react-router-dom'


function XcubeRenderCampaigns(props) {

  const removeCampaign = (id) => {
    store.dispatch(displaySweetAlert({value: {
        display: true,
        id: id,
        type: 'removeCampaign'
      }
    }));
  }



  function copyCampaign(campaignId) {
    setDataService.copyCampaign(campaignId).then((resp) => {
      window.location.reload();
    });
  }

  function renderCampaigns(data) {
    const renderCampaigns = [];
    if (data.length > 0) {
      data.forEach((res) => {
        if(res.user){
          let preview_url = "https://editor.brame.ch/?id=" + res._id;
          renderCampaigns.push(

              <tr key={res._id}>
                  <td>
                    <div className="media">
                      {/*<img*/}
                      {/*  className="mr-3"*/}
                      {/*  src={res.logo?.url ? res.logo?.url : res.logo ? res.logo : 'false'}*/}
                      {/*  alt="Prize"*/}
                      {/*/>*/}
                      <div className="media-body">
                        <div>{res.title}</div>
                        <div>
                          <p>
                            Added on
                            {' '}
                            {dataHelper.formatDateHelper(res.createdAt, 'DD/MM/YYYY')}
                            {' '}
                            by { `${res.user.firstName}`} { `${res.user.lastName}`}
                          </p>
                        </div>
                        <div className="btn-group options" role="group">
                          <a href={res.campaign_url} target={'_blank'}>
                            <img src={previewImg} alt=""/>
                            Preview
                          </a>
                          <a href={preview_url} target={'_blank'}>
                            <img src={editImg} alt=""/>
                            Edit
                          </a>
                          <a onClick={() => copyCampaign(res._id)} style={{ cursor: "pointer" }}>
                            <img src={copyImg} alt=""/>
                            Copy
                          </a>
                          <a onClick={() => removeCampaign(res._id)} style={{ cursor: "pointer" }}>
                            <img src={removeImg} alt=""/>
                            Delete
                            {' '}
                          </a>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td><img src={res.game?.icon} alt=""/></td>
                  <td>
                    {res.numberOfUniqueVisitors}
                  </td>
                  <td>
                    <div>
                      {
                        dataHelper.conversionCalculator(res.numberOfUniqueVisitors, res.numberOfLeads)
                      }%
                    </div>
                    <div className="options2">
                      <a href={`/campaigns/${res._id}/status`}>
                        <img src={statsImg} alt=""/>
                        Stats
                      </a>
                    </div>
                  </td>
                  <td>
                    <div>
                      {dataHelper.capitalizeString(res.status)}

                      Ends on
                      {' '}
                      {dataHelper.formatDateHelper(res.endDate, 'DD/MM/YYYY')}
                    </div>
                    <div>
                      <p>({dataHelper.daysLeftHelper(res.endDate)})</p>

                    </div>
                  </td>
              </tr>
          );
        }
      });
    }
    return renderCampaigns;
  }
  return (
      <>
        {renderCampaigns(props.data)}
      </>
  )
}
export default XcubeRenderCampaigns;
