import React, {Component} from 'react';
import HeroInspiration from "./components/hero-inspiration/hero-inspiration";
import "./inspiration.scss";
import IntroInspiration from "./components/intro-inspiration/intro-inspiration";
import UseCasesInspiration from "./components/use-cases-inspiration/use-cases-inspiration";
import Action from "./components/action/action";
class Inspiration extends Component {
    render() {
        return (
            <div id="inspiration">
                <HeroInspiration/>
                <IntroInspiration/>
                <UseCasesInspiration/>
                <Action/>
            </div>
        );
    }
}

export default Inspiration;