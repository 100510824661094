import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import startLogo from "../create-campaign-wizard-template/assets/wiz-template.png";
import "./create-campaign-wizard-template.scss";
import XcubeSelect from "../../../../shared/components/xcube-select/xcube-select";
import cardImg from "./assets/card-img.svg";
import XcubeFilledButton from "../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import XcubeTextButton from "../../../../shared/components/xcube-buttons/xcube-text-button/xcube-text-button";
import {getDataService} from "../../../../shared/services/getDataService";
import PreviewTemplate from "./components/preview-template/preview-template";
import { toast } from 'react-toastify';

class CreateCampaignWizardTemplate extends Component {
    constructor(props) {
        super(props);
        this.toggleClass = this.toggleClass.bind(this);
        this.state = {
            template: '',
            cardsData: []
        };
    }

    componentDidMount(){
        getDataService.getAllTemplates().then((resp) => {
            // console.log(resp.data.body);
            resp.data.body.map((data) => {
                data.active = false;
                this.state.cardsData.push(data)
            });
        });
    }

    nextStep(){
        if(this.props.state.template_id.length > 0){
            return this.props.nextStep();
        }else{
            toast.error("You must select at least one template!");
        }
    }

    toggleClass(id) {
        this.state.cardsData.map((data) => {
           if(data.id === id){
               data.active = !data.active;
               if(data.name === "Gym One Memory Game"){
                   this.state.template = 'https://server.brame.ch/assets/templates/gymonememory/';
               }
               else{
                   this.state.template = 'https://server.brame.ch/assets/templates/botanic-garden/';
               }
           }else{
               data.active = false;
           }
        });
        // console.log(this.state.cardsData);
        this.setState(this.state.cardsData);
        this.props.updateState(
            {
                name: 'template_id',
                value: id,
            }
        );
    };
    createCards = () => {
        let cards = [];

        this.state.cardsData.map((data) => {
           cards.push(
               <div className="col-md-3 mt-md-3" key={data.id}>
                   <div className={data.active ? 'card active': 'card'}
                        onClick={() =>this.toggleClass(data.id)} >
                       <img className="card-img-top" src={data.thumbnail_url}/>
                       <div className="card-footer">
                           {data.name}
                       </div>
                   </div>
               </div>
           )
        });
        return cards;
    };


    render() {
        return (
            <div id="createCampaignWizardTemplate" className="mt-md-5 pb-md-5">
                <Container>
                    <Row>
                        <div className="col-md-12">
                            <Card className="mt-2">
                                <Card.Header>
                                    <div className="xcube-card-header mt-md-3">
                                        <Row>
                                            <div className="imgDiv col-sm-2
                                            col-md-6 col-lg-2">
                                                <img src={startLogo} alt=""/>
                                            </div>
                                            <div className="col-md-6 col-lg-6 ml-lg-4">
                                                <h3>
                                                    Find your style
                                                </h3>
                                                <p>
                                                    Pick the template that fits your campaign best.
                                                </p>
                                                <small>
                                                    You can always go back and choose another one, or customize the one you've picked.
                                                </small>
                                            </div>
                                            {/*<div className="col-md-4 mt-md-5">*/}
                                            {/*    <XcubeSelect label="Template style"/>*/}
                                            {/*</div>*/}
                                        </Row>
                                    </div>
                                </Card.Header>
                                <Card.Body className="mt-md-4 p-0">

                                    <div className="xcube-card-body">
                                        <Row>
                                            {this.createCards()}
                                        </Row>
                                    </div>
                                </Card.Body>
                                <Card.Footer className="xcube-card-footer">
                                    <Row>
                                        <div className="col-md-3 preview">
                                            <PreviewTemplate template={this.state.template}/>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="btn-group float-md-right" role="group" aria-label="Basic example">
                                                <XcubeTextButton btnState="normal" text="< Goal" onClick={this.props.previousStep}/>
                                                <XcubeFilledButton btnState="normal" text="Continue >" onClick={() => this.nextStep()}/>
                                            </div>
                                        </div>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default CreateCampaignWizardTemplate;
