import React, {Component} from 'react';
import DatePicker from "react-date-picker";
import "./xcube-date-picker.scss";
import moment from 'moment';

class XcubeDatePicker extends Component {
    state = {
        date: new Date(),
        time: '00:00',
    };
    onDateChange = date => {
        let formatedDate = moment(date).format('MM-DD-YYYY');
        this.setState(prevState => ({
            ...prevState,
            date: date
        }));
        this.props.onChange({
            name: this.props.name,
            value: formatedDate + ' ' + this.state.time
        });
    };

    render() {
        return (
            <div id="xCubeDatePicker">
                <div className="xcubeDatePicker form-control">
                    <label>
                        {this.props.label}
                    </label>
                    <span className="dateLabel">
                        {this.props.dateLabel}
                    </span>
                    <DatePicker
                      format={'yyyy-M-d'}
                      clearIcon={null}
                      calendarIcon={null}
                      className="datePicker"
                      maxDate={this.props.maxDate ? new Date(this.props.maxDate) : null}
                      minDate={this.props.minDate ? new Date(this.props.minDate) : null}
                      onChange={this.onDateChange}
                      value={this.props.date ? new Date(this.props.date) : this.state.date}
                    />
                </div>
            </div>
        );
    }
}

export default XcubeDatePicker;
