import React, {Component} from 'react';
import "./game-inspiration.scss";
import XcubeFilledButton from "../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import imac from "./assets/iMac.png";
import Row from "react-bootstrap/Row";

class GameInspiration extends Component {
    render() {
        return (
            <div id="gameInspiration-home-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-5">
                            <Row>
                                <div className="col-md-12 intro-text">
                                    <h3>
                                        GET INSPIRED BY <br/>
                                        OUR AMAZING <br/>
                                        TEMPLATES...
                                    </h3>
                                    <p className="w-50">
                                        Discover and explore hundreds of customizable samples and adapt them to your needs.
                                    </p>
                                    <XcubeFilledButton text="Get inspired"/>
                                </div>
                            </Row>

                            <Row>
                                <div className="col-md-12 intro2-text">
                                    <h3>
                                        and start creating, <br/>
                                        engaging and <br/>
                                        growing.
                                    </h3>
                                    <p className="w-75 mt-md-4 md-md-3">
                                        Increase your brand awareness, create long-term loyalty and grow your database with our cool game concepts.
                                    </p>
                                    <XcubeFilledButton text="Try out games" btnState="normal"/>
                                </div>
                            </Row>
                        </div>
                        <div className="col-md-7 mt-sm-3">
                            <img src={imac} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GameInspiration;