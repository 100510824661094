import React, { Component } from 'react';
import './xcube-input.scss';
import PropTypes from 'prop-types';
import { Textbox } from 'react-inputs-validation';

// eslint-disable-next-line react/prefer-stateless-function
class XcubeInput extends Component {
  render() {
    return (
      <div id="xcube-input">
        <div className="background-image">
          <label className="xcube-input-label ">
            {this.props.label}
          </label>
          <Textbox
            attributesInput={{
              id: this.props.id,
              name: this.props.name,
              type: this.props.type,
              placeholder: this.props.placeholder,
              className: 'xcube-input typing form-control',
              autoComplete: 'Of',
            }}
            attributesWrapper={{
            }}
            classNameContainer={'xcube-input-error'}
            value={this.props.value}
            onChange={(value) => {
              this.props.onChange({
                name: this.props.name,
                value: value
              })
            }}
            onBlur={(e) => {}}
            validationOption={{
              name: this.props.label,
              check: true,
              required: this.props.required,
            }}
            validate={this.props.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
            validationCallback={res => {
              if(res){
                let validatorName = `has_${this.props.name}_error`;
                this.props.onChange({
                  name: validatorName, value: res
                });
                this.props.onChange({
                  name: 'validate', value: false
                });
              }
            }}
          />
          {/*<input type={this.props.type} className="xcube-input typing form-control" placeholder={this.props.placeholder} onChange={this.props.onChange} value={this.props.value} name={this.props.name} id={this.props.id} />*/}
        </div>
      </div>
    );
  }
}

XcubeInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool
};

XcubeInput.defaultProps = {
  placeholder: '',
  value: '',
  onChange() {},
  type: 'text',
  name: '',
  id: '',
  label: '',
  required: false
};

export default XcubeInput;
