import React, {Component} from 'react';
import "./campaign-integrations.scss";
import Row from "react-bootstrap/Row";
import editImg from "../campaign-game/assets/edit.svg";
import emailImg from "./assets/email.svg";
import statisticsImg from "./assets/statistics.svg";
import otherImg from "./assets/other.svg";
import notificationImg from "./assets/notification.svg";
import removeImg from "../campaign-game/assets/remove.svg";
import IntegrationDialog from "./components/integrationDialog.jsx";
class CampaignIntegrations extends Component {

componentDidMount() {
this.props.changeTitle('Campaign - Integrations');
}

render() {
    return (
        <div id="campaignIntegrations" className="pt-md-5">
            <div className="container">
                <Row>
                    <div className="col-md-6 section-head">
                        <h1>
                            Integrations
                        </h1>
                    </div>
                    <div className="col-md-6 question-btn">
                        <IntegrationDialog/>
                    </div>
                </Row>
                <Row>
                    <div className="col-md-12 mt-md-3">
                        <table className="table" id="leads-table">
                            <thead>
                                <tr>
                                    <th scope="col">Type</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="media">
                                            <img className="mr-3" src={emailImg} alt="Generic placeholder image"/>
                                            <div className="media-body">
                                                <div>E-mail provider</div>
                                                <div><p>Mailchimp</p></div>
                                                <div className="btn-group options" role="group">
                                                    <a href=""><img src={editImg} /> Edit</a>
                                                    <a href=""><img src={removeImg} /> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>Running</div>
                                        <div><p>Last check: 14/05/2019 12:00</p></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="media">
                                            <img className="mr-3" src={statisticsImg} alt="Generic placeholder image"/>
                                            <div className="media-body">
                                                <div>Statistics</div>
                                                <div><p>Google Analytics</p></div>
                                                <div className="btn-group options" role="group">
                                                    <a href=""><img src={editImg} /> Edit</a>
                                                    <a href=""><img src={removeImg} /> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>Running</div>
                                        <div><p>Last check: 14/05/2019 12:00</p></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="media">
                                            <img className="mr-3" src={notificationImg} alt="Generic placeholder image"/>
                                            <div className="media-body">
                                                <div>Notification</div>
                                                <div><p>Cookie consent</p></div>
                                                <div className="btn-group options" role="group">
                                                    <a href=""><img src={editImg} /> Edit</a>
                                                    <a href=""><img src={removeImg} /> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>Running</div>
                                        <div><p>Last check: 14/05/2019 12:00</p></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="media">
                                            <img className="mr-3" src={otherImg} alt="Generic placeholder image"/>
                                            <div className="media-body">
                                                <div>Other</div>
                                                <div><p>Particle generator</p></div>
                                                <div className="btn-group options" role="group">
                                                    <a href=""><img src={editImg} /> Edit</a>
                                                    <a href=""><img src={removeImg} /> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>Running</div>
                                        <div><p>Last check: 14/05/2019 12:00</p></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Row>
            </div>
        </div>
    );
}
}

export default CampaignIntegrations;
