import React, {useState, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import "./xcube-file-upload.scss";
import startUpload from "./assets/upload.svg";
import { setDataService } from '../../services/setDataService';

function XcubeFileUpload(props) {
    const [percentage, setPercentage] = useState(0);

    const thumbsContainer = {
        display: 'flex',
        // flexDirection: 'row',
        // flexWrap: 'wrap',
        marginTop: 16
    };

    const thumb = {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 200,
        height: 200,
        padding: 4,
        boxSizing: 'border-box'
    };

    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };

    const img = {
        display: 'block',
        width: 'auto',
        height: '100%'
    };


    const [files, setFiles] = useState([]);
    let images;
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        onDrop: acceptedFiles => {
            for (let file in acceptedFiles){
                let reader = new FileReader();
                reader.readAsDataURL(acceptedFiles[file]);
                reader.onloadend = () => {
                    images = reader.result;
                    setDataService.uploadImage(images).then((result) => {
                        if(result.data.message === 'success') {
                            props.onChange({'value': result.data.body, 'name': props.name});
                        }
                    });
                };
            }

            // Preview thumbs
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            setPercentage(100);
        },
    });


    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                />
            </div>
        </div>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    let uploadStart = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94 43" width="94" height="43" className="d-block ml-auto">
        <g id="Section 2">
            <g id="Upload">
                <path id="Ellipse 3" fillRule="evenodd" className="shp1" offset="20%" fill="#99cc66" stroke="rgba(140,140,140,0.5019607843137255)"
                      d="M90.25 42L3.58 42C2.39 42 1.38 41.24 1 40.18C1.41 29.65 8.39 25.73 16.08 25.73C17.41 25.73 18.7 25.92 19.93 26.24C21.21 12.64 32.65 2 46.58 2C55.83 2 63.98 6.7 68.79 13.83C69.94 13.63 71.12 13.53 72.33 13.53C83.75 13.53 93 22.78 93 34.2C93 36.14 92.73 38.7 92.23 40.48C91.73 41 91.03 42 90.25 42ZM41.49 39.29L52.34 39.29L52.34 24.91C54.48 24.91 56.07 24.91 56.35 24.91C57.3 24.91 57.77 24.19 57.35 23.64C56.77 22.89 48.54 12.53 48.02 11.9C47.56 11.33 46.47 11.32 46.01 11.9C45.3 12.78 37.11 23.1 36.71 23.61C36.2 24.26 36.84 24.91 37.71 24.91C37.9 24.91 39.41 24.91 41.49 24.91L41.49 39.29Z"/>
            </g>
        </g>
    </svg>;

    let uploadStarted = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103 108" width="103" height="108" className="d-block ml-auto">
        <g id="Section 2">
            <g id="Upload Progress">
                <defs>
                    <linearGradient id="percentage" x1="0" y1="1" x2="0" y2="0">
                        <stop id="stop1" offset={percentage + '%'} stopColor="#99cc66"/>
                        <stop id="stop2" offset="0%" stopColor="rgba(140,140,140,0.5019607843137255)"/>
                    </linearGradient>
                </defs>
                <g id="Icon">
                    <path id="Ellipse 3" fillRule="evenodd" className="shp0" fill="url(#percentage)"
                          d="M95.25 107L8.58 107C7.39 107 6.38 106.24 6 105.18C6.41 94.65 13.39 90.73 21.08 90.73C22.41 90.73 23.7 90.92 24.93 91.24C26.21 77.64 37.65 67 51.58 67C60.83 67 68.98 71.7 73.79 78.83C74.94 78.63 76.12 78.53 77.33 78.53C88.75 78.53 98 87.78 98 99.2C98 101.14 97.73 103.7 97.23 105.48C96.73 106 96.03 107 95.25 107ZM46.49 104.29L57.34 104.29L57.34 89.91C59.48 89.91 61.07 89.91 61.35 89.91C62.3 89.91 62.77 89.19 62.35 88.64C61.77 87.89 53.54 77.53 53.02 76.9C52.56 76.33 51.47 76.32 51.01 76.9C50.3 77.78 42.11 88.1 41.71 88.61C41.2 89.26 41.84 89.91 42.71 89.91C42.9 89.91 44.41 89.91 46.49 89.91L46.49 104.29Z"/>
                    <path id="Ellipse 3 copy 2" className="shp2"
                          d="M61.84 49.86L68.05 49.86L68.05 60L61.84 60L61.84 49.86ZM70.34 51.73C69.79 51.73 60.06 51.73 59.67 51.73C59.18 51.73 58.81 51.36 59.1 50.99C59.33 50.69 64.02 44.75 64.42 44.25C64.69 43.91 65.31 43.92 65.58 44.25C65.87 44.61 70.58 50.57 70.91 51C71.15 51.32 70.89 51.73 70.34 51.73Z"/>
                    <path id="Ellipse 3 copy 3" className="shp3"
                          d="M35.84 31.86L42.05 31.86L42.05 42L35.84 42L35.84 31.86ZM44.34 33.73C43.79 33.73 34.06 33.73 33.67 33.73C33.18 33.73 32.81 33.36 33.1 32.99C33.33 32.69 38.02 26.75 38.42 26.25C38.69 25.91 39.31 25.92 39.58 26.25C39.87 26.61 44.58 32.57 44.91 33C45.15 33.32 44.89 33.73 44.34 33.73Z"/>
                    <path id="Ellipse 3 copy 4" className="shp4"
                          d="M48.84 7.86L55.05 7.86L55.05 18L48.84 18L48.84 7.86ZM57.34 9.73C56.79 9.73 47.06 9.73 46.67 9.73C46.18 9.73 45.81 9.36 46.1 8.99C46.33 8.69 51.02 2.75 51.42 2.25C51.69 1.91 52.31 1.92 52.58 2.25C52.87 2.61 57.58 8.57 57.91 9C58.15 9.32 57.89 9.73 57.34 9.73Z"/>
                </g>
            </g>
        </g>
    </svg>;

    return (
        <>
        <div id="xcube-file-upload">
            <section className="container-fluid">
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <h3> {percentage === 0 ? props.head : 'Uploading file (' + percentage + '%).'}</h3>
                    <p>{percentage === 0 ? props.text : 'Upload completed!'} </p>
                    {percentage === 0 ? uploadStart : uploadStarted}
                </div>
            </section>
            {
                props.preview ?
                    <div className={'thumbnails'}>
                        <aside style={thumbsContainer}>
                            {thumbs}
                        </aside>
                    </div>
                : null
            }
        </div>
        </>
    );
}

export default XcubeFileUpload;
