import React, { useState} from 'react';
import './preview-template.css';
import XcubeFilledButton
    from "../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import Modal from "react-bootstrap/Modal";

function PreviewTemplate(props) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {setShow(true);};

    // var iframe = document.createElement('iframe');
    // iframe.src = 'http://server.brame.ch/templates/gymone/';
    //
    // document.body.appendChild(iframe);
    return (
        <>
            <XcubeFilledButton btnState="normal" onClick={handleShow} text="Preview Template"/>
            <Modal show={show} onHide={handleClose}
                   size={'xl'}
                   id={'preview-template'}
                   aria-labelledby="contained-modal-title-vcenter"
                   // id="newQuestionModal"
                   centered>
                <Modal.Header closeButton>
                    {/*<Modal.Title>Modal title</Modal.Title>*/}
                </Modal.Header>

                <Modal.Body>
                    <iframe src={props.template} style={{width: '100%'}}/>
                </Modal.Body>

                {/*<Modal.Footer>*/}
                {/*    <XcubeFilledButton btnState="negative" onClick={handleClose} text="Close" />*/}
                {/*    <XcubeFilledButton btnState="normal" text="Save your game"/>*/}
                {/*</Modal.Footer>*/}
            </Modal>
        </>
    )
}

export default PreviewTemplate;
