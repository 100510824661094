import React, {Component} from 'react';
import "./create-campaign-wizard-complete.scss";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import startLogo from "../create-campaign-wizard-complete/assets/logo.svg";
import XcubeFilledButton from "../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import Container from "react-bootstrap/Container";
import XcubeTextButton from "../../../../shared/components/xcube-buttons/xcube-text-button/xcube-text-button";

class CreateCampaignWizardComplete extends Component {
    render() {
        return (
            <div id="createCampaignWizardComplete" className="mt-md-5 pb-md-5">
                <Container>
                    <Row>
                        <div className="col-md-7 ml-md-auto mr-md-auto">
                            <Card className="mt-2">
                                <Card.Body className="mt-md-4">
                                    <img src={startLogo} alt=""/>
                                    <h3>
                                        Awesome job!
                                    </h3>
                                    <p>
                                        You have just finished the initial set-up.
                                    </p>
                                    <small className="mb-md-3">
                                        You can now continue to customize the content of your template,
                                        or go back and tweak your settings some more.
                                    </small>

                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        <XcubeTextButton btnState="normal" text="< Information" onClick={this.props.previousStep}/>
                                        <XcubeFilledButton btnState="normal" text="Continue >" onClick={this.props.submitData}/>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default CreateCampaignWizardComplete;
