import React from 'react';
import devicesImg from "./assets/wiz-device.png";
import "./xcube-analytics-devices.scss";

function XcubeAnalyticsDevices(props) {

    return (
      <div id="dashboard-devices">
          <div className="card">
              <div className="card-title-devices mt-3 ml-3">Devices</div>
              <div className="row no-gutters">
                  <div className="col-7">
                      <div className="card-body pt-4">
                          {props.data.length > 0 ?
                              props.data.map(device => {
                                      return <p key={device.type ? device.type : 'desktop'} className="card-text"><i className={`icn-${device.type ? device.type : 'desktop'}`}> </i> <span>{(device.count) + '' || '0'}</span> {device.type ? device.type : 'desktop'}</p>
                                  })
                          :
                              <>
                                  <p key={'desktop'} className="card-text"><i className={`icn-desktop`}> </i> <span>{'0'}</span> {'desktop'}</p>
                                  <p key={'tablet'} className="card-text"><i className={`icn-tablet`}> </i> <span>{'0'}</span> {'tablet'}</p>
                                  <p key={'mobile'} className="card-text"><i className={`icn-mobile`}> </i> <span>{'0'}</span> {'mobile'}</p>
                              </>
                          }
                      </div>
                  </div>
                  <div className="col-4 text-right pl-2 pr-4 pb-2 pt-2">
                      <img src={devicesImg} className="deviceImg" alt="deviceImg"/>
                  </div>
              </div>
          </div>
      </div>
    );
}

export default XcubeAnalyticsDevices;


