import React, {Component} from 'react';
import "./hero.scss";
class Hero extends Component {
    render() {
        return (
            <div id="hero-why-brame-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 hero-text">
                            <h4>Gamify your content.</h4>
                            <h1>Create <br/> <span>interactive</span> <br/> experiences.</h1>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Hero;