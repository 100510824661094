import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import './register-plan.scss';
import demo
  from '../../../../dashboard/components/dashboard-accounts/components/dashboard-account-plan-billing/assets/demo.svg';
import basic
  from '../../../../dashboard/components/dashboard-accounts/components/dashboard-account-plan-billing/assets/basic.svg';
import professional
  from '../../../../dashboard/components/dashboard-accounts/components/dashboard-account-plan-billing/assets/professional.svg';
import enterprise
  from '../../../../dashboard/components/dashboard-accounts/components/dashboard-account-plan-billing/assets/enterprise.svg';
import XcubeFilledButton
  from '../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button';
import store
  from '../../../../../redux/store';
import { editRegistration }
  from '../../../../../redux/actions';



class RegisterPlan extends Component {

  selectPlan(plan){
    let cards = document.getElementsByClassName('main-card');
    for (let i = 0; i < cards.length; i++) {
      cards[i].className = 'card main-card';
    }
    document.getElementById(plan).className = 'card main-card main-card-active';
    store.dispatch(editRegistration({name: 'selected_plan', value: plan}));
  }

  render() {
    return (
      <div id={'register-plan'}>
        <div className="container-fluid">
          <Row>
            <div className="col-md-7 hero-text">
              <Row>
                <div className="col-md-12">
                  <h1>
                    Glad to have you <br/> on board.
                  </h1>
                  <p>
                    You’ve made the right choice - Brame’s gamified approach to marketing will help you bring out the best of your creative skills and help you get more quality leads than ever before! Now, let’s get on with your registration.
                  </p>
                </div>
              </Row>
            </div>
          </Row>
          <Row>
            <div className="col-md-12">
              <div className="card-deck mt-md-3">
                <div className="card main-card" id={'demo'} onClick={() => this.selectPlan('demo')}>
                  <div className="card m-1">
                    <img className="card-img-top" src={demo} alt="Card cap"/>
                    <div className="card-body p-0 text-center">
                      <p className="card-text main-card-text">Free</p>
                    </div>
                  </div>
                  <div className="card-body text-center second-body">
                    <p className="card-text"> <strong>1k</strong> clicks - <strong>2k</strong> leads</p>
                    <p className="card-text">self-service - one month limit</p>
                    <p className="card-text pt-md-3">
                      <small className="text-muted">
                        Access to select games <br/>
                        Access to select templates <br/>
                        Single user <br/>
                        One active campaign <br/>
                        Full access to Help Desk <br/>
                        Campaign set-up wizard <br/>
                      </small>
                    </p>
                    <p className="card-text pt-md-3">
                      <small className="text-muted">
                        Click to select this plan.
                      </small>
                    </p>

                  </div>
                </div>
                <div className="card main-card main-card-active" id={'basic'} onClick={() => this.selectPlan('basic')}>
                  <div className="card m-1">
                    <img className="card-img-top" src={basic} alt="Card cap"/>
                    <div className="card-body p-0 text-center">
                      <p className="card-text main-card-text">€99/month</p>
                    </div>
                  </div>
                  <div className="card-body text-center second-body">
                    <p className="card-text"> <strong>5k</strong> clicks - <strong>1k</strong> leads</p>
                    <p className="card-text">self-service - monthly cycle</p>
                    <p className="card-text pt-md-3">
                      <small className="text-muted">
                        <strong>Everything in Demo, plus:</strong> <br/>
                        High Converter games <br/>
                        Access to all templates <br/>
                        Template editor <br/>
                        E-mail support <br/>
                      </small>
                    </p>
                    <p className="card-text pt-md-3">
                      <small className="text-muted">
                        You have selected this plan.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="card main-card" id={'professional'} onClick={() => this.selectPlan('professional')}>
                  <div className="card m-1">
                    <img className="card-img-top" src={professional} alt="Card cap"/>
                    <div className="card-body p-0 text-center">
                      <p className="card-text main-card-text">€299/month</p>
                    </div>
                  </div>
                  <div className="card-body text-center second-body">
                    <p className="card-text"> <strong>20k</strong> clicks - <strong>4k</strong> leads</p>
                    <p className="card-text">self-service - yearly cycle</p>
                    <p className="card-text pt-md-3">
                      <small className="text-muted">
                        <strong>Everything in Basic, plus:</strong> <br/>
                        Access to all games <br/>
                        Teams up to 5 users <br/>
                        Pop-up/page integration <br/>
                      </small>
                    </p>
                    <p className="card-text pt-md-3">
                      <small className="text-muted">
                        Click to select this plan.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="card main-card" id={'enterprise'} onClick={() => this.selectPlan('enterprise')}>
                  <div className="card m-1">
                    <img className="card-img-top" src={enterprise} alt="Card cap"/>
                    <div className="card-body p-0 text-center">
                      <p className="card-text main-card-text">Book a meeting</p>
                    </div>
                  </div>
                  <div className="card-body text-center second-body">
                    <p className="card-text"> <strong>Unlimited</strong> clicks - <strong>4k</strong> leads</p>
                    <p className="card-text">custom solutions</p>
                    <p className="card-text pt-md-3">
                      <small className="text-muted">
                        <strong>Everything in Professional, plus:</strong> <br/>
                        Up to 4 custom templates <br/>
                        API Integrations <br/>
                        Success manager (4 campaigns/year) <br/>
                        Call support <br/>
                      </small>
                    </p>
                    <p className="card-text pt-md-3">
                      <small className="text-muted">
                        Click to select this plan.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-md-12 mt-md-5 publish">
              <hr/>
              <XcubeFilledButton text="Enter details >" btnState="normal" onClick={() => this.props.history.push('/register/payment')}/>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

export default RegisterPlan;
