import React from 'react';
import "./xcube-analytics-visitors-per-country.scss";
function XcubeAnalyticsVisitorsPerCountry (props) {
    return (
        <div id="campaignCountry">
            <div className="card">
                <div className="card-body">
                    <div className="row no-gutters">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5 className="card-title">Visitors per country</h5>
                                </div>
                            </div>
                        </div>
                        {props.data.length > 0 ?
                            props.data.slice(0, 4).map((country, index) => {
                                return country.country !== null ?
                                    <React.Fragment key={country.country}>
                                        <div className="col-8">
                                            {country.country &&
                                            <p className="card-text" key={country.country + country.count + 'name'}>{country.country}</p>
                                            }
                                        </div>
                                        <div className="col-4 text-right">
                                            {country.country &&
                                            <p className="card-text" key={country.country + 'count'}><strong>{country.count}</strong></p>
                                            }
                                        </div>
                                    </React.Fragment>
                                    : <React.Fragment key={index}>
                                        <div className="col-8">
                                            <p className="card-text" key={index}>Unknown country</p>
                                        </div>
                                        <div className="col-4 text-right">
                                            <p className="card-text" key={index}><strong>{country.count}</strong></p>
                                        </div>
                                    </React.Fragment>
                            })
                            : "No data to show yet."
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default XcubeAnalyticsVisitorsPerCountry;
