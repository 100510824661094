import {useState} from "react";
import Modal from "react-bootstrap/Modal";
import React from "react";
import "./new-group.scss";
import XcubeFilledButton
  from '../../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button';
import XcubeInput from '../../../../../../../shared/components/xcube-input/xcube-input';
import Row from 'react-bootstrap/Row';
import { authenticationService } from '../../../../../../../shared/services/authenticationService';
import XcubeSelect from '../../../../../../../shared/components/xcube-select/xcube-select';
import Container from "react-bootstrap/Container";
import {setDataService} from "../../../../../../../shared/services/setDataService";
import { toast } from "react-toastify";


function NewGroup(props) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({
    name: '',
    type: '',
    campaign_id: '',
    client_id: '',
    company_id: authenticationService.currentUserValue.company_id
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function handleChange(event) {
    // console.log(event);
    const value = event.value;
    const name = event.name;
    setData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  function submitForm(){

    if(
      data.name.length > 0 &&
      data.type.length > 0
    ){
      setDataService.createGroup(data).then((resp) => {
        if(resp.data.message === 'success'){
          handleClose();
          window.location.reload();
        }
      });
    }else{
      toast.error('You must fill in all required fields!');
    }
  }

  return (
    <div>
      <XcubeFilledButton btnState="normal" text="New group" onClick={handleShow}/>

      <Modal show={show} onHide={handleClose}
             aria-labelledby="contained-modal-title-vcenter"
             id="new-group"
             centered
             >
        <Modal.Header closeButton>
          New Group
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <div className="col-md-12">
                <XcubeInput
                  label="Group name"
                  placeholder="Group name"
                  type="text"
                  name="name"
                  value={data?.name || ''}
                  onChange={handleChange}
                />
              </div>
            </Row>
            <Row>
              <div className="col-md-12">
                <XcubeSelect
                  label="Group type"
                  options={[{name:'Administrator', id: 'administrator'}, {name: 'Deputy', id: 'deputy'}, {name: 'Company', id: 'company'}, {name: 'Campaign', id: 'campaign'}]}
                  name="type"
                  id="type"
                  onChange={handleChange}
                  required={false}
                  defaultValue={data?.type || ''}
                />
              </div>
            </Row>
            <Row>
              <div className="col-md-12">
                <XcubeSelect
                  label="Client Company (Optional)"
                  options={props.data.clients}
                  name="client_id"
                  id="client_id"
                  onChange={handleChange}
                  required={false}
                  defaultValue={data?.client_id || ''}
                />
              </div>
            </Row>
            <Row>
              <div className="col-md-12">
                <XcubeSelect
                  label="Campaigns (Optional)"
                  options={props.data.campaigns}
                  name="campaign_id"
                  id="campaign_id"
                  onChange={handleChange}
                  required={false}
                  defaultValue={data?.campaign_id || ''}
                />
              </div>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
        <XcubeFilledButton
            btnState="normal"
            text="Save"
            onClick={submitForm}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NewGroup;
