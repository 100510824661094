import React, { Component } from 'react';
import './registration.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import RegisterPlan from './components/register-plan/register-plan';
import RegisterPayment from './components/register-payment/register-payment';

class Registration extends Component {
  render() {
    return (
      <div id={'registration-page'}>
        <Router>
          <Switch>
            <Route path='/register/plan' component={RegisterPlan} />
            <Route path='/register/payment' component={RegisterPayment} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default Registration;
