import {useState} from "react";
import Modal from "react-bootstrap/Modal";
import React from "react";
import XcubeFilledButton
    from "../../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import Row from "react-bootstrap/Row";
import XcubeInput from "../../../../../../../shared/components/xcube-input/xcube-input";
import XcubeCheckbox from "../../../../../../../shared/components/xcube-checkbox/xcube-checkbox";
import "./new-payment-modal.scss";
import Col from 'react-bootstrap/Col';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Constants } from '../../../../../../../Constants';
import { setDataService } from '../../../../../../../shared/services/setDataService';
import { toast } from 'react-toastify';
function NewPaymentModal() {
    const [show, setShow] = useState(false);
    const [data, setData] = useState('');
    const stripePromise = loadStripe(Constants.API_KEYS.STRIPE.stripe_pk_test_key);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onInputChange = (event) => {
        setData({
            ...data,
            [event.name]: event.value
        });
    }

    const CheckoutForm = () => {
        const stripe = useStripe();
        const elements = useElements();

        const handleSubmit = async (event) => {
            event.preventDefault();

            if (!stripe || !elements) {
                return;
            }
            const cardElement = elements.getElement(CardElement);

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (error) {
                console.log('[error]', error);
            } else {
                data.stripe_payment_method = paymentMethod;
                setDataService.updatePayment(data).then((resp) => {
                    // console.log(resp);
                    if(resp.data.message === 'success'){
                        toast.success("Success!");
                        window.location.assign('/account/plan-billing');
                    }
                });
            }
        }
        return(
          <form onSubmit={handleSubmit}>
              <Row>
                  <Col md={12}>
                      <div id={'xcube-input'}>
                          <label className={'xcube-input-label'}>Card</label>
                          <div className={'stripe-card-element xcube-input form-control'}>
                              <CardElement
                                options={{
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            color: '#424770',
                                            '::placeholder': {
                                                color: '#aab7c4',
                                            },
                                        },
                                        invalid: {
                                            color: '#9e2146',
                                        },
                                    },
                                }}
                              />
                          </div>
                      </div>
                  </Col>
              </Row>
              <Modal.Footer>
                  <XcubeFilledButton
                    btnState={'normal'}
                    text={'Pay now'}
                    type="submit"
                    disabled={!stripe}
                  />
              </Modal.Footer>
          </form>
        )
    }

    return (
        <div>
            <XcubeFilledButton btnState="normal" text="Payment" onClick={handleShow}/>

            <Modal show={show} onHide={handleClose}
                   aria-labelledby="contained-modal-title-vcenter" id="newPaymentModal"
                   centered>
                <Modal.Header closeButton>
                    <span>
                        Payment
                    </span>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <XcubeInput
                              placeholder={'John Doe'}
                              label={'Name'}
                              type={'text'}
                              name={'stripe_name'}
                              id={'stripe_name'}
                              onChange={onInputChange}
                              value={data.stripe_name}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <XcubeInput
                              placeholder={'email@email.com'}
                              label={'E-mail'}
                              type={'email'}
                              name={'stripe_email'}
                              id={'stripe_email'}
                              onChange={onInputChange}
                              value={data.stripe_email}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <XcubeInput
                              placeholder={'+1-234-567-890'}
                              label={'Phone'}
                              type={'phone'}
                              name={'stripe_phone'}
                              onChange={onInputChange}
                              value={data.stripe_phone}
                            />
                        </Col>
                    </Row>
                    <Elements stripe={stripePromise}>
                        <CheckoutForm/>
                    </Elements>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default NewPaymentModal;
