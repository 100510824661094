import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import startLogo from "../create-campaign-wizard-game-type/assets/wiz-game.png";
import XcubeFilledButton from "../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import XcubeTextButton from "../../../../shared/components/xcube-buttons/xcube-text-button/xcube-text-button";
import Container from "react-bootstrap/Container";
import cardImg from "../create-campaign-wizard-template/assets/card-img.svg";
import "./create-campaign-wizard-game-type.scss";
import {getDataService} from "../../../../shared/services/getDataService";
import { toast } from 'react-toastify';

class CreateCampaignWizardGameType extends Component {

    constructor(props) {
        super(props);
        this.toggleClass = this.toggleClass.bind(this);
        this.state = {
            cardsData: []
        };
    }

    componentDidMount(){
        getDataService.getAllGames().then((resp) => {
            // console.log(resp.data.body);
            resp.data.body.forEach((data) => {
                data.img = cardImg;
                data.active = false;
                this.state.cardsData.push(data)
            });
        });
    }

    nextStep(){
        if(this.props.state.game_id.length > 0){
            return this.props.nextStep();
        }else{
            toast.error("You must select at least one game!");
        }
    }

    toggleClass(id) {
        this.state.cardsData.forEach((data) => {
            if(data.id === id){
                data.active = !data.active;
            }else{
                data.active = false;
            }
        });
        this.setState(this.state.cardsData);
        this.props.updateState(
            {
                name: 'game_id',
                value: id
            }
        );
    };

    createCards = () => {
        let cards = [];

        this.state.cardsData.forEach((data) => {
            cards.push(
                <div className="col-md-3 mt-md-3" key={data.id}>
                    <div className={data.active ? 'card active': 'card'}
                         onClick={() =>this.toggleClass(data.id)} >
                        <img className="card-img-top" alt="card cap" src={data.thumbnail_url}/>
                        <div className="card-footer">
                            {data.name}
                        </div>
                    </div>
                </div>
            )
        });
        return cards;
    };

    render() {
        return (
            <>
            <div id="createCampaignWizardGameType" className="mt-md-5 pb-md-5">
                <Container>
                    <Row>
                        <div className="col-md-12">
                            <Card>
                                <Card.Header>
                                    <div className="xcube-card-header mt-md-3">
                                        <Row>
                                            <div className="col-sm-2 col-md-6 col-lg-2">
                                                <img src={startLogo} alt=""/>
                                            </div>
                                            <div className="col-md-6 col-lg-6 ml-lg-4">
                                                <h3>
                                                    Get your game on!
                                                </h3>
                                                <p>
                                                    Select the game type that suits your campaign.
                                                </p>
                                                <small>
                                                    You can always go back and choose another one,<br/> You’ll be customizing the game content later.
                                                </small>
                                            </div>
                                        </Row>
                                    </div>
                                </Card.Header>
                                <Card.Body className="mt-md-4 p-0">

                                    <div className="xcube-card-body">
                                        <Row>
                                            {this.createCards()}
                                        </Row>
                                    </div>
                                </Card.Body>
                                <Card.Footer className="xcube-card-footer">
                                    <Row>
                                        {/* <div className="col-md-2 preview">
                                            <XcubeFilledButton btnState="normal" text="Try out game"/>
                                        </div>
                                        <div className="col-md-3 preview">
                                            <EditGameModal updateState={this.props.updateState} games={this.state.cardsData}/>
                                        </div> */}
                                        <div className="col-md-7">
                                            <div className="btn-group float-md-right" role="group" aria-label="Basic example">
                                                <XcubeTextButton btnState="normal" text="< Template" onClick={this.props.previousStep}/>
                                                <XcubeFilledButton btnState="normal" text="Continue >" onClick={() => this.nextStep()}/>
                                            </div>
                                        </div>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>

        </>
        );
    }
}

export default CreateCampaignWizardGameType;
