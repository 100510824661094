import React, {Component} from 'react';
import "./xcube-analytics-game-info.scss";
import Col from 'react-bootstrap/Col';


class XcubeAnalyticsGameInfo extends Component {
    constructor() {
        super();
        console.log(this.props)
    }

    render() {
        return (
            <div id="campaign-leads">
                <div className="card">
                    <div className="card-title mt-3 ml-3">
                        {this.props.name}
                    </div>
                    <table className="no-gutters card-body">
                        <tbody>
                            <tr>
                                <td className="pl-3 pb-2">
                                    {this.props.data && this.props.data.length ? this.props.data.slice(0, 4).reverse().map((answer, i) => {
                                        if(answer.title && answer.title.length > 27){
                                            return (
                                                <p key={i} className="card-text">{answer.title.substring(0, 27) + '...'}</p>
                                            )
                                        } else if(answer.title){
                                            return (
                                                <p key={i} className="card-text">{answer.title}</p>
                                            )
                                        }
                                    }) : <p className="card-text">No data to show yet</p> }
                                </td>
                                <td className="text-right pr-3 pb-2">
                                    {this.props.data?.length ? this.props.data.slice(0, 4).reverse().map((answer, i) => {
                                        return answer.title &&(
                                          <p key={i} className="card-text"><strong>{ answer.count }</strong></p>
                                        )
                                    }) : null}
                                </td>
                                {/*<td className="text-right pr-3 pb-2">*/}
                                {/*    {this.props.data?.length > 0 ? this.props.data.slice(0).reverse().map((answer, i) => {*/}
                                {/*        return (*/}
                                {/*          <p key={i} className="card-text"><strong>{ answer.percentage + '%' }</strong></p>*/}
                                {/*        )*/}
                                {/*    }) : <p className="card-text">No data to show yet</p>}*/}
                                {/*</td>*/}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default XcubeAnalyticsGameInfo;
