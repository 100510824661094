import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import Modal from 'react-responsive-modal';

class Player extends Component {
  render() {
    const { open, toggleModal } = this.props;
    return (
      <Modal
        open={open}
        onClose={toggleModal}
        styles={{
          modal: {
            maxWidth: 'unset',
            width: '100%',
            padding: 'unset',
            background: 'transparent',
            border: 'transparent',
          },
          overlay: {
            background: 'rgba(0, 0, 0, 0.75)',
          },
          closeButton: {
            // background: "#99cc66"
            background: 'white',
          },
        }}
        center
      >
        <ReactPlayer
          url={require('./assets/video.mp4')}
          width="100%"
          height="calc(100vh - 100px)"
          controls="true"
        />
      </Modal>
    );
  }
}

export default Player;
