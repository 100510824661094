import React, {Component} from 'react';
import "./hero-inspiration.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class HeroInspiration extends Component {
    render() {
        return (
            <div id="hero-inspiration-page">
                <Container>
                    <Row>
                        <Col md={6}>
                            <h3>
                                get inspired <br/>
                                and get going
                            </h3>
                            <p>
                                Brame offers you many various templates to help you create your next big thing. And to get that final touch, you can always check out our case studies, and see how others used Brame to great success.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default HeroInspiration;