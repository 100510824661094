import { interceptors } from './shared/helpers/axios-interceptors';

interceptors.reqInterceptor();
interceptors.respInterceptor();

export const Constants = {
  endPoint: 'https://api.brame.ch/v1',
  // endPoint: 'https://api.brame.groot.codes/v1',
  domain: 'https://server.brame.ch',
  wizard: '/wizard',

  API_KEYS: {
    STRIPE: {
      stripe_pk_test_key: 'pk_test_QX8Vyr7NdWUc1Q9Jyk2QGEtv00dhKG7wyO',
      stripe_sk_test_key: 'sk_test_2fJFSH1Pbc5NBKvcYpxXJno3004Ib6MVcy',
    },

    GA: {
      apiKey: 'AIzaSyCt4uxprTtLZdXqdHy8qdSGh6ZHy3dCmBQ',
      // clientId: '504999701336-0eo5k045s4kbjkhdsdceuom8j82jurhb.apps.googleusercontent.com',
      clientId: '936853369778-579jidk0e7nb38ki2546adcg9htshg63.apps.googleusercontent.com',
      clientSecret: 'KRPlK5u2EkvQjiELv87DmTWb',
    },
  },

  AUTHORIZATION: {
    login: '/auth/login',
    // signUp: '/stripe/charge',
    signUp: '/register',
  },

  IMAGES: {
    upload: '/image',
  },

  UPLOAD: {
    pdf: '/pdf',
  },

  ANALYTICS: {
    get(page) {
      return `/analytics/${page}`;
    },
    gameAnalytics(campaignId, gameType) {
      return `/analytics/games/${gameType}/${campaignId}`;
    },
    getAnalyticsEngagement(campaignId, period, from, to) {
      return `/analytics/${campaignId}/engagement?groupBy=${period}&from=${from}&to=${to}`;
    },
    getAnalyticsConversion(campaignId, period, from, to) {
      return `/analytics/${campaignId}/conversion?groupBy=${period}&from=${from}&to=${to}`;
    },
  },

  CAMPAIGNS: {
    getActiveCampaigns(page) {
      return `/campaigns?status=active&page=${page}`;
    },
    getInactiveCampaigns(page) {
      return `/campaigns?status=inactive&page=${page}`;
    },
    pauseAllCampaigns: '/dashboard/pauseAll',
    copyCampaign(campaignId) {
      return `/campaign/${campaignId}/copy`;
    },
    campaignAnalytics(campaignId) {
      return `/campaign/${campaignId}/analytics`;
    },
    leadsAndPrizes(campaignId) {
      return `/dashboard/${campaignId}/leads_prizes`;
    },
  },

  USER: {
    userData: '/user_data',
    clientCompanies: '/company_clients',
    getUser(id) {
      return `/users/${id}`;
    },
  },

  TEMPLATES: {
    getAll: '/templates',
  },

  GAMES: {
    getAll: '/games',
    updateGame: '/update_game',
  },

  WIZARD: {
    putData: '/campaign',
  },

  PRIZES: {
    newPrize: '/prize',
  },

  EMAIL: {
    test(campaignId) {
      return `/dashboard/${campaignId}/emails/test`;
    },
  },

  ACCOUNT: {
    general: '/account/general',
    updateUser: '/account/general_update/user',
    updateCompany: '/account/general_update/company',
    plan: '/account/plan',
    exportBilling(email) {
      return `/account/plan/${email}/export`;
    },
    updatePayment: '/stripe/update',
    team: '/account/team',
    addEmployee: '/addEmployee',
    editDeleteEmployee(id) {
      return `/user/${id}`;
    },
  },

  PUBLISH: {
    validate(campaignId) {
      return `/dashboard/${campaignId}/check_valid`;
    },
  },

  LEADS: {
    getLeads(campaignId, pageNumber, sortBy, limit) {
      // return `/players/${campaignId}?leads=true`;
      return `/players/${campaignId}?leads=true&page=${pageNumber}&sortBy=${sortBy}&limit=${limit}`;
    },
    exportLeads(campaignId) {
      return `/players/${campaignId}/export?leads=true`;
    },
  },
  COMPANY: {
    companyAnalytics(companyId) {
      return `/company/${companyId}/analytics`;
    },
    client: '/client',
    activeClientCampaigns(id) {
      return `/client/${id}/campaigns`;
    },
  },

  GROUP: {
    newGroup: '/group',
    newUser: '/group/add_user',
  },
};
