import {useState} from "react";
import Modal from "react-bootstrap/Modal";
import React from "react";
import XcubeFilledButton
  from "../../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import Row from "react-bootstrap/Row";
import XcubeInput from "../../../../../../../shared/components/xcube-input/xcube-input";
import "./add-company-modal.scss";
import XcubeInputFile
  from '../../../../../../../shared/components/xcube-input-file/xcube-input-file';
import { setDataService } from '../../../../../../../shared/services/setDataService';
import { toast } from 'react-toastify';
import addNew from '../assets/addNew.svg';
function AddCompanyModal() {
  const [show, setShow] = useState(false);
  const [newCompany, setNewCompany] = useState({
    name: '',
    logo: ''
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function submitForm(){
    if(
      newCompany.name.length > 0 &&
      newCompany.logo !== ''
    ){
      setDataService.newClientCompany(newCompany).then((resp: any) => {
        if(resp.data.message === 'success'){
          handleClose();
          window.location.reload();
        }
      });
    }else{
      toast.error('You must fill in all required fields!');
    }
  }

  function handleChange(event) {
    const value = event.value;
    const name = event.name;
    setNewCompany(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  return (
    <div>
      <div className="card" onClick={handleShow}>
        <div className="card-body">
          <h5 className="card-title">Need another company?</h5>
          <p className="card-text">To add a company</p>
          <p className="card-text" style={{color: "#88c559"}}>click here.</p>
        </div>
        <img className="card-img-top" src={addNew} alt="Card image cap"/>
      </div>

      <Modal show={show} onHide={handleClose}
             aria-labelledby="contained-modal-title-vcenter" id="addCompanyModal"
             centered>
        <Modal.Header closeButton>
          Create new Client Company
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="col-md-12">
              <XcubeInput
                label="Company name"
                placeholder="Company name"
                type="text"
                name="name"
                value={newCompany?.name || ''}
                onChange={handleChange}
              />
            </div>
          </Row>
          <Row>
            <div className="col-md-12">
              <XcubeInputFile
                name={'logo'}
                onChange={handleChange}
                previewImg={newCompany?.logo}
                label={'New Logo Image'}
                id={'logo'}
              />
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <XcubeFilledButton
            btnState="normal"
            text="Save"
            onClick={submitForm}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddCompanyModal;
