import React, { Component } from 'react';
import './campaigns.scss';
import Row from 'react-bootstrap/Row';
import addImg from './assets/add.svg';
import { getDataService } from '../../../../../../shared/services/getDataService';
import XcubeFilledButton
  from '../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button';
import XcubeRenderCampaigns
  from '../../../../../../shared/components/xcube-render-campaigns/xcube-render-campaigns';
import Pagination from "react-js-pagination";

class Campaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCampaigns: [],
      inactiveCampaigns: [],
      activeCampaignsCurrentPage: 1,
      activeTotalItemsCount: 0,
      activePagesLimit: 0,
      activeTotalPages: 0,
      inactiveCampaignsCurrentPage: 1,
      inactiveTotalItemsCount: 0,
      inactivePagesLimit: 0,
      inactiveTotalPages: 0,
    };
  }

  handlePageChange(pageNumber, type) {
    if(type === 'active') {
      this.setState({activeCampaigns: []}, () => {
        this.getActiveCampaigns(pageNumber-1);
        this.setState({activeCampaignsCurrentPage: pageNumber});
      });
    } else {
      this.setState({inactiveCampaigns: []}, () => {
        this.getInactiveCampaings(pageNumber-1);
        this.setState({inactiveCampaignsCurrentPage: pageNumber});
      });
    }
  }

  getActiveCampaigns = (page) => {
    getDataService.getActiveCampaigns(page).then((resp) => {
      this.setState((prevState) => ({
        activeCampaigns: resp.data.results,
        activeCampaignsCurrentPage: resp.data.page,
        activeTotalItemsCount: resp.data.totalResults,
        activePagesLimit: resp.data.limit,
        activeTotalPages: resp.data.totalPages
      }))
    });
  };

  getInactiveCampaings = (page) => {
    getDataService.getInactiveCampaigns(page).then((resp) => {
      this.setState((prevState) => ({
        inactiveCampaigns: resp.data.results,
        inactiveCampaignsCurrentPage: resp.data.page,
        inactiveTotalItemsCount: resp.data.totalResults,
        inactivePagesLimit: resp.data.limit,
        inactiveTotalPages: resp.data.totalPages

      }))
    });
  };

  componentDidMount() {
    this.getActiveCampaigns(0);
    this.getInactiveCampaings(0);
  }

  render() {
    return (
      <div id="campaignMain" className="pt-md-5">
        <div className="container">
          <Row>
            <div className="col-md-6 section-head mt-3">
              <h1>
                Live campaigns
              </h1>
            </div>
            <div className="col-md-6 mb-3 new-campaign-btn">
              {/*<XcubeFilledButton btnState="normal" text="New campaign" onClick={() => window.location.assign('/wizard')}/>*/}
            </div>
          </Row>
          <Row>
            <div className="col-md-12 mt-md-3 liveCampaignsXoverflow">
              <table className="table" id="leads-table">
                <thead>
                <tr>
                  <th scope="col">Campaign</th>
                  <th scope="col">Game</th>
                  <th scope="col">Visitors</th>
                  <th scope="col">Conversions</th>
                  <th scope="col">Status</th>
                </tr>
                </thead>
                <tbody>
                  <XcubeRenderCampaigns data={this.state.activeCampaigns} />
                </tbody>
              </table>
              <Pagination
                activePage={this.state.activeCampaignsCurrentPage}
                itemsCountPerPage={this.state.activePagesLimit}
                totalItemsCount={this.state.activeTotalItemsCount}
                pageRangeDisplayed={this.state.activeTotalPages}
                onChange={(page) => this.handlePageChange(page, 'active')}
              />
            </div>
          </Row>

          <Row>
            <div className="col-md-6 section-head">
              <h1>
                Inactive campaigns
              </h1>
            </div>
          </Row>
          <Row>
            <div className="col-md-12 mt-md-3 liveCampaignsXoverflow">
              <table className="table" id="leads-table">
                <thead>
                <tr>
                  <th scope="col">Campaign</th>
                  <th scope="col">Game</th>
                  <th scope="col">Visitors</th>
                  <th scope="col">Conversions</th>
                  <th scope="col">Status</th>
                </tr>
                </thead>
                <tbody>
                <XcubeRenderCampaigns data={this.state.inactiveCampaigns} />
                </tbody>
              </table>
              <Pagination
                  activePage={this.state.inactiveCampaignsCurrentPage}
                  itemsCountPerPage={this.state.inactivePagesLimit}
                  totalItemsCount={this.state.inactiveTotalItemsCount}
                  pageRangeDisplayed={this.state.inactiveTotalPages}
                  onChange={(page) => this.handlePageChange(page, 'inactive')}
              />
            </div>
          </Row>
          <Row>
            {/*<div className="media media-add" onClick={() => {window.location.assign('/wizard')}}>*/}
            {/*  <img src={addImg} alt="" />*/}
            {/*  <div className="media-body">*/}
            {/*    <h5 className="mt-0">*/}
            {/*      Want to run a new campaign?*/}
            {/*    </h5>*/}
            {/*    Click here and we will get you started in no time!*/}
            {/*  </div>*/}
            {/*</div>*/}
          </Row>
        </div>
      </div>
    );
  }
}

export default Campaigns;
