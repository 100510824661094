import React, {useEffect, useState} from 'react';
import "./xcube-analytics-details-engagement.scss";
import {Row, Col} from 'react-bootstrap'
import Chart from "react-google-charts";
import {dataHelper} from "../../helpers/data-helper";
import moment from 'moment';

function XcubeAnalyticsDetailsEngagement(props) {

    const [data, setData] = useState([]);
    const [vAxisTitle, setVAxisTitle] = useState('Time in minutes');
    const headers = ['Time Period', 'Total visit time', 'Avg time per visit'];
    const showDateFormat = () => {
        return props.showDateFormat
    }

    const getAnalytics = () => {
        let dataArr = [];
        props.data.map((row, index) => {
            if (dataHelper.periodToHMS(props.start_date, props.end_date) === 'hours') {
                dataArr.push([
                    moment(`${row.year}-${row.month}-${row.day} ${row.hour}:00`).format('HH'),
                    Math.floor((Math.round(row.total) / 60)),
                    Math.floor((Math.round(row.average) / 60))
                ]);
            } else if (dataHelper.periodToHMS(props.start_date, props.end_date) === 'days') {
                dataArr.push([
                    moment(`${row.year}-${row.month}-${row.day}`).format('DD MMM'),
                    Math.floor((Math.round(row.total) / 60)),
                    Math.floor((Math.round(row.average) / 60))
                ]);
            } else if (dataHelper.periodToHMS(props.start_date, props.end_date) === 'months') {
                dataArr.push([
                    moment(`${row.year}-${row.month}-${row.day}`).format('MMM'),
                    Math.floor((Math.round(row.total) / 60)),
                    Math.floor((Math.round(row.average) / 60))
                ]);
            }
            setData([headers, ...dataArr]);
        });
    };

    useEffect(() => {
        getAnalytics()
    }, [props.data])

    return (
        <Row>
            <Col lg={12} className="text-center pt-3 mb-lg-4">
                <h5>Engagement</h5>
                {data.length > 0
                    ? <Chart
                        className='engagementDetails'
                        style={{margin: '0 auto'}}
                        chartType="AreaChart"
                        loader={<div>Loading Chart</div>}
                        data={data}
                        options={{
                            hAxis: {
                                title: ` ${showDateFormat()} \n From ${moment(props.start_date).format('MM-DD-YYYY')} to ${moment(props.end_date).format('MM-DD-YYYY')}`,
                                titleTextStyle: {color: '#333', bold: true},
                            },
                            vAxis: {minValue: 0, title: vAxisTitle, titleTextStyle: {bold: true, fontSize: 14}},
                            chartArea: {width: '70%', height: '70%', bottom: '25%'},
                        }}
                        rootProps={{'data-testid': '1'}}
                    />
                    : <p>No data to show yet</p>
                }
            </Col>
        </Row>
    )
}

export default XcubeAnalyticsDetailsEngagement;
