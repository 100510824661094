import React, {Component} from 'react';
import {authenticationService} from "../../../shared/services/authenticationService";
import XcubeFilledButton from "../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import XcubeInput from "../../../shared/components/xcube-input/xcube-input";
import "./login.scss";
import imac from "./assets/imac.png";
import Row from "react-bootstrap/Row";
import { toast } from 'react-toastify';
import ReactDOMServer from "react-dom/server";
import { googleAnalyticsService } from '../../../shared/services/googleAnalyticsService';
import { Constants } from '../../../Constants';
class Login extends Component {

    constructor(props){
        super(props);
        this.state = {
            loginForm: {
                email: '',
                password: ''
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        // console.log(ReactDOMServer.renderToStaticMarkup(XcubeInput));
    }

    handleChange(event) {
        const value = event.value;
        const name = event.name;
        this.setState(prevState => ({
            loginForm: {
                ...prevState.loginForm,
                [name]: value
            }
        }));
    }

    componentDidMount() {
        if(authenticationService.isUserAuth()){
            window.location = '/dashboard';
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        if (
          this.state.loginForm.email !== '' &&
          this.state.loginForm.password !== ''
        ) {
            authenticationService.login({email: this.state.loginForm.email, password: this.state.loginForm.password}).then((res) => {
                window.location.reload();
            }).catch((onerror) => {
                console.log(onerror);
                toast.error("Invalid credentials!");
            });
        }else{
            toast.error("You must fill in all required fields!");
        }
    }

    render() {
        return (
            <div id="login">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-7 hero-text">
                            <Row>
                                <div className="col-md-12">
                                    <h1>
                                        Good to see <br/> you again.
                                    </h1>
                                    <p className="w-50">Enter your e-mail address and password below
                                        to sign into your dashboard and manage your
                                        campaigns.
                                    </p>
                                </div>
                            </Row>
                            <Row className="form-row">
                                <div className="col-md-10">
                                    <form onSubmit={this.handleSubmit}>
                                        <XcubeInput label="E-mail" onChange={this.handleChange} value={this.state.loginForm.email || ''} type="email" name="email" required={true}/>
                                        <XcubeInput label="Password" onChange={this.handleChange} value={this.state.loginForm.password || ''} type="password" name="password" required={true}/>
                                        <p>Can't remember your password?</p>
                                        <span><a>Click here</a> and we will reset it for you in no time. If you haven’t got an account yet, we can get you set up with one <a href={'/register/payment'}>right here.</a></span>
                                        <XcubeFilledButton text="Log in" btnState="normal" type="submit"/>
                                    </form>
                                </div>
                            </Row>
                        </div>
                        <div className="col-md-5 d-mob-none">
                            <img src={imac} className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
