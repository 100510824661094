import React, { Component} from 'react';
import "./xcube-select.scss";
import { Select } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';

class XcubeSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: ''
        };
    }

    render() {
        return (
            <div id="select">
                <label className="xcube-input-label">{this.props.label}</label>
                <Select
                  attributesInput={{
                      id: this.props.id,
                      name: this.props.name,
                  }}
                  value={this.props.defaultValue}
                  optionList={this.props.options || [{id: '', name: ''}]}
                  onChange={(res, e) => {
                      this.props.onChange({
                          name: this.props.name,
                          value: res.id
                      });
                  }}
                  onBlur={() => {}}
                  customStyleOptionListContainer={{ maxHeight: '200px', overflow: 'auto', fontSize: '14px' }}
                  validationOption={{
                      name: this.props.errorName,
                      check: this.props.required,
                      required: this.props.required
                  }}
                />
            </div>
        );
    }
}

export default XcubeSelect;

// EXAMPLE:
  // <XcubeSelect label="Selected template"
  //   options={this.state.templates}
  //   onChange={this.handleChange}
  //   id={'select_template'}
  //   name={'template_id'}
  //   errorName={'template'}
  //   defaultValue={this.state.campaignData.template_id}
  //   required={true}/>
