import React, {Component} from 'react';
import './create-campaign-wizard.scss';
import StepWizard from 'react-step-wizard';
import CreateCampaignWizardStart from "./components/create-campaign-wizard-start/create-campaign-wizard-start";
import CreateCampaignWizardGlobal from "./components/create-campaign-wizard-goal/create-campaign-wizard-goal";
import CreateCampaignWizardTemplate from "./components/create-campaign-wizard-template/create-campaign-wizard-template";
import CreateCampaignWizardGameType
    from "./components/create-campaign-wizard-game-type/create-campaign-wizard-game-type";
import CreateCampaignWizardExpressBrand
    from "./components/create-campaign-wizard-express-brand/create-campaign-wizard-express-brand";
import CreateCampaignWizardInfo from "./components/create-campaign-wizard-info/create-campaign-wizard-info";
import CreateCampaignWizardComplete from "./components/create-campaign-wizard-complete/create-campaign-wizard-complete";
import CreateCampaignWizardGdpr
    from "./components/create-campaign-wizard-gdpr/create-campaign-wizard-gdpr";
import CreateCampaignWizardNav from "./components/create-campaign-wizard-nav/create-campaign-wizard-nav";
import {setDataService} from "../../shared/services/setDataService";
import moment from 'moment';
import { toast } from 'react-toastify';
import { googleAnalyticsService } from '../../shared/services/googleAnalyticsService';
import InsertAccountIdModal
    from '../dashboard/components/dashboard/components/insertAccountIdModal/insertAccountIdModal';
import CreateCampaignWizardPrizes
    from './components/create-campaing-wizard-prizes/create-campaign-wizard-prizes';

class CreateCampaignWizard extends Component {

    constructor(props){
        super(props);
        this.state = {
            name: '',
            description: '',
            type: '',
            template_id: '',
            game_id: '',
            game_settings: '',
            primary_color: {"hsl":{"h":25.135135135135133,"s":0.8631600023337365,"l":0.5038000499999999,"a":1},"hex":"#ee6f13","rgb":{"r":238,"g":111,"b":19,"a":1},"hsv":{"h":25.135135135135133,"s":0.919,"v":0.9320999999999999,"a":1},"oldHue":25.135135135135133,"source":"rgb"},
            secondary_color: {"hsl":{"h":25.135135135135133,"s":0.8631600023337365,"l":0.5038000499999999,"a":1},"hex":"#ee6f13","rgb":{"r":238,"g":111,"b":19,"a":1},"hsv":{"h":25.135135135135133,"s":0.919,"v":0.9320999999999999,"a":1},"oldHue":25.135135135135133,"source":"rgb"},
            logo: '',
            instagram: '',
            facebook: '',
            twitter: '',
            seo_title: '',
            seo_description: '',
            seo_image: '',
            legal_company: '',
            legal_phone: '',
            legal_website: '',
            legal_email: '',
            legal_address: '',
            legal_city: '',
            legal_zip_code: '',
            legal_show_in_connect_section: '',
            legal_country: '',
            legal_data_source: '',
            start_date: moment(new Date()).format('MM-DD-YYYY') + ' 00:00',
            end_date: moment(new Date()).format('MM-DD-YYYY') + ' 00:00',
            client_id: '',
            prizes: [],
            general_notify_team: '',
            general_notify_sponsor: '',
            general_reply_to: '',
            general_sender_name: '',
            winner_email_body: '',
            winner_email_subject: '',
            winner_email_introduction: '',
            winner_email_background: '',
            winner_email_prize_image: '',
            policy: {
                source: 'text',
                background: '',
                privacy_policy: '',
                terms_of_use: ''
            }
        };

        this.updateState = this.updateState.bind(this);
        this.updateClearState = this.updateClearState.bind(this);
        this.submitData = this.submitData.bind(this);
    }

    updateState(data){
        let name = data.name;
        let value = data.value;
        this.setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    updateClearState(data){
        // console.log(data);
        this.setState(prevState => ({
            ...prevState,
              [data.name]: data.value
        }));
    }

    submitData(){

        googleAnalyticsService.isGAReady().then((resGA) => {
            if(resGA.status){
                setDataService.setWizardData(this.state).then((res:any) => {
                    if(res.data.message === "success"){
                        googleAnalyticsService.insertProperty(
                          resGA.GAData.accountId,
                          `https://editor.brame.ch/?id=${res.data.body.id}`,
                          res.data.body.name
                        ).then((resProperty) => {
                            googleAnalyticsService.insertView(
                              resGA.GAData.accountId,
                              resProperty.result.id,
                              res.data.body.name,
                              `https://editor.brame.ch/?id=${res.data.body.id}`
                            ).then((resView) => {
                                setDataService.updateCampaignAnalytics(res.data.body.id, {
                                    propertyId: resProperty.result.id,
                                    viewId: resView.result.id
                                }).then((updateCampaignAnalyticsRes) => {
                                    toast.success('Campaign successfully created!');
                                    // window.open("https://editor.brame.ch/?id=" + JSON.stringify(res.data.body.id), "_blank");
                                    window.location.assign('/dashboard');
                                }).catch(onerror => console.log(onerror));
                            }).catch(onerror => console.log(onerror));
                        }).catch(onerror => console.log(onerror));
                    }else{
                        // console.log(res.data.body);
                        toast.error(res.data.body);
                    }
                });
            }else{
                document.getElementById('GAAccountId').click(); // Ask user to add Account ID.
            }
        });
    }

    render() {
        return (
            <div id="createCampaignWizard">
                <InsertAccountIdModal/>
                {/*<CreateCampaignWizardNavbar/>*/}
                <StepWizard initialStep={1} nav={<CreateCampaignWizardNav steps={['Start', 'Campaign', 'Template', 'Game', 'Brand', 'Prize', 'Social', 'Legal', 'Finish']}/>}>
                    <CreateCampaignWizardStart updateState={this.updateState} state={this.state} updateClearState={this.updateClearState}/>
                    <CreateCampaignWizardGlobal updateState={this.updateState} state={this.state}/>
                     <CreateCampaignWizardTemplate updateState={this.updateState} state={this.state}/>
                    <CreateCampaignWizardGameType updateState={this.updateState} state={this.state}/>
                    <CreateCampaignWizardExpressBrand updateState={this.updateState} state={this.state}/>
                    <CreateCampaignWizardPrizes updateState={this.updateState} state={this.state}/>
                   <CreateCampaignWizardInfo updateState={this.updateState} state={this.state}/>
                    <CreateCampaignWizardGdpr updateState={this.updateState} state={this.state}/>
                    <CreateCampaignWizardComplete state={this.state} submitData={this.submitData}/>
                </StepWizard>
            </div>
        );
    }
}

export default CreateCampaignWizard;
