import {useState} from "react";
import Modal from "react-bootstrap/Modal";
import React from "react";
import XcubeFilledButton
    from "../../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import Row from "react-bootstrap/Row";
import XcubeInput from "../../../../../../../shared/components/xcube-input/xcube-input";
import XcubeSelect from "../../../../../../../shared/components/xcube-select/xcube-select";
import XcubeInputFile from "../../../../../../../shared/components/xcube-input-file/xcube-input-file";
import XcubeDatePicker from "../../../../../../../shared/components/xcube-date-picker/xcube-date-picker";
import "./new-prize-modal.scss";
import moment from 'moment';
import { setDataService } from '../../../../../../../shared/services/setDataService';
import {
    useParams
} from "react-router-dom";
import { toast } from 'react-toastify';
import Col from 'react-bootstrap/Col';

function NewPrizeModal() {
    const [show, setShow] = useState(false);
    const [newPrize, setNewPize] = useState({
        campaign_id: useParams().id,
        name: '',
        unique_id: '',
        description: '',
        value: '',
        value_type: 'Discount(%)',
        url: '',
        image: '',
        draw_type: '',
        draw_date: moment(new Date()).format('MM-DD-YYYY') + ' 00:00',
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function handleChange(event) {
        const value = event.value;
        const name = event.name;
        setNewPize(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    function submitForm(){
        if(
          newPrize.name.length > 0 &&
          newPrize.description.length > 0 &&
          newPrize.value.length > 0 &&
          newPrize.image !== ''
        ){
            setDataService.createNewPrize(newPrize).then((resp: any) => {
                if(resp.data.message === 'success'){
                    window.location.reload();
                }
            });
        }else{
            toast.error('You must fill in all required fields!');
        }
    }

    return (
        <div>
            <XcubeFilledButton btnState="normal" text="New prize" onClick={handleShow}/>

            <Modal show={show} onHide={handleClose} size="lg"
                   aria-labelledby="contained-modal-title-vcenter" id="newPrizeModal"
                   centered>
                <Modal.Header closeButton>
                    <span>
                        New prize
                    </span>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <div className="col-md-6">
                            <XcubeInput
                              label="Name"
                              placeholder="Prize name"
                              id={'name'}
                              onChange={handleChange}
                              value={newPrize.name || ''}
                              type="text"
                              required={true}
                              name={'name'}
                            />
                        </div>
                        <div className="col-md-6">
                            <XcubeInput
                              label="Number of prizes"
                              placeholder="20"
                              id={'value'}
                              onChange={handleChange}
                              value={newPrize.value || ''}
                              type="text" required={true}
                              name={'value'}
                            />
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <XcubeInput
                              label="Description"
                              placeholder="Prize description"
                              id={'description'}
                              onChange={handleChange}
                              value={newPrize.description || ''}
                              type="text"
                              required={true}
                              name={'description'}
                            />
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-6">
                            <div className="datePicker">
                                <XcubeDatePicker
                                  label="Prize available from"
                                  onChange={handleChange}
                                  value={newPrize.draw_date}
                                  name={'draw_date'}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <XcubeInputFile
                              name={'image'}
                              onChange={handleChange}
                              previewImg={newPrize.image.thumbnail_url}
                              label={'Attachment'}
                              id={'prize_image'}
                            />
                        </div>
                    </Row>
                    <Row className="d-none">
                        <Col md={12}>
                            <XcubeSelect
                              label="Chance of winning"
                              options={[
                                  {id: '1', name: '1/10'},
                                  {id: '2', name: '1/50'},
                                  {id: '3', name: '1/100'},
                              ]}
                              onChange={(event) => handleChange(event, 'percentage')}
                              id={'percentage'}
                              name={'percentage'}
                              errorName={'chance of winning'}
                              defaultValue={newPrize.percentage}
                              required={true}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <XcubeFilledButton
                      btnState="normal"
                      text="Save"
                      onClick={submitForm}
                    />
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default NewPrizeModal;
