import React, {Component} from 'react';
import email from "../../assets/email.svg";
import statistics from "../../assets/statistics.svg";
import notifications from "../../assets/notifications.svg";
import other from "../../assets/other.svg";
import Row from "react-bootstrap/Row";
import "./mainIntegration.scss";

class MainIntegration extends Component {
    render() {
        return (
            <div id="mainIntegration">
                <Row>
                    <div className="col-md-3">
                        <div className="card" onClick={() => {this.props.setStep('email-integration')}}>
                            <div className="row no-gutters">
                                <div className="col-md-6 card-img-col">
                                    <img src={email} className="card-img" alt=""/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <h5 className="card-title">E-mail</h5>
                                        <p className="card-text">Select a custom single or mass email provider.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card" onClick={() => {this.props.setStep('statistics-integration')}}>
                            <div className="row no-gutters">
                                <div className="col-md-6 card-img-col">
                                    <img src={statistics} className="card-img" alt=""/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <h5 className="card-title">Statistics</h5>
                                        <p className="card-text">Tie in custom analytics
                                            solutions and tracking.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card" onClick={() => {this.props.setStep('notifications-integration')}}>
                            <div className="row no-gutters">
                                <div className="col-md-6 card-img-col">
                                    <img src={notifications} className="card-img" alt=""/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <h5 className="card-title">Notifications</h5>
                                        <p className="card-text">Get notified - or notify the
                                            visitors - about events,</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card">
                            <div className="row no-gutters">
                                <div className="col-md-6 card-img-col">
                                    <img src={other} className="card-img" alt=""/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <h5 className="card-title">Other</h5>
                                        <p className="card-text">Set up a number of various
                                            integrations.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        );
    }
}

export default MainIntegration;
