import React, {Component} from 'react';
import "./public-footer.scss";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../navbar/assets/logo.svg";
import Nav from "react-bootstrap/Nav";
import {NavLink} from "react-router-dom";

class PublicFooter extends Component {
    render() {
        return (
            <div id="public-footer">
                <Navbar>
                    <Container>
                        <Navbar.Brand to="/">
                            <img
                                alt=""
                                src={logo}
                                width="100"
                                height="30"
                                className="d-inline-block align-top"
                            />
                        </Navbar.Brand>

                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav>
                                <a className="nav-link">
                                    © 2020 Brame. All Rights Reserved
                                </a>
                            </Nav>
                            <Nav className="ml-auto">
                                <NavLink activeClassName="active" className="nav-link" to="/terms-and-conditions">Terms & Conditions</NavLink>
                                <NavLink activeClassName="active" className="nav-link" to="/privacy-policy">Privacy policy</NavLink>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
}

export default PublicFooter;