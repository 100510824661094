import React, {Component} from 'react';
import XcubeFilledButton from "../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import "./community.scss";
import Row from "react-bootstrap/Row";
import logos from "./assets/Logos.png";
import XcubeCardCarousel from "../../../../../shared/components/xcube-card-carousel/xcube-card-carousel";
import slide1 from "./assets/slide1.png";
class Community extends Component {
    render() {
        return (
            <div id="community-home-page">
                <div className="container">
                    <Row>
                        <div className="col-md-9 offset-md-2 hero-text">
                            <h1>Excite your community.</h1>
                            <p>In the fast-moving omnichannel marketing industry of today, the most valuable source customers can give is engagement. See how Brame clients use our software to bring their brands to the next level.</p>
                            <XcubeFilledButton text="View more" btnState="normal"/>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-9 offset-md-2 mt-5">
                            <img src={logos} alt="" className="img-fluid"/>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-9 offset-md-2 mt-5">
                            <XcubeCardCarousel autoplay={false} images={[slide1, slide1, slide1]}/>
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Community;