import React, {Component} from 'react';
import pie from "./assets/wiz-conversion.png";
import "./xcube-analytics-conversions.scss";
import { dataHelper } from '../../helpers/data-helper';

function XcubeAnalyticsConversions(props) {


    let totalConversions = dataHelper.conversionCalculator(props.data.uniqueVisitors < props.data.leads ? props.data.leads : props.data.uniqueVisitors , props.data.leads);

    return (
      <div id="dashboardConversions">
          <div className="card">
              <div className="card-title-visitors mt-3 ml-3">Conversions</div>
              <div className="row no-gutters">
                  <div className="col-7">
                      <div className="card-body pt-2">
                          <p className="card-text"><small><strong>{props.data.uniqueVisitors ? props.data.uniqueVisitors + '' : '0'}</strong> visitors</small></p>
                          {/*<p className="card-text"><small><strong>{props.data?.players < props.data?.leads ? props.data.leads || '0' : props.data?.players || '0'} </strong>players</small></p>*/}
                          <p className="card-text"><small><strong>{props.data?.leads || '0'} </strong>leads</small></p>
                          <p className="card-text"><small><strong id='conv'>{totalConversions} % </strong>conversion</small></p>
                      </div>
                  </div>
                  <div className="col-4 text-right pr-4 pb-2 pt-2">
                      <img src={pie} className="visitorImg" alt=""/>
                  </div>
              </div>
          </div>
      </div>
    );
}

export default XcubeAnalyticsConversions;
