import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import './xcube-filled-button.scss';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prefer-stateless-function
class XcubeFilledButton extends Component {
  render() {
    return (
      <div id="xcube-filled-button">
        <Button variant="link" className={this.props.btnState} disabled={this.props.disabled} onClick={this.props.onClick} type={this.props.type} id={this.props.id}>{this.props.text}</Button>
      </div>
    );
  }
}

XcubeFilledButton.propTypes = {
  btnState: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  text: PropTypes.string,
  id: PropTypes.string
};

XcubeFilledButton.defaultProps = {
  btnState: 'normal',
  disabled: false,
  onClick() {},
  type: 'text',
  text: 'Button',
  id: 'filledButton'
};

export default XcubeFilledButton;
