import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import "./notificationsIntegration.scss";
import notifications from "../../assets/notifications.svg";

class NotificationsIntegration extends Component {
    render() {
        return (
            <div id="notificationsIntegration">
                <Row>
                    <div className="col-md-3">
                        <div className="card" onClick={() => {this.props.setStep('step3')}}>
                            <div className="row no-gutters">
                                <div className="col-md-6 card-img-col">
                                    <img src={notifications} className="card-img" alt=""/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <h5 className="card-title">Firebase</h5>
                                        <p className="card-text">Integrate firebase.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        );
    }
}

export default NotificationsIntegration;