import React, { useEffect, useState} from 'react';
import "./xcube-analytics-conversion-details.scss";
import {Row, Col} from 'react-bootstrap'
import Chart from "react-google-charts";
import moment from 'moment';
import {dataHelper} from "../../helpers/data-helper";

function XcubeAnalyticsConversionDetails(props) {

    const [data, setData] = useState([]);
    const headers = ['Date', 'Visitors', 'Leads'];
    const showDateFormat = () => {
        return props.showDateFormat
    }

    const getAnalytics = () => {
        let dataArr = [];
        console.log(props)
        props.data.visitors.map((row, index) => {
            if (dataHelper.periodToHMS(props.start_date, props.end_date) === 'hours') {
                dataArr.push([
                    moment(`${row.year}-${row.month}-${row.day} ${row.hour}:00`).format('HH'),
                    parseInt(row.visitors),
                    parseInt(props.data.leads[index]?.count),
                    // parseInt(props.data.players[index]?.count),
                ]);
            } else if (dataHelper.periodToHMS(props.start_date, props.end_date) === 'days') {
                dataArr.push([
                    moment(`${row.year}-${row.month}-${row.day}`).format('DD MMM'),
                    parseInt(row.visitors),
                    parseInt(props.data.leads[index]?.count),
                    // parseInt(props.data.players[index]?.count),
                ]);
            } else if (dataHelper.periodToHMS(props.start_date, props.end_date) === 'months') {
                dataArr.push([
                    moment(`${row.year}-${row.month}-01`).format('MMM'),
                    parseInt(row.visitors),
                    parseInt(props.data.leads[index]?.count),
                    // parseInt(props.data.players[index]?.count),
                ]);
                if(props.data.visitors.length === 1) {
                    dataArr.push([
                        moment(`${row.year}-${row.month}-01`).format('MMM'),
                        parseInt(row.visitors),
                        parseInt(props.data.leads[index]?.count),
                        // parseInt(props.data.players[index]?.count),
                    ]);
                }

            }
            setData([headers, ...dataArr]);
        });
        if(!props.data.visitors.length){
            setData([]);
        }
    };

    useEffect(() => {
        getAnalytics()
    }, [props.data.visitors])


    // const getAnalytics = () => {
    //   if(this.props.visitsPerMonth?.result?.error) {
    //   }
    //   else{
    //     let dataArr = [];
    //
    //     if(this.props.visitsPerMonth?.result?.reports[0]?.data?.rows) {
    //       this.props.visitsPerMonth.result.reports[0].data.rows.map((row, index) => {
    //
    //         if(moment.duration(moment(this.props.end_date).diff(moment(this.props.start_date))).asDays() === 0) {
    //           dataArr.push([
    //             row.dimensions[1] ? moment(row.dimensions[0]).format('YYYY-MM-DD') : row.dimensions[0],
    //             parseInt(row.metrics[0].values[0]),
    //             parseInt(this.props.campaignData.leads_distribution[index]?.leads),
    //             parseInt(this.props.campaignData.leads_distribution[index]?.players)
    //           ]);
    //         } else if (moment.duration(moment(this.props.end_date).diff(moment(this.props.start_date))).asDays() <= 31) {
    //           dataArr.push([
    //             row.dimensions[1] ? moment(row.dimensions[0]).format('YYYY-MM-DD') : moment(row.dimensions[0]).format('DD MMM'),
    //             parseInt(row.metrics[0].values[0]),
    //             parseInt(this.props.campaignData.leads_distribution[index]?.leads),
    //             parseInt(this.props.campaignData.leads_distribution[index]?.players)
    //           ]);
    //         } else if (moment.duration(moment(this.props.end_date).diff(moment(this.props.start_date))).asDays() > 31) {
    //           dataArr.push([
    //             row.dimensions[1] ? moment(row.dimensions[0]).format('YYYY-MM-DD') : moment(row.dimensions[0]).format('MMM'),
    //             parseInt(row.metrics[0].values[0]),
    //             parseInt(this.props.campaignData.leads_distribution[index]?.leads),
    //             parseInt(this.props.campaignData.leads_distribution[index]?.players)
    //           ]);
    //         }
    //
    //         this.setState({
    //           data: [['Date', 'Visitors', 'Leads', 'Players'], ...dataArr]
    //         })
    //       });
    //     }
    //   }
    // };

    return (
        <Row>
            <Col lg={12} className="text-center pt-3 mb-lg-4">
                <h5>Conversions</h5>
                {data.length
                    ? <Chart
                        className="detailsConversion"
                        chartType="AreaChart"
                        style={{margin: '0 auto'}}
                        loader={<div>Loading Chart</div>}
                        data={data}
                        options={{
                            hAxis: {
                                title: ` ${showDateFormat()} \n From ${moment(props.start_date).format('MM-DD-YYYY')} to ${moment(props.end_date).format('MM-DD-YYYY')}`,
                                titleTextStyle: {color: '#333', bold: true}
                            },
                            vAxis: {minValue: 0, title: 'Count', titleTextStyle: {bold: true, fontSize: 15}},
                            chartArea: {width: '70%', height: '70%', bottom: "25%"},
                        }}
                        rootProps={{'data-testid': '1'}}
                    />
                    : <p>No data to show yet</p>
                }
            </Col>
        </Row>
    )
}

export default XcubeAnalyticsConversionDetails;
