import React, {Component} from 'react';
import image from "./assets/iMac.png";
import "./intro.scss";
import Row from "react-bootstrap/Row";
class Intro extends Component {
    render() {
        return (
            <div id="why-brame-page-intro">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 intro-text">
                            <Row className="intro-text-white">
                                <div className="col-md-12">
                                    <h3>
                                        Why experience matters
                                    </h3>
                                    <p>
                                        Purchasing decisions (yes, even B2B ones) are made by humans, humans make decisions based on logic and feelings, and feelings are formed by our experiences.
                                        <br/>
                                        <br/>
                                        Making an experience something to remember is a challenge, which is where gamification steps in.
                                    </p>
                                </div>
                            </Row>
                            <Row className="mt-md-5">
                                <div className="col-md-12">
                                    <h3>
                                        Gamification
                                    </h3>
                                    <span>
                                        /ˌɡeɪmɪfɪˈkeɪʃ(ə)n/
                                    </span>
                                    <p>
                                        Gamification is the application of typical elements of game playing (e.g. point scoring, competition with others, rules of play) to other areas of activity.
                                        <br/>
                                        <br/>
                                        Gamification is exciting because it promises to make the hard, boring or annoying stuff in life fun. This is why it is one of the best marketing tools out there!
                                    </p>
                                </div>
                            </Row>
                        </div>
                        <div className="col-md-7 image-col">
                            <img src={image} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Intro;