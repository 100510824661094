import React, { useEffect, useState} from 'react';
import "./xcube-chartjs-geo.scss";
import Chart from 'react-google-charts';

function XcubeChartjsGeo (props){
    const [country, setCountry] = useState([
        ["Country", "Popularity"]
    ])

    useEffect(() => {
        props.data.map((newCountry) => {
            setCountry(country => [...country,[newCountry.country, parseInt(newCountry.count)]])
        })
    }, [props.data]);

        return (
            <div>
                <div id="xcubeGeoChart">
                    <Chart
                      chartEvents={[
                          {
                              eventName: "select",
                              callback: ({ chartWrapper }) => {
                                  const chart = chartWrapper.getChart();
                                  const selection = chart.getSelection();
                                  if (selection.length === 0) return;
                                  // const region = this.state.country[selection[0].row + 1];
                              }
                          }
                      ]}
                      chartType="GeoChart"
                      width="100%"
                      height="60%"
                      data={country}
                      options={{
                          sizeAxis: { minValue: 0, maxValue: 100 },
                          colorAxis: { colors: ['#a0e869', '#577e39'] },
                          // tooltip: { isHtml: true},
                          region: '150', // Europe
                          // displayMode: 'markers',
                          }}

                      mapsApiKey="AIzaSyCt4uxprTtLZdXqdHy8qdSGh6ZHy3dCmBQ"
                      rootProps={{ 'data-testid': '3' }}
                    />
                </div>
            </div>
        );
}

export default XcubeChartjsGeo;
