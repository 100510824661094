import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import React, {Component} from "react";

class XcubeColorPicker extends Component {
    state = {
        displayColorPicker: false,
        color: {
            r: '241',
            g: '112',
            b: '19',
            a: '1',
        },
        name: this.props.name,
        propColor: this.props.propColor
    };

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        this.setState({ color: color.rgb });
        this.props.onChange(JSON.stringify(color), this.state.name);
    };

    UNSAFE_componentWillReceiveProps(props) {
        if(props.propColor){
            try {
                JSON.parse(props.propColor);
            } catch (e) {
                return false;
            }
            this.setState({propColor: JSON.parse(props.propColor)})
        }
    }



    render() {

        const styles = reactCSS({
            'default': {
                color: {
                    width: '64px',
                    height: '24px',
                    borderRadius: '2px',
                    background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
                },
                colorProp: {
                    width: '64px',
                    height: '24px',
                    borderRadius: '2px',
                    background: `${ this.state.propColor?.hex }`,
                },
                swatch: {
                    background: '#fff',
                    borderRadius: '1px',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <div>
                <div style={ styles.swatch } onClick={ this.handleClick }>
                    <div style={ this.state.propColor ? styles.colorProp : styles.color } />
                </div>
                { this.state.displayColorPicker ? <div style={ styles.popover }>
                    <div style={ styles.cover } onClick={ this.handleClose }/>
                    <SketchPicker color={ this.state.propColor ? this.state.propColor : this.state.color } onChange={ this.handleChange } />
                </div> : null }

            </div>
        )
    }
}

export default XcubeColorPicker;
