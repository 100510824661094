import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import previewIcon from "../campaign-status/assets/search-icon.svg";
import "./campaign-publishing.scss";
import campaignActive from './assets/campaigns-active.png';
import campaignInactive from './assets/campaigns-inactive.png';
import emailActive from './assets/email-active.png';
import emailInactive from './assets/email-inactive.png';
import prizeActive from './assets/prize-active.png';
import prizeInactive from './assets/prize-inactive.png';
import gameSettingsActive from './assets/game-settings-active.png';
import XcubeFilledButton
    from "../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import XcubeDatetimePicker from "../../../../../../shared/components/xcube-datetime-picker/xcube-datetime-picker";
import XcubeSelect from "../../../../../../shared/components/xcube-select/xcube-select";
import XcubeShareModal
    from '../../../../../../shared/components/xcube-share-modal/xcube-share-modal';
import {getDataService} from "../../../../../../shared/services/getDataService";
import { setDataService } from '../../../../../../shared/services/setDataService';
import { toast } from 'react-toastify';
class CampaignPublishing extends Component {
    campaignId;
    constructor(props){
        super(props);
        this.state = {
            campaignData: {}
        };
    }

    handleChange = (event) => {
        // console.log(event);
        const value = event.value;
        const name = event.name;
        this.setState(prevState => ({
            campaignData: {
                ...prevState.campaignData,
                [name]: value
            }
        }));
    };

    componentDidMount() {
        this.campaignId = this.props.match.params.id;
        this.props.changeTitle('Campaign - Publishing');
        getDataService.campaignGeneralPage(this.campaignId).then((data) => {
            this.setState({
                campaignData: data.data.body
            });
        });

        getDataService.validateSetup(this.campaignId).then((data) => {
            // console.log(data);
           this.setState({
               validation: data.data
           })
        });
    }

    submitData = () => {
        setDataService.updateCampaignData(this.state.campaignData, this.campaignId).then((res: any) => {
            toast.success('Campaign successfully updated!');

        });
    };

    publishCampaign = () => {
        setDataService.updateCampaignData({status: 'active', id: this.campaignId}).then((res: any) => {
            window.location.reload();
        });
    };

    render() {
        return (
            <div id="campaignPublishing" className="pt-md-5">
                <div className="container">
                    <Row>
                        <div className="col-md-12 section-head">
                            <h1>
                                Status
                            </h1>
                            {this.state?.campaignData?.status === 'active'
                              ? <h2>Your campaign is <strong>live</strong> and doing fine!</h2>
                              : <h2>Your campaign is <strong>{this.state?.campaignData?.status}</strong>!</h2>
                            }
                            <p>Preparing to launch a Wheel of Fortune game on the Christmas Cheer template.</p>
                            <Row className="mt-md-4">
                                <div className="col-md-4">
                                    <XcubeSelect
                                      label="Campaign type"
                                      defaultValue={this.state.campaignData?.type}
                                      options={[
                                        {id: 'slide_in', name: 'Slide-in'},
                                        {id: 'fullscreen', name: 'Fullscreen'},
                                        {id: 'pop_up', name: 'Pop-up'}
                                      ]}
                                      id={'type'}
                                      name={'type'}
                                      errorName={'type'}
                                      onChange={this.handleChange}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <div className="startDatePicker">
                                        <XcubeDatetimePicker
                                          label="Start date"
                                          dateLabel="Starts on"
                                          timeLabel=" at "
                                          onChange={this.handleChange}
                                          name={'start_date'}
                                          date={this.state.campaignData?.start_date}
                                          time={this.state.campaignData?.start_date}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="startDatePicker">
                                        <XcubeDatetimePicker
                                          label="End date"
                                          dateLabel="Ends on"
                                          timeLabel=" at "
                                          onChange={this.handleChange}
                                          name={'end_date'}
                                          date={this.state.campaignData?.end_date}
                                          time={this.state.campaignData?.end_date}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <Row className="mt-md-4">
                                <div className="col-md-4 col-lg-2 mt-md-3">
                                    <XcubeFilledButton
                                      btnState="normal"
                                      text="Save changes"
                                      onClick={this.submitData}
                                    />
                                </div>
                                <div className="col-md-5 mt-md-2">
                                    <div className="btn-group" role="group">
                                        <a href={this.state?.campaignData?.campaign_url}
                                           className="preview"
                                           target={'_blank'}
                                           rel="noopener noreferrer">
                                            <img src={previewIcon} alt=""/> Preview
                                        </a>
                                        <XcubeShareModal
                                          linkText={'Get link'}
                                          qrCode={this.state.campaignData?.qr}
                                          linkShare={this.state.campaignData?.campaign_url}
                                        />
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <hr/>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12 section-head">
                            <h1>
                                Publish campaign
                            </h1>
                            <span>You have a couple of things to set up before you go.</span>
                            <p>There are some settings that you ned to configure before you can publish your campaign. <br/>
                                Check out the following tiles to sort this out.</p>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">

                            <div className="card-deck mt-md-3">
                                <div className="card main-card">
                                    <div className="card m-1">
                                        <img
                                          className="card-img-top"
                                          src={this.state.validation?.campaign
                                            ? campaignActive
                                            : campaignInactive
                                          }
                                          alt="Card cap"
                                        />
                                        <div className="card-body p-0 text-center">
                                            <p className="card-text main-card-text">General</p>
                                        </div>
                                    </div>
                                    <div className="card-body text-center second-body">
                                        <p className="card-text">
                                            {this.state.validation?.campaign
                                              ? 'You have filled in the general campaign data.'
                                              : 'You have not filled up general campaign data.'
                                            }
                                        </p>
                                        <div className="card-text pt-md-3">
                                            {this.state.validation?.campaign
                                              ? <small className="text-muted" onClick={() => window.location.assign(`/campaigns/${this.campaignId}/general`)}>Click this tile to <br/> change the settings</small>
                                              : <XcubeFilledButton text="Fix now" btnState="normal" onClick={() => window.location.assign(`/campaigns/${this.campaignId}/general`)}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="card main-card">
                                    <div className="card m-1">
                                        <img
                                          className="card-img-top"
                                          src={ gameSettingsActive }
                                          alt="Card cap"
                                        />
                                        <div className="card-body p-0 text-center">
                                            <p className="card-text main-card-text">Game settings</p>
                                        </div>
                                    </div>
                                    <div className="card-body text-center second-body">
                                        <p className="card-text">You have configured the game and added content.</p>
                                        <p className="card-text pt-md-3">
                                            <small className="text-muted" onClick={() => window.location.assign(`/campaigns/${this.campaignId}/game-settings`)}>Click this tile to <br/> change the settings</small>
                                        </p>
                                    </div>
                                </div>
                                <div className="card main-card">
                                    <div className="card m-1">
                                        <img
                                          className="card-img-top"
                                          src={this.state.validation?.prize
                                            ? prizeActive
                                            : prizeInactive
                                          }
                                          alt="Card cap"
                                        />
                                        <div className="card-body p-0 text-center">
                                            <p className="card-text main-card-text">Prizes</p>
                                        </div>
                                    </div>
                                    <div className="card-body text-center second-body">
                                        <p className="card-text">
                                            {this.state.validation?.prize
                                              ? 'You have set up prizes for this campaign.'
                                              : 'You have not set up prizes for this campaign.'
                                            }
                                        </p>
                                        <div className="card-text pt-md-3">
                                            {this.state.validation?.prize
                                              ? <small className="text-muted" onClick={() => window.location.assign(`/campaigns/${this.campaignId}/prizes`)}>Click this tile to <br/> change the settings</small>
                                              : <XcubeFilledButton text="Fix now" btnState="normal" onClick={() => window.location.assign(`/campaigns/${this.campaignId}/prizes`)}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="card main-card">
                                    <div className="card m-1">
                                        <img
                                          className="card-img-top"
                                          src={this.state.validation?.email
                                            ? emailActive
                                            : emailInactive
                                          }
                                          alt="Card cap"
                                        />
                                        <div className="card-body p-0 text-center">
                                            <p className="card-text main-card-text">E-mail</p>
                                        </div>
                                    </div>
                                    <div className="card-body text-center second-body">
                                        <p className="card-text">
                                            {this.state.validation?.email
                                              ? 'You have set up the winner e-mail.'
                                              : 'You have not set up the winner e-mail.'
                                            }
                                        </p>
                                        <div className="card-text pt-md-3">
                                            {this.state.validation?.email
                                              ? <small className="text-muted" onClick={() => window.location.assign(`/campaigns/${this.campaignId}/emails`)}>Click this tile to <br/> change the settings</small>
                                              : <XcubeFilledButton text="Fix now" btnState="normal" onClick={() => window.location.assign(`/campaigns/${this.campaignId}/emails`)}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12 mt-md-5 publish">
                            <hr/>
                            <XcubeFilledButton
                              text="Publish"
                              btnState="normal"
                              disabled={!(this.state.validation?.email && this.state.validation?.prize && this.state.validation?.campaign)}
                              onClick={() => this.publishCampaign()}
                            />
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
}

export default CampaignPublishing;
