import React, {Component} from 'react';
import Button from "react-bootstrap/Button";
import "./xcube-text-button.scss";

class XcubeTextButton extends Component {
    render() {
        let disabled = false;
        return (
            <div id="xcube-text-button">
                <Button variant="link" className={this.props.btnState} disabled={disabled} onClick={this.props.onClick}>{this.props.text}</Button>
            </div>
        );
    }
}

export default XcubeTextButton;