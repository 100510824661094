import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import copyImg from "../campaign-game/assets/copy.svg";
import removeImg from "../campaign-game/assets/remove.svg";
import "./campaign-prizes.scss";
import prizeImg from "../campaign-prizes/assets/prize.svg";
import importImg from "../campaign-prizes/assets/import.svg";
import exportImg from "../campaign-prizes/assets/export.svg";
import NewPrizeModal from "./components/new-prize-modal";
import {getDataService} from "../../../../../../shared/services/getDataService";
import {dataHelper} from "../../../../../../shared/helpers/data-helper";
import { setDataService } from '../../../../../../shared/services/setDataService';
import EditPrizeModal from './components/edit-prize-modal';
import { Constants } from '../../../../../../Constants';
class CampaignPrizes extends Component {

    campaignId;
    campaignData;
    inputElement;
    constructor(props){
        super(props);
        this.state = {
            campaignData: []
        }
    }

    componentWillMount(){
        this.campaignId = this.props.match.params.id;
        getDataService.campaignPrizesPage(this.campaignId).then((data) => {
            this.setState(prevState => ({
                campaignData: data.data.body
            }))
        });
    }

    removePrize(id){
        setDataService.removePrize(id).then((resp) => {
           // console.log(resp);
           window.location.reload();
        });
    }

    copyPrize(id){
        setDataService.copyPrize(id).then((resp) => {
           // console.log(resp);
            window.location.reload();
        });
    }

    importFile = (event, campaignId) => {
        // console.log(event.target.files[0]);
        setDataService.importPrizes(event.target.files[0], campaignId)
          .then((resp) => {
              // console.log(resp);
          })
    };

    renderPrizes = () =>{
      let prizes = [];
      // console.log(this.state.campaignData);
      this.state.campaignData.map((prize, i) => {
         prizes.push(
             <tr key={i}>
                 <td>
                     <div className="media">
                         <img className="mr-3" src={prize?.image?.thumbnail_url || prizeImg} alt="Prize"/>
                         <div className="media-body">
                             <div>{prize.name}</div>
                             <div><p>Added on {dataHelper.formatDateHelper(prize.createdAt, 'DD/MM/YYYY')} by {prize?.created_by_user?.first_name + ' ' + prize?.created_by_user?.last_name}</p></div>
                             <div className="btn-group options" role="group">
                                 <EditPrizeModal prize={prize}/>
                                 <a onClick={() => this.copyPrize(prize.id)}><img src={copyImg} />Copy</a>
                                 <a onClick={() => this.removePrize(prize.id)}><img src={removeImg} />Remove </a>
                             </div>
                         </div>
                     </div>
                 </td>
                 <td>{prize?.winners}</td>
                 <td>
                     <div>{prize?.status}</div>
                     <div><p>{prize?.status_description}</p></div>
                 </td>
             </tr>
         );
      });
        return prizes;
    };

    componentDidMount() {
        this.props.changeTitle('Campaign - Prizes');
    }
    render() {
        return (
            <div id="campaignPrizes" className="pt-md-5">
               <div className="container">
                   <Row>
                       <div className="col-md-6 section-head">
                           <h1>
                               Prize list
                           </h1>
                       </div>
                       <div className="col-md-6 question-btn">
                           <NewPrizeModal/>
                       </div>
                   </Row>
                   <Row className="prizeOverflowX">
                       <div className="col-md-12 mt-md-3">
                           <table className="table" id="leads-table">
                               <thead>
                               <tr>
                                   <th scope="col">Prize</th>
                                   <th scope="col">Winner</th>
                                   <th scope="col">Prize draw</th>
                               </tr>
                               </thead>
                               <tbody>
                                    {this.renderPrizes()}
                               </tbody>
                           </table>
                       </div>
                   </Row>
                   <Row>
                       <div className="col-md-12 import-export">
                           <img src={importImg} alt=""/>  <a href={`${Constants.endPoint}/dashboard/${this.campaignId}/prizes/export`}>Export prizes</a> <span>-</span> <img src={exportImg} alt=""/>  <a onClick={() => this.inputElement.click()}>Import prizes</a>
                       </div>
                       <input className={'import-button'} type="file" name="file" ref={input => this.inputElement = input} onChange={ (event) => this.importFile(event, this.campaignId)}/>
                   </Row>
               </div>
            </div>
        );
    }
}

export default CampaignPrizes;
