import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import startLogo from "../create-campaign-wizard-goal/assets/star.svg";
import leadImg from "../create-campaign-wizard-goal/assets/leads.svg";
import brandImg from "../create-campaign-wizard-goal/assets/awareness.svg";
import conversionImg from "../create-campaign-wizard-goal/assets/conversion.svg";
import XcubeFilledButton from "../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import Container from "react-bootstrap/Container";
import "./create-campaign-wizard-goal.scss";
import XcubeTextButton from "../../../../shared/components/xcube-buttons/xcube-text-button/xcube-text-button";
import popupImg from "../../../brame-gamesite-initial-setup/components/create-campaign-wizard-goal/assets/popup.svg";
import fullscreenImg
    from "../../../brame-gamesite-initial-setup/components/create-campaign-wizard-goal/assets/fullscreen.svg";
import slideinImg
    from "../../../brame-gamesite-initial-setup/components/create-campaign-wizard-goal/assets/slide-in.svg";
import { toast } from 'react-toastify';

class CreateCampaignWizardGoal extends Component {
    nextStep(){
        if(this.props.state.type.length > 0){
            return this.props.nextStep();
        }else{
            toast.error("You must must select one of the options!");
        }
    }
    render() {
        return (
            <div id="createCampaignWizardGoal" className="mt-md-5 pb-md-5">
                <Container>
                    <Row>
                        <div className="col-md-7 ml-md-auto mr-md-auto">
                            <Card className="mt-2">
                                <Card.Body className="mt-md-4">
                                    <img src={startLogo} alt=""/>
                                    <h3>
                                        What is your campaign type?
                                    </h3>
                                    <p>
                                        Select how you would like to integrate your game.
                                    </p>
                                    <small>
                                        Brame will adapt to offer you experiences
                                        most suitable to your needs.
                                    </small>
                                    <form action="" className="selector">
                                        <input type="radio" id="radio1" name="type" value="pop_up" onChange={()=> this.props.updateState({name: 'type', value: 'pop_up'})} />
                                        <label htmlFor="radio1">
                                            <img src={popupImg} alt=""/>
                                            Pop-up
                                        </label>
                                        <input type="radio" id="radio2" name="type" value="fullscreen" onChange={()=> this.props.updateState({name: 'type', value: 'fullscreen'})} />
                                        <label htmlFor="radio2">
                                            <img src={fullscreenImg} alt=""/>
                                            Fullscreen
                                        </label>
                                        <input type="radio" id="radio3" name="type" value="slide_in" onChange={()=> this.props.updateState({name: 'type', value: 'slide_in'})} />
                                        <label htmlFor="radio3">
                                            <img src={slideinImg} alt=""/>
                                            Slide-in
                                        </label>
                                    </form>
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        <XcubeTextButton btnState="normal" text="< Basics" onClick={this.props.previousStep}/>
                                        <XcubeFilledButton btnState="normal" text="Continue >" onClick={() => this.nextStep()}/>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default CreateCampaignWizardGoal;
