import {useState} from "react";
import Modal from "react-bootstrap/Modal";
import React from "react";
import "./see-all-campaigns.scss";
import { getDataService } from '../../../../../../../shared/services/getDataService';
import {googleAnalyticsService} from '../../../../../../../shared/services/googleAnalyticsService';
import {dataHelper} from '../../../../../../../shared/helpers/data-helper';
import XcubeRenderCampaigns
  from '../../../../../../../shared/components/xcube-render-campaigns/xcube-render-campaigns';


function SeeAllCampaigns(id) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => showData();
  let showData = () => {
    getDataService.getActiveClientCampaigns(id.id).then((resp) => {
      // let stateData = [];
      // console.log('sega');
      resp.data.body.forEach((respData) => {
        googleAnalyticsService.isGAReady().then((res) => {
          if(res.status){
            googleAnalyticsService.readAnalyticVisits(respData.analytics.viewId, '2015-01-01', 'today').then((res) => {
              if (res.visits.result.error) {
                respData.analytics.visits = 0;
                respData.analytics.conversions = dataHelper.conversionCalculator(0);
              } else {
                respData.analytics.visits = res.visits.result.reports[0].data.totals[0].values[0];
                respData.analytics.conversions = dataHelper.conversionCalculator(respData.leads_count > respData.game_visitors ? respData.leads_count : respData.game_visitors, respData.leads_count);
              }
              setData(data => [...data, respData]);
            });
          }else{
            document.getElementById('GAAccountId').click(); // Ask user to add Account ID.
          }
        });
      });
      setShow(true);
    });
  };

  return (
    <div>
      <span onClick={handleShow}>see all campaigns</span>

      <Modal show={show} onHide={handleClose}
             aria-labelledby="contained-modal-title-vcenter"
             id="seeAllCampaigns"
             centered
             size="lg"
             >
        <Modal.Header closeButton>
          Client Campaigns
        </Modal.Header>
        <Modal.Body>
        <table className="table" id="leads-table">
          <thead>
            <tr>
              <th scope="col">Campaign</th>
              <th scope="col">Game</th>
              <th scope="col">Visitors</th>
              <th scope="col">Conversions</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <XcubeRenderCampaigns data={data} />
          </tbody>
        </table>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SeeAllCampaigns;
