import React, {Component} from 'react';
import DatePicker from "react-date-picker";
import TimePicker from "react-time-picker";
import "./xcube-datetime-picker.scss";
import moment from 'moment';

class XcubeDatetimePicker extends Component {
    state = {
        date: new Date(),
        time: '00:00',
    };
    onDateChange = date => {
        let formatedDate = moment(date).format('MM-DD-YYYY');
        this.setState(prevState => ({
            ...prevState,
            date: date
        }));
        this.props.onChange({
            name: this.props.name,
            value: formatedDate + ' ' + this.state.time
        });
    };
    onTimeChange = time => {
        let formatedDate = moment(this.state.date).format('MM-DD-YYYY');
        this.setState(prevState => ({
            ...prevState,
            time: time
        }));
        this.props.onChange({
            name: this.props.name,
            value: formatedDate + ' ' + time
        })
    };

    render() {
        return (
            <div id="xCubeDatetimePicker">
                <div className="xcubeDateTimePicker">
                    <label>
                        {this.props.label}
                    </label>
                    <span className="dateLabel">
                        {this.props.dateLabel}
                    </span>
                    <DatePicker
                      format={'yyyy/M/d'}
                      clearIcon={null}
                      calendarIcon={null}
                      className="datePicker"
                      onChange={this.onDateChange}
                      value={this.props.date ? new Date(this.props.date) : this.state.date}
                      minDate={this.props.minDate ? new Date(this.props.minDate) : null}
                    />
                    <span className="timeLabel">
                        {this.props.timeLabel}
                    </span>
                    <TimePicker
                      clearIcon={null}
                      clockIcon={null}
                      format="H:m"
                      className="timePicker"
                      onChange={this.onTimeChange}
                      value={this.props.time ? moment.utc(this.props.time).format("H:mm") : this.state.time}
                      

                    />
                </div>
            </div>
        );
    }
}

export default XcubeDatetimePicker;
