import React, {Component} from 'react';
import "./xcube-checkbox.scss";
import { Checkbox } from 'react-inputs-validation';

class XcubeCheckbox extends Component {
    render() {
        return (
            <div id="xcube-checkbox">
                <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={this.props.id}
                      checked={this.props.checked}
                      onChange={ event => this.props.onChange({'value': event.target.checked, 'name': this.props.name})}
                    />
                    {/*<Checkbox*/}
                    {/*  attributesInput={{ // Optional.*/}
                    {/*      id: 'agreement',*/}
                    {/*      name: 'agreement',*/}
                    {/*      className: 'custom-control-input'*/}
                    {/*  }}*/}
                    {/*  value={''} // Required.[String].Default: "".*/}
                    {/*  onBlur={() => {}} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.*/}
                    {/*  checked={this.props.checked} // Required.[Bool].Default: false.*/}
                    {/*  onChange={(isAgreementChecked, e) => {*/}
                    {/*      this.props.onChange({*/}
                    {/*          name: this.props.name,*/}
                    {/*          value: isAgreementChecked*/}
                    {/*      });*/}
                    {/*  }}*/}
                    {/*  validationOption={{*/}
                    {/*      name: 'agreement', // Optional.[String].Default: "". To display in the Error message. i.e Please check the .*/}
                    {/*      check: true, // Optional.[Bool].Default: true. To determin if you need to validate.*/}
                    {/*      required: true, // Optional.[Bool].Default: true. To determin if it is a required field.*/}
                    {/*  }}*/}
                    {/*/>*/}
                    <label className="custom-control-label" htmlFor={this.props.id}></label>
                    <label className="text-label">{this.props.title}</label>
                    <label className="checkbox-label">{this.props.label}</label>
                </div>
            </div>
        );
    }
}

export default XcubeCheckbox;
