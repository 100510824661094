import React, {Component} from 'react';
import "./create-campaign-wizard-express-brand.scss";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import startLogo from "../create-campaign-wizard-express-brand/assets/logo.svg";
import XcubeFilledButton from "../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import Container from "react-bootstrap/Container";
import XcubeTextButton from "../../../../shared/components/xcube-buttons/xcube-text-button/xcube-text-button";
import XcubeFileUpload from "../../../../shared/components/xcube-file-upload/xcube-file-upload";
import XcubeColorPicker from "../../../../shared/components/xcube-color-picker/xcube-color-picker";
import { toast } from 'react-toastify';

class CreateCampaignWizardExpressBrand extends Component {
    updateState;
    constructor(props) {
        super(props);

        this.updateState = function (data, name){
            props.updateState(
                {
                    name: name,
                    value: data
                }
            );
        }
    }

    nextStep(){
        if(this.props.state.logo !== ''){
            return this.props.nextStep();
        }else{
            toast.error("Logo image is required!");
        }
    }

    render() {
        return (
            <div id="createCampaignWizardExpressBrand" className="mt-md-5 pb-md-5">
                <Container>
                    <Row>
                        <div className="col-md-7 ml-md-auto mr-md-auto">
                            <Card className="mt-2">
                                <Card.Body className="mt-md-4">
                                    <img src={startLogo} alt=""/>
                                    <div className="head">
                                        <h3>
                                            Express your brand
                                        </h3>
                                        <p>
                                            Customize the template with your brand logo and colors.
                                        </p>
                                        <small className="mb-md-3">
                                            We will automatically use these to adapt the template and match your corporate image.
                                        </small>
                                    </div>

                                    <form action="">
                                        <div className="primaryColorPicker">
                                            <p>Primary:</p> <XcubeColorPicker onChange={this.updateState} name="primary_color"/>
                                        </div>
                                        <div className="secondaryColorPicker">
                                            <p>Secondary:</p> <XcubeColorPicker onChange={this.updateState} name="secondary_color"/>
                                        </div>
                                    </form>
                                    <div className="row">
                                        <XcubeFileUpload
                                          onChange={this.props.updateState}
                                          name="logo"
                                          text="You should use a full color .PNG image that is not larger then 3 MB."
                                          head="Drag your logo here to upload it."
                                        />
                                    </div>
                                    <div className="btn-group float-md-right" role="group" aria-label="Basic example">
                                        <XcubeTextButton
                                          btnState="normal"
                                          text="< Game"
                                          onClick={this.props.previousStep}
                                        />
                                        <XcubeFilledButton
                                          btnState="normal"
                                          text="Continue >"
                                          onClick={() => this.nextStep()}
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>

                    </Row>
                </Container>
            </div>
        );
    }
}

export default CreateCampaignWizardExpressBrand;
