import React, {Component} from 'react';
import "./campaign-status.scss";
import Row from "react-bootstrap/Row";
import XcubeFilledButton
    from "../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import {getDataService} from "../../../../../../shared/services/getDataService";
import {dataHelper} from "../../../../../../shared/helpers/data-helper";
import XcubeShareModal
    from '../../../../../../shared/components/xcube-share-modal/xcube-share-modal';
import {setDataService} from '../../../../../../shared/services/setDataService';
import {Constants} from '../../../../../../Constants';
import {ClipLoader} from 'react-spinners';
import XcubeAnalyticsConversions
    from '../../../../../../shared/components/xcube-analytics-conversions/xcube-analytics-conversions';
import XcubeAnalyticsVisitorsOld
    from "../../../../../../shared/components/xcube-analytics-visitors-old/xcube-analytics-visitors";
import XcubeAnalyticsDevices
    from '../../../../../../shared/components/xcube-analytics-devices/xcube-analytics-devices';
import XcubeAnalyticsVisitorsPerCountry
    from '../../../../../../shared/components/xcube-analytics-visitors-per-country/xcube-analytics-visitors-per-country';
import XcubeAnalyticsEngagement
    from '../../../../../../shared/components/xcube-analytics-engagement/xcube-analytics-engagement';
import XcubeDatePicker
    from '../../../../../../shared/components/xcube-date-picker/xcube-date-picker';
import Col from 'react-bootstrap/Col';
import * as moment from 'moment';
import DataTable from "react-data-table-component";
import XcubeAnalyticsGameInfo
    from '../../../../../../shared/components/xcube-analytics-game-info/xcube-analytics-game-info';
import XcubeAnalyticsBestTime
    from '../../../../../../shared/components/xcube-analytics-best-time/xcube-analytics-best-time';
import XcubeAnalyticsDevicesDetails
    from "../../../../../../shared/components/xcube-analytics-devices-details/xcube-analytics-devices-details";
import XcubeAnalyticsConversionDetails
    from "../../../../../../shared/components/xcube-analytics-conversion-details/xcube-analytics-conversion-details";
import XcubeAnalyticsVisitorsDetails
    from "../../../../../../shared/components/xcube-analytics-visitors-details/xcube-analytics-visitors-details";
import XcubeAnalyticsDetailsVisitorsPerCountry
    from "../../../../../../shared/components/xcube-analytics-details-visitors-per-country/xcube-analytics-details-visitors-per-country";
import XcubeAnalyticsDetailsEngagement
    from "../../../../../../shared/components/xcube-analytics-details-engagement/xcube-analytics-details-engagement";
import XcubeAnalyticsLeads
    from '../../../../../../shared/components/xcube-analytics-leads/xcube-analytics-leads';
import XcubeAnalyticsDetailsLeads
    from "../../../../../../shared/components/xcube-analytics-details-leads/xcube-analytics-details-leads";
import XcubeAnalyticsBestTimeDetails
    from "../../../../../../shared/components/xcube-analytics-best-time-details/xcube-analytics-best-time-details";
import orderBy from 'lodash/orderBy';
import XcubeAnalyticsQuestionDetails
    from '../../../../../../shared/components/xcube-analytics-question-details/xcube-analytics-question-details';
import XcubeSelect from "../../../../../../shared/components/xcube-select/xcube-select";
import {iconSuccessPlaceholder} from "react-bootstrap-sweetalert/dist/styles/SweetAlertStyles";
import XcubeAnalyticsCTA
    from '../../../../../../shared/components/xcube-analytics-cta/xcube-analytics-cta';
import XcubeAnalyticsCtaDetails
    from '../../../../../../shared/components/xcube-analytics-cta-details/xcube-analytics-cta-details';

class CampaignStatus extends Component {
    campaignId;
    campaignData;
    editUrl;
    order = 1;

    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.state = {
            campaignData: {},
            campaignGameAnalytics: {},
            campaignLeads: {},
            gameAnalytics: [],
            CTAData: [],
            engagementAnalytics: [],
            conversionAnalytics: {},
            analyticsPeriod: 'days',
            leadsPageNumber: 1,
            leadsSortBy: 'createdAt:desc',
            leadsRowsPerPage: 10,
            analyticsConf: {
                startDate: moment(moment().subtract(30, 'days')).format("YYYY-MM-DD"),
                endDate: moment().format('YYYY-MM-DD')
            },
            columns: [],
            leadsData: [],
            campaignAnalytics: '',
            componentClicked: 'Devices',
            selectedDateClicked: 'Custom period',
            componentsAnalytics: [
                {id: 'Engagement', name: 'Engagement'},
                {id: 'Devices', name: 'Devices'},
                // {id: 'Conversions', name: 'Conversions'},
                {id: 'Visitors per country', name: 'Visitors per country'},
                {id: 'Visitors', name: 'Visitors'},
                {id: 'Leads', name: 'Leads'}
            ],
            selectedDateOptions: [
                {id: 'All time', name: 'All time'},
                {id: 'Today', name: 'Today'},
                {id: 'Last 7 days', name: 'Last 7 days'},
                {id: 'Last month', name: 'Last month'},
                {id: 'Custom period', name: 'Custom period'}
            ]
        }
    }

    scroll(ref) {
        ref.current.scrollIntoView({behavior: 'smooth'})
    }

    componentDidMount() {
        this.campaignId = this.props.match.params.id;
        this.editUrl = `https://editor.brame.ch/?id=${this.campaignId}`;
        this.props.changeTitle('Campaign - Status');
        this.getCampaignData();
    }

    updateDateTime = (props) => {
        this.handleChange(props);
        switch (props.value) {
            case 'Today':
                this.setState({
                    analyticsPeriod: 'hours'
                }, () => {
                    this.updateStateWithDate([
                        {name: 'startDate', value: moment().format()},
                        {name: 'endDate', value: moment().format()}
                    ]);
                });
                break;
            case 'Last 7 days':
                this.setState({
                    analyticsPeriod: 'days'
                }, () => {
                    this.updateStateWithDate([
                        {name: 'startDate', value: moment().subtract(7, 'd')},
                        {name: 'endDate', value: moment().format()}
                    ]);
                });
                break;
            case 'Last month':
                this.setState({
                    analyticsPeriod: 'days'
                }, () => {
                    this.updateStateWithDate([
                        {name: 'startDate', value: moment().subtract(1, 'month')},
                        {name: 'endDate', value: moment().format()}
                    ]);
                });
                break;
            case 'All time':
                this.setState({
                    analyticsPeriod: dataHelper.periodToHMS(moment(this.state.campaignData.startDate).format(), moment().format())
                }, () => {
                    this.updateStateWithDate([
                        {name: 'startDate', value: moment(this.state.campaignData.startDate).format()},
                        {name: 'endDate', value: moment().format()}
                    ]);
                });
                break;
            default:
                this.setState({
                    analyticsPeriod: dataHelper.periodToHMS(moment(this.state.campaignData.startDate).format(), moment().format())
                }, () => {
                    this.updateStateWithDate([
                        {name: 'startDate', value: moment(this.state.campaignData.startDate).format()},
                        {name: 'endDate', value: moment().format()}
                    ]);
                });
        }
    };

    getCampaignData = () => {
        getDataService.campaignStatusPage(this.campaignId).then((data) => {
            this.setState({
                campaignData: data.data
            }, () => {
                this.getAllAnalytics();
                this.getAllLeads(1);
                if (this.state.componentClicked === 'Engagement') {
                    this.getEngagementAnalytics();
                } else if (this.state.componentClicked === 'Conversions' || this.state.componentClicked === 'Leads' || this.state.componentClicked === 'Visitors') {
                    this.getConversionAnalytics();
                }
                this.setState({
                    componentsAnalytics: [
                        {id: 'Engagement', name: 'Engagement'},
                        {id: 'Devices', name: 'Devices'},
                        // {id: 'Conversions', name: 'Conversions'},
                        {id: 'Visitors per country', name: 'Visitors per country'},
                        {id: 'Visitors', name: 'Visitors'},
                        {id: 'Leads', name: 'Leads'},
                        {id: this.state.campaignData.game?.slug, name: this.state.campaignData.game?.title}
                    ]
                });
            });
        });
    };

    getAllAnalytics = () => {
        getDataService.getGameAnalytics(this.campaignId, this.state.analyticsConf.startDate + "T00:00", this.state.analyticsConf.endDate + "T23:59", this.state.campaignData.game?.slug).then((resp) => {
            this.setState({
                campaignGameAnalytics: resp.data
            }, () =>  getDataService.getAnalytics(this.campaignId, this.state.analyticsConf.startDate + "T00:00", this.state.analyticsConf.endDate + "T23:59").then((resp) => {
                this.setState({
                    campaignAnalytics: resp.data
                }, () => {
                    const switchResult = (gameSlug) => {
                        if(gameSlug === 'quiz'){
                            let data = {
                                answers: [],
                                results: []
                            };
                            this.state.campaignGameAnalytics.answers.map((resp) => {
                                const q = data.answers.find(q => q.questionSlug === resp.questionSlug);
                                if (q) {
                                    q.answers.push({
                                        answer: resp.answerText,
                                        percentage: resp.percentage.toFixed(2),
                                        occurrences: resp.count,
                                        questionSlug: resp.questionSlug
                                    })
                                } else {
                                    data.answers.push({
                                        question: resp.questionText,
                                        answers: [
                                            {
                                                answer: resp.answerText,
                                                percentage: resp.percentage.toFixed(2),
                                                occurrences: resp.count,
                                                questionSlug: resp.questionSlug
                                            }
                                        ],
                                        questionSlug: resp.questionSlug
                                    })
                                }
                            });
                            this.state.campaignGameAnalytics.results.map((resp) => {
                                if (resp.resultText) {
                                    data.results.push({
                                        title: resp.resultText,
                                        count: resp.count
                                    });
                                }
                            });
                            return data;
                        }
                    }
                    this.setState({
                        gameAnalytics: switchResult(this.state?.campaignData?.game?.slug)
                    })
                });
                if (this.state.campaignData.callToAction) {
                    this.setState({
                        CTAData: Object.keys(this.state.campaignData.callToAction).map(cta => {
                            const event = this.state.campaignAnalytics.events.find(e => e.target === cta);
                            if (event) return {
                                ...event,
                                name: this.state.campaignData.callToAction[event.target],
                                key: event.target
                            };
                        }).filter(cta => cta !== undefined)
                    }, () => {
                        this.setState(prevState => ({
                            componentsAnalytics: [
                                ...prevState.componentsAnalytics, {id: 'CTA', name: 'CTA'}
                            ]
                        }))
                    });
                }
            }) );


        });

    }

    getAllLeads = (pageNumber) => {
        this.setState({
            leadsPageNumber: pageNumber
        });
        getDataService.getAllLeads(this.campaignId, pageNumber, this.state.leadsSortBy, this.state.leadsRowsPerPage).then((resp) => {
            this.setState({
                campaignLeads: resp.data
            }, () => {
                this.generaliseColumns();
                this.generaliseData();
            })
        })
    }

    handleChange = (event) => {
        const value = event.value;
        const name = event.name;
        this.setState({
            [name]: value
        }, () => this.scroll(this.myRef));
    };

    updateState = (data) => {
        this.setState(prevState => ({
            analyticsConf: {
                ...prevState.analyticsConf,
                [data.name]: moment(data.value).format('YYYY-MM-DD')
            }
        }), () => {
            this.setState({
                analyticsPeriod: dataHelper.periodToHMS(this.state.analyticsConf.startDate, this.state.analyticsConf.endDate)
            }, () => {
                this.getCampaignData();
            })
        });
    };

    updateStateWithDate = (data) => {
        let _this = this;
        let promise = new Promise(function (resolve, reject) {
            let items = 0;
            data.forEach(date => {
                _this.setState(prevState => ({
                    analyticsConf: {
                        ...prevState.analyticsConf,
                        [date.name]: moment(date.value).format('YYYY-MM-DD')
                    }
                }));
                items++;
            });
            if (data.length === items) {
                resolve(true);
            } else {
                reject(Error("Error"));
            }
        });

        promise.then((res) => {
            if (res) {
                this.getCampaignData();
            }
        });
    };

    pauseCampaign = () => {
        setDataService.updateCampaignData({status: 'paused', id: this.campaignId}).then((res: any) => {
            window.location.reload();
        });
    };

    generaliseData = () => {
        if (this.state.campaignLeads.results.length > 0) {

            let leads = [];
            this.state.campaignLeads.results.map((resp) => {
                leads.push({
                    "date": dataHelper.formatDateHelper(resp.updatedAt, 'DD/MM/YYYY'),
                    "name": resp.firstName + " " + resp.lastName,
                    "email": resp.email,
                    "prize-awarded": resp?.prize?.title ?  resp.prize.title : 'No prize awarded',
                    "newsletter": resp.newsletter ? 'Yes' : 'No'
                });
            });

            this.setState({
                leadsData: leads
            });
        }
    };

    generaliseColumns = () => {

        let columns = [
            {
                name: 'Date',
                selector: 'date',
                sortable: true
            },
            {
                name: 'Name',
                selector: 'name',
                sortable: true
            },
            {
                name: 'Email',
                selector: 'email',
                sortable: true
            },
            {
                name: 'Prize Awarded',
                selector: 'prize-awarded',
                sortable: true
            },
            {
                name: 'Newsletter',
                selector: 'newsletter',
                sortable: false
            },
        ]
        this.setState({
            columns: columns
        });
    };

    customSort = (rows, direction) => {
        if (this.campaignId) {
            let sortBy;
            switch (rows.selector) {
                case 'date':
                    sortBy = `createdAt:${direction}`
                    break;
                case 'name':
                    sortBy = `firstName:${direction}`
                    break;
                case 'email':
                    sortBy = `email:${direction}`
                    break;
                case 'prize-awarded':
                    sortBy = `prize.title:${direction}`
            }
            this.setState({
                leadsSortBy: sortBy
            });
            getDataService.getAllLeads(this.campaignId, 1, sortBy, this.state.leadsRowsPerPage).then((resp) => {
                this.setState({
                    campaignLeads: resp.data
                }, () => {
                    this.generaliseColumns();
                    this.generaliseData();
                })
            })
        }
    };

    customLimit = (limit) => {
        console.log(limit)
        this.setState({
            leadsRowsPerPage: limit
        }, () => {
            getDataService.getAllLeads(this.campaignId, this.state.leadsPageNumber, this.state.leadsSortBy, this.state.leadsRowsPerPage).then((resp) => {
                this.setState({
                    campaignLeads: resp.data
                }, () => {
                    this.generaliseColumns();
                    this.generaliseData();
                })
            })
        });
    }

    getEngagementAnalytics = () => {
        getDataService.getAnalyticsEngagement(this.campaignId, this.state.analyticsPeriod, this.state.analyticsConf.startDate + "T00:00", this.state.analyticsConf.endDate + "T23:59").then((resp) => {
            console.log(resp);
            this.setState({
                engagementAnalytics: resp.data
            })
        })
    }

    getConversionAnalytics = () => {
        getDataService.getAnalyticsConversion(this.campaignId, this.state.analyticsPeriod, this.state.analyticsConf.startDate + "T00:00", this.state.analyticsConf.endDate + "T23:59").then((resp) => {
            console.log(resp);
            this.setState({
                conversionAnalytics: resp.data
            })
        })
    }

    renderGameInfo() {
        switch (this.state?.campaignData?.game?.slug) {
            case "Guess the picture":
                return (
                    <XcubeAnalyticsGameInfo
                        name={this.state.campaignData.game.title}
                        data={this.state.campaignData.game_analytics[0]?.correct_analytics}
                    />
                );
            case "quiz":
                return (
                    <XcubeAnalyticsGameInfo
                        name={this.state.campaignData.game.title}
                        data={this.state.gameAnalytics.results}
                    />
                );
            case "spin-the-wheel":
                return (
                    <XcubeAnalyticsGameInfo
                        name={this.state.campaignData.game.title}
                        data={this.state.campaignGameAnalytics}
                    />
                );
            case "Memory":
                return (
                    <XcubeAnalyticsBestTime
                        campaignData={this.state.campaignData}
                    />
                );
            case "Sliding Puzzle":
                return (
                    <XcubeAnalyticsBestTime
                        campaignData={this.state.campaignData}
                    />
                );
            default:
                return (
                    // <Col>No info</Col>
                    ''
                )
        }
    }

    showDateFormat = () => {
        return `(${dataHelper.periodToHMS(this.state.analyticsConf.startDate, this.state.analyticsConf.endDate)})`;
        // if(moment.duration(moment(this.state.analyticsConf.endDate).diff(moment(this.state.analyticsConf.startDate))).asDays() === 0) {
        //     return '(Hours)'
        // }
        // else if (moment.duration(moment(this.state.analyticsConf.endDate).diff(moment(this.state.analyticsConf.startDate))).asDays() <= 31) {
        //     return '(Days)'
        // } else {
        //     return '(Months)'
        // }
    };

    renderComponentDetails = () => {
        switch (this.state.componentClicked) {
            case 'Engagement':
                return (
                    <Col>
                        {this.state.engagementAnalytics.length > 0
                            ?
                            <XcubeAnalyticsDetailsEngagement
                                data={this.state.engagementAnalytics}
                                showDateFormat={this.showDateFormat()}
                                start_date={this.state.analyticsConf.startDate}
                                end_date={this.state.analyticsConf.endDate}
                            />
                            :
                            <div style={{textAlign: "center"}}>
                                <ClipLoader
                                    size={100}
                                    color={"#57ad63"}
                                    loading={true}
                                />
                            </div>
                        }
                    </Col>
                );
            case 'Devices':
                return (
                    <Col>
                        {this.state.campaignAnalytics
                            ?
                            <XcubeAnalyticsDevicesDetails
                                data={this.state.campaignAnalytics.devices}
                            />
                            :
                            <div style={{textAlign: "center"}}>
                                <ClipLoader
                                    size={100}
                                    color={"#57ad63"}
                                    loading={true}
                                />
                            </div>
                        }
                    </Col>
                );
            case 'Conversions':
                return (
                    <Col>
                        {Object.keys(this.state.conversionAnalytics).length
                            ?
                            <XcubeAnalyticsConversionDetails
                                campaignData={this.state.campaignData}
                                data={this.state.conversionAnalytics}
                                start_date={this.state.analyticsConf.startDate}
                                end_date={this.state.analyticsConf.endDate}
                                showDateFormat={this.showDateFormat()}
                            />
                            :
                            <div style={{textAlign: "center"}}>
                                <ClipLoader
                                    size={100}
                                    color={"#57ad63"}
                                    loading={true}
                                />
                            </div>
                        }
                    </Col>
                );
            case 'Visitors':
                return (
                    <Col>
                        {Object.keys(this.state.conversionAnalytics).length
                            ?
                            <XcubeAnalyticsVisitorsDetails
                                data={this.state.conversionAnalytics.visitors}
                                visitsPerMonth={this.state.campaignAnalytics.visitsPerMonth}
                                start_date={this.state.analyticsConf.startDate}
                                end_date={this.state.analyticsConf.endDate}
                                showDateFormat={this.showDateFormat()}
                            />
                            :
                            <div style={{textAlign: "center"}}>
                                <ClipLoader
                                    size={100}
                                    color={"#57ad63"}
                                    loading={true}
                                />
                            </div>
                        }
                    </Col>
                );
            case 'Visitors per country':
                return (
                    <Col>
                        {this.state.campaignAnalytics
                            ?
                            <XcubeAnalyticsDetailsVisitorsPerCountry
                                data={this.state.campaignAnalytics.countries}
                            />
                            :
                            <div style={{textAlign: "center"}}>
                                <ClipLoader
                                    size={100}
                                    color={"#57ad63"}
                                    loading={true}
                                />
                            </div>
                        }
                    </Col>
                );
            case 'Leads':
                return (
                    <Col>
                        {Object.keys(this.state.conversionAnalytics).length
                            ?
                            <XcubeAnalyticsDetailsLeads
                                data={this.state.conversionAnalytics.leads}
                                start_date={this.state.analyticsConf.startDate}
                                end_date={this.state.analyticsConf.endDate}
                                showDateFormat={this.showDateFormat()}
                            />
                            :
                            <div style={{textAlign: "center"}}>
                                <ClipLoader
                                    size={100}
                                    color={"#57ad63"}
                                    loading={true}
                                />
                            </div>
                        }
                    </Col>
                );
            case 'Memory':
                return (
                    <Col>
                        {this.state.campaignGameAnalytics
                            ?
                            <XcubeAnalyticsBestTimeDetails
                                data={this.state.campaignGameAnalytics}
                            />
                            :
                            <div style={{textAlign: "center"}}>
                                <ClipLoader
                                    size={100}
                                    color={"#57ad63"}
                                    loading={true}
                                />
                            </div>
                        }
                    </Col>
                );
            case 'Guess the picture':
                return (
                    <Col>
                        {this.state.campaignAnalytics
                            ?
                            <XcubeAnalyticsQuestionDetails
                                questions={this.state.campaignData?.game_analytics[0].question_analytics}
                            />
                            :
                            <div style={{textAlign: "center"}}>
                                <ClipLoader
                                    size={100}
                                    color={"#57ad63"}
                                    loading={true}
                                />
                            </div>
                        }
                    </Col>
                );
            case 'quiz':
                return (
                    <Col>
                        {this.state.campaignAnalytics
                            ?
                            <XcubeAnalyticsQuestionDetails
                                questions={this.state.gameAnalytics.answers}
                            />
                            :
                            <div style={{textAlign: "center"}}>
                                <ClipLoader
                                    size={100}
                                    color={"#57ad63"}
                                    loading={true}
                                />
                            </div>
                        }
                    </Col>
                );
            case 'spin-the-wheel':
                return (
                    <Col>
                        {this.state.campaignGameAnalytics
                            ?
                            <XcubeAnalyticsBestTimeDetails
                                data={this.state.campaignGameAnalytics}
                            />
                            :
                            <div style={{textAlign: "center"}}>
                                <ClipLoader
                                    size={100}
                                    color={"#57ad63"}
                                    loading={true}
                                />
                            </div>
                        }
                    </Col>
                );
            case 'CTA':
                return (
                    <Col>
                        {this.state.campaignGameAnalytics
                            ?
                            <XcubeAnalyticsCtaDetails
                                data={this.state.CTAData}
                            />
                            :
                            <div style={{textAlign: "center"}}>
                                <ClipLoader
                                    size={100}
                                    color={"#57ad63"}
                                    loading={true}
                                />
                            </div>
                        }
                    </Col>
                );

            default:
                return <Col>No info</Col>
        }
    };

    render() {
        return (
            <div id="campaignStatus" className="pt-md-5">
                <div className="container">
                    <Row>
                        <div className="col-md-6 status">
                            <h1 className="mb-5">
                                Basics
                            </h1>
                            {this.state?.campaignData?.status === 'active'
                                ? <h2>Your campaign is <strong>live</strong> and doing fine!</h2>
                                : <h2>Your campaign is <strong>{this.state?.campaignData?.status}</strong>!</h2>
                            }
                            <p>Running a <strong>{this.state?.campaignData?.title}</strong> campaign.</p>
                            <p>{this.state?.campaignData?.game?.title} game</p>
                            <div className="btn-group" role="group">
                                <span>Run from <strong>{dataHelper.formatDateHelper(this.state?.campaignData?.startDate, 'DD/MM/YYYY HH:mm')}</strong> to <strong>{dataHelper.formatDateHelper(this.state?.campaignData?.endDate, 'DD/MM/YYYY HH:mm')}</strong></span>
                            </div>
                            {/*<p>Created by {this.state?.campaignData?.user?.firstName} {this.state?.campaignData?.user?.lastName}</p>*/}
                            {/*<div className="btn-group" role="group">*/}
                            {/*    <a href={this.state.campaignData?.campaign_url} className="preview">*/}
                            {/*        <i className="icn-gamepad"></i> View game*/}
                            {/*    </a>*/}
                            {/*    <div className="share">*/}
                            {/*        <XcubeShareModal*/}
                            {/*            linkText={'Share link'}*/}
                            {/*            qrCode={this.state.campaignData?.qr}*/}
                            {/*            linkShare={this.state.campaignData?.campaign_url}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="col-md-6 pause">
                            {/*{this.state.campaignData?.status === 'active' &&*/}
                            {/*<XcubeFilledButton*/}
                            {/*    btnState="negative"*/}
                            {/*    text="Pause"*/}
                            {/*    onClick={() => this.pauseCampaign()}*/}
                            {/*/>*/}
                            {/*}*/}
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <hr/>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12 at-a-glance">
                            <Row>
                                <Col md={6} lg={8}>
                                    <h2 className="mb-md-5">At a glance</h2>
                                </Col>
                                <Col md={6} lg={4}>
                                    <Row className="mb-2">
                                        <Col>
                                            <XcubeDatePicker
                                                label="Start date"
                                                onChange={this.updateState}
                                                value={this.state.analyticsConf.startDate}
                                                name="startDate"
                                                date={this.state.analyticsConf.startDate}
                                                maxDate={this.state.analyticsConf.endDate}
                                            />
                                        </Col>
                                        <Col>
                                            <XcubeDatePicker
                                                label="End date"
                                                onChange={this.updateState}
                                                value={this.state.analyticsConf.endDate}
                                                name="endDate"
                                                date={this.state.analyticsConf.endDate}
                                                minDate={this.state.analyticsConf.startDate}
                                                maxDate={new Date()}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <div id='engagement' className="col-sm-4 col-md-6 col-lg-4"
                                     onClick={() => this.setState({
                                         componentClicked: 'Engagement'
                                     }, () => {
                                         this.scroll(this.myRef)
                                         this.getEngagementAnalytics();
                                     })
                                     }
                                >
                                    {this.state.campaignAnalytics
                                        ?
                                        <XcubeAnalyticsEngagement
                                            data={this.state.campaignAnalytics.sessionDuration}
                                        />
                                        :
                                        <div style={{textAlign: "center"}}>
                                            <ClipLoader
                                                size={100}
                                                color={"#57ad63"}
                                                loading={true}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="col-sm-4 col-md-6 col-lg-4"
                                     onClick={() => this.setState({
                                         componentClicked: 'Devices'
                                     }, () => this.scroll(this.myRef))}
                                >
                                    {this.state.campaignAnalytics.devices
                                        ?
                                        <XcubeAnalyticsDevices
                                            data={this.state.campaignAnalytics.devices}
                                        />
                                        :
                                        <div style={{textAlign: "center"}}>
                                            <ClipLoader
                                                size={100}
                                                color={"#57ad63"}
                                                loading={true}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="col-sm-4 col-md-6 col-lg-4"
                                     // onClick={() => this.setState({
                                     //     componentClicked: 'Conversions'
                                     // }, () => {
                                     //     this.scroll(this.myRef)
                                     //     this.getConversionAnalytics();
                                     // })}
                                >
                                    {this.state.campaignAnalytics
                                        ?
                                        <XcubeAnalyticsConversions
                                            data={this.state.campaignAnalytics}
                                        />
                                        :
                                        <div style={{textAlign: "center"}}>
                                            <ClipLoader
                                                size={100}
                                                color={"#57ad63"}
                                                loading={true}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="col-sm-4 col-md-6 col-lg-4"
                                     onClick={() => this.setState({
                                         componentClicked: 'Visitors per country'
                                     }, () => this.scroll(this.myRef))}
                                >
                                    {this.state.campaignAnalytics.countries
                                        ?
                                        <XcubeAnalyticsVisitorsPerCountry
                                            data={this.state.campaignAnalytics.countries}
                                        />
                                        :
                                        <div style={{textAlign: "center"}}>
                                            <ClipLoader
                                                size={100}
                                                color={"#57ad63"}
                                                loading={true}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="col-sm-4 col-md-6 col-lg-4"
                                     onClick={() => this.setState({
                                         componentClicked: 'Visitors'
                                     }, () => {
                                         this.scroll(this.myRef)
                                         this.getConversionAnalytics();
                                     })}
                                >
                                    {this.state.campaignAnalytics
                                        ?
                                        <XcubeAnalyticsVisitorsOld
                                            data={this.state.campaignAnalytics.uniqueVisitors}

                                        />
                                        :
                                        <div style={{textAlign: "center"}}>
                                            <ClipLoader
                                                size={100}
                                                color={"#57ad63"}
                                                loading={true}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="col-sm-4 col-md-6 col-lg-4"
                                     onClick={() => this.setState({
                                         componentClicked: 'Leads'
                                     }, () => {
                                         this.scroll(this.myRef)
                                         this.getConversionAnalytics();
                                     })}
                                >
                                    {this.state.campaignAnalytics
                                        ?
                                        <XcubeAnalyticsLeads
                                            totalLeads={this.state.campaignAnalytics.leads}
                                        />
                                        :
                                        <div style={{textAlign: "center"}}>
                                            <ClipLoader
                                                size={100}
                                                color={"#57ad63"}
                                                loading={true}
                                            />
                                        </div>
                                    }
                                </div>
                                {this.state.CTAData.length > 0 &&
                                <div className="col-sm-4 col-md-6 col-lg-4"
                                     onClick={() => this.setState({componentClicked: 'CTA'}, () => this.scroll(this.myRef))}
                                >
                                    {this.state.CTAData.length > 0
                                        ?
                                        <XcubeAnalyticsCTA data={this.state.CTAData}/>
                                        :
                                        <div style={{textAlign: "center"}}>
                                            <ClipLoader
                                                size={100}
                                                color={"#57ad63"}
                                                loading={true}
                                            />
                                        </div>
                                    }
                                </div>
                                }
                                <div className="col-sm-4 col-md-6 col-lg-4"
                                     onClick={() => this.setState({componentClicked: this.state.campaignData.game?.slug}, () => this.scroll(this.myRef))}
                                >
                                    {this.state.campaignGameAnalytics
                                        ?
                                        this.renderGameInfo()
                                        :
                                        <div style={{textAlign: "center"}}>
                                            <ClipLoader
                                                size={100}
                                                color={"#57ad63"}
                                                loading={true}
                                            />
                                        </div>
                                    }
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <hr/>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12 statistics-row">
                            <Row>
                                <Col xs={6} md={4}>
                                    <XcubeSelect
                                        label="Metric"
                                        options={this.state.componentsAnalytics}
                                        name="componentClicked"
                                        id="template_id"
                                        onChange={this.handleChange}
                                        required={false}
                                        defaultValue={this.state.componentClicked}
                                    />
                                </Col>
                                <Col xs={6} md={4}>
                                    <XcubeSelect
                                        label="View Mode"
                                        options={this.state.selectedDateOptions}
                                        name="selectedDateClicked"
                                        id="template_id"
                                        onChange={this.updateDateTime}
                                        required={false}
                                        defaultValue={this.state.selectedDateClicked}
                                    />
                                </Col>
                                {this.state.selectedDateClicked === 'Custom period' &&
                                <Col xs={12} md={4}>
                                    <Row className="status_date_pick">
                                        <Col>
                                            <XcubeDatePicker
                                                label="Start date"
                                                onChange={this.updateState}
                                                value={this.state.analyticsConf.startDate}
                                                name="startDate"
                                                date={this.state.analyticsConf.startDate}
                                                maxDate={this.state.analyticsConf.endDate}
                                            />
                                        </Col>
                                        <Col>
                                            <XcubeDatePicker
                                                label="End date"
                                                onChange={this.updateState}
                                                value={this.state.analyticsConf.endDate}
                                                name="endDate"
                                                date={this.state.analyticsConf.endDate}
                                                minDate={this.state.analyticsConf.startDate}
                                                maxDate={new Date()}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                }
                            </Row>
                            <Row ref={this.myRef}>
                                {this.renderComponentDetails()}
                            </Row>
                        </div>
                    </Row>
                    {this.state.campaignLeads.totalResults > 0 &&
                    <>
                        <Row>
                            <div className="col-md-12">
                                <hr/>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-md-12 leads-row">
                                <Row className="leadsRowTable">
                                    <div className="col-6">
                                        <h4 className="mb-md-1">Leads</h4>
                                    </div>
                                    <div className="col-6 export-btn">
                                        <XcubeFilledButton
                                            btnState="normal"
                                            text="Export"
                                            onClick={() => window.location.assign(`${Constants.endPoint + Constants.LEADS.exportLeads(this.campaignId)}`)}
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        {this.state.leadsData.length > 0 &&
                                        <DataTable
                                            columns={this.state.columns}
                                            data={this.state.leadsData}
                                            defaultSortField="title"
                                            sortServer
                                            onSort={this.customSort}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={this.state.campaignLeads.totalResults}
                                            paginationPerPage={this.state.campaignLeads.limit}
                                            onChangeRowsPerPage={(limit) => this.customLimit(limit)}
                                            onChangePage={this.getAllLeads}
                                        />
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                    </>
                    }
                </div>
            </div>
        );
    }
}


export default CampaignStatus;
