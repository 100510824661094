import React, {Component} from 'react';
import email from "../../assets/email.svg";
import Row from "react-bootstrap/Row";
import "./emailIntegration.scss";

class EmailIntegration extends Component {
    render() {
        return (
            <div id="emailIntegration">
                <Row>
                    <div className="col-md-3">
                        <div className="card" onClick={() => {this.props.setStep('step3')}}>
                            <div className="row no-gutters">
                                <div className="col-md-6 card-img-col">
                                    <img src={email} className="card-img" alt=""/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <h5 className="card-title">Mailchimp</h5>
                                        <p className="card-text">Integrate mailchimp.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        );
    }
}

export default EmailIntegration;