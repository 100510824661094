import React, {Component} from 'react';
import XcubeFilledButton from "../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class OpenPathButton extends Component {

    constuctor() {
        this.routeChange = this.routeChange.bind(this);
    }
    
    routeChange = () => {
        let path = this.props.path;
        this.props.history.push(path);
    }

    render() {
        return (
            <div>
                <XcubeFilledButton text={this.props.text} btnState="normal" path={this.props.path} onClick={this.routeChange} />
            </div>
        );
    }
}

OpenPathButton.propTypes = {
    text: PropTypes.string,
    path: PropTypes.string
};
  
OpenPathButton.defaultProps = {
    text: 'Book a demo',
    path: '/book-a-demo'
};

export default withRouter (OpenPathButton);