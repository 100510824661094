import React, {Component} from 'react';
import Hero from "./components/hero/hero";
import Intro from "./components/intro/intro";
import Culture from "./components/culture/culture";
import Contact from "./components/contact/contact";
import "./about-page.scss";
import Action from "./components/action/action";

class AboutPage extends Component {
    render() {
        return (
            <div id="about-page">
                <Hero/>
                <Intro/>
                <Culture/>
                <Contact/>
                <Action/>
            </div>
        );
    }
}

export default AboutPage;