import React, {Component} from 'react';
import "./hero.scss";
class Hero extends Component {
    render() {
        return (
            <div id="hero-about-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 hero-text">
                            <h1>Inspiring you <br/> to be the <br/> <span>game changer.</span></h1>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Hero;