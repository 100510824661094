import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./get-on-top.scss";
import image from "./assets/image.png";
class GetOnTop extends Component {
    render() {
        return (
            <div id="get-on-top" className="pb-md-5">
                <Container>
                    <Row>
                        <Col md={4}>
                            <img src={image} className="img-fluid" alt={'Get on top'}/>
                        </Col>
                        <Col md={3}/>
                        <Col md={5}>
                            <h3>get on top <br/> of the game</h3>
                            <p>
                                88% of marketers say interactive content differentiates them from their competitors. Don’t let your brand be the one left out.
                            </p>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.startups.com/library/expert-advice/how-interactive-content-can-skyrocket-marketing">Source ►</a>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default GetOnTop;
