import React, {Component} from 'react';
import {Row, Col, Table} from 'react-bootstrap'
import './xcube-analytics-best-time-details.scss'

class XcubeAnalyticsBestTimeDetails extends Component {
    renderTableRows() {
        let rows = [];
        if (this.props.data?.length > 0) {
            this.props.data.slice(0, 10).reverse().map((player) => {
                {
                    player.hasOwnProperty("title") &&
                    rows.push(
                        <tr key={player.title + 'index'}>
                            <td><span className='bullet'
                                      style={{backgroundColor: `${this.getRandomColor()}`}}> </span> {player.title}</td>
                            {player.hasOwnProperty("count") &&
                            <td style={{textAlign: 'center'}}>{player.count}</td>
                            }
                            {player.hasOwnProperty("value") &&
                            <td style={{textAlign: 'center'}}>{player.value}%</td>
                            }
                            {player.hasOwnProperty("percentage") &&
                            <td style={{textAlign: 'center'}}>{player.percentage}%</td>
                            }
                        </tr>
                    )
                }

            })
        }
        return rows
    };


    getRandomColor() {
        let letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    render() {
        return (
            <Row className='bestTimeDetails align-items-center pt-3'>
                <Col md={12} lg={12} className="text-center">
                    {this.props.data?.length > 0
                        ?
                        <Table className='playersTime text-left' hover>
                            <thead style={{borderBottom: 'none'}}>
                            <tr>
                                {<th>Prizes</th>}
                                {<th>Occurrences</th>}
                                {/*{<th>Percentage</th>}*/}
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderTableRows()}
                            </tbody>
                        </Table>
                        : <p>No info available</p>
                    }
                </Col>
            </Row>
        )
    }
}

export default XcubeAnalyticsBestTimeDetails;
