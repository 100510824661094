import React, { Component } from 'react';
import { ClipLoader } from 'react-spinners';
import "./xcube-spinner.scss";
import store from '../../../redux/store';


class XcubeSpinner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    store.subscribe(() => {
      let localStore = store.getState();
      this.setState({
        loading: localStore.displaySpinner
      });
    });
  }

  render() {
    return (
      <div>
        {this.state.loading &&
          <div id="xcube-spinner">
            <div className="sweet-loading">
              <ClipLoader
                // css={override}
                size={150}
                color={"#57ad63"}
                loading={this.state.loading}
              />
            </div>
          </div>
        }
      </div>
    );
  }
}

export default XcubeSpinner;
