import React, {Component} from 'react';
import Hero from "./components/hero/hero";
import Intro from "./components/intro/intro";
import CheckOutSoftware from "./components/check-out-software/check-out-software";
import "./home.scss";
import Community from "./components/community/community";
import GameInspiration from "./components/game-inspiration/game-inspiration";
import Action from "./components/action/action";

class Home extends Component {
    render() {
        return (
            <div id="home-page">
                <Hero/>
                <Intro/>
                <CheckOutSoftware/>
                <Community/>
                <GameInspiration/>
                <Action/>
            </div>
        );
    }
}

export default Home;