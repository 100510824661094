import React, {Component} from 'react';
import "./campaign-game.scss";
import {getDataService} from "../../../../../../shared/services/getDataService";
import MemoryGame from "./components/memory-game/memory-game";
import SlidingPuzzleEditor from "./components/sliding-puzzle/sliding-puzzle-editor";

class CampaignGame extends Component {
    constructor(props){
        super (props);
        this.state = {
            gameData:{
                id:'1234',
                name:'Sliding Puzzle'
            }

        }
    }

    componentWillMount(){
        this.campaignId = this.props.match.params.id;
        getDataService.campaignGeneralPage(this.campaignId).then((data) => {
                this.setState({
                    campaignData: data.data.body
                });

                // console.log('game-campaign', this.state);

            }, err => {
            }
        );
    }

    componentDidMount() {

        this.props.changeTitle('Campaign - Game settings');
    }


    render() {

        return (
            <>
                { this.state.gameData.name === 'Memory '
                    ? <MemoryGame props={this.props}/>
                    : <SlidingPuzzleEditor props={this.props}/> }
            </>
        );
    }
}

export default CampaignGame;
