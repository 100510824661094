import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import XcubeInput from "../../../../../../../../../shared/components/xcube-input/xcube-input";
import XcubeFilledButton
    from "../../../../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";

class Step3 extends Component {
    render() {
        return (
            <div id="step3">
                <Row>
                    <div className="col-md-12">
                       <XcubeInput label="API key"/>
                       <XcubeInput label="Secret key"/>
                        <XcubeFilledButton text="Finish" btnState="normal"/>
                    </div>
                </Row>
            </div>
        );
    }
}

export default Step3;