import React, {Component} from 'react';
import "./dashboard-account-plan-billing.scss";
import Row from "react-bootstrap/Row";
import demo from "./assets/demo.svg";
import professional from "./assets/professional.svg";
import basic from "./assets/basic.svg";
import enterprise from "./assets/enterprise.svg";
import XcubeFilledButton
    from "../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import importImg from "./assets/billing-info.svg";
import exportImg from "./assets/export.svg";
import NewPaymentModal from "./components/new-payment-modal";
import { getDataService } from '../../../../../../shared/services/getDataService';
import { dataHelper } from '../../../../../../shared/helpers/data-helper';
import { Constants } from '../../../../../../Constants';
import CloseAccount from './components/close-account';


class DashboardAccountPlanBilling extends Component {
    user = JSON.parse(localStorage.getItem('currentUser'));
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.props.changeTitle('Account - Plan & Billing');

        getDataService.getAccountPlan().then((resp) => {
           if(resp.data.message === 'success'){
               this.setState(resp.data.body);
           }
        });
    }
    renderBilling = () => {
      let data = [];
      this.state.billing.map((bill, index) => {
         data.push(
           <tr key={index + bill.product}>
               <td>
                   {bill.product}
               </td>
               <td>
                   {dataHelper.formatDateHelper( bill.date, 'DD/MM/YYYY')}
               </td>
               <td>
                   €{bill.amount}
               </td>
               <td>
                   €{bill.amount}
               </td>
               <td>
                   <div className="status positive"></div>
                   Paid
               </td>
           </tr>
         )
      });

      return data;
    };
    render() {
        return (
            <div id="dashboardAccountPlanBilling" className="pt-md-5">
                <div className="container">
                    <Row>
                        <div className="col-md-12 section-head">
                            <h1>
                                Account plan
                            </h1>
                            <span>
                                You are currently subscribed to the {this.state.plan} Plan.
                            </span>
                            <p>
                                You have no outstanding payments to make.
                            </p>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">

                            <div className="card-deck mt-md-3">
                                <div className="card main-card">
                                    <div className="card m-1">
                                        <img className="card-img-top" src={demo} alt="Card image cap"/>
                                        <div className="card-body p-0 text-center">
                                            <p className="card-text main-card-text">Free</p>
                                        </div>
                                    </div>
                                    <div className="card-body text-center second-body">
                                        <p className="card-text"> <strong>1k</strong> clicks - <strong>2k</strong> leads</p>
                                        <p className="card-text">self-service - one month limit</p>
                                        <p className="card-text pt-md-3">
                                            <small className="text-muted">Access to select games
                                                Access to select templates
                                                Single user
                                                One active campaign
                                                Full access to Help Desk
                                                Campaign set-up wizard
                                            </small>
                                        </p>
                                        <p className="card-text pt-md-3">
                                            <small className="text-muted">
                                                You cannot downgrade
                                                your account plan.
                                            </small>
                                        </p>

                                    </div>
                                </div>
                                <div className="card main-card">
                                    <div className="card m-1">
                                        <img className="card-img-top" src={basic} alt="Card image cap"/>
                                        <div className="card-body p-0 text-center">
                                            <p className="card-text main-card-text">€99/month</p>
                                        </div>
                                    </div>
                                    <div className="card-body text-center second-body">
                                        <p className="card-text"> <strong>5k</strong> clicks - <strong>1k</strong> leads</p>
                                        <p className="card-text">self-service - monthly cycle</p>
                                        <p className="card-text pt-md-3">
                                            <small className="text-muted">
                                                <strong>Everything in Demo, plus:</strong>
                                                High Converter games
                                                Access to all templates
                                                Template editor
                                                E-mail support
                                            </small>
                                        </p>
                                        <p className="card-text pt-md-3">
                                            {this.state.plan === 'Basic'
                                                ?
                                                    <small className="text-muted">
                                                      You are currently
                                                      subscribed to this plan.
                                                    </small>
                                                :
                                                    this.state.plan === 'demo'
                                                ?
                                                    <XcubeFilledButton text="Upgrade" btnState="normal"/>
                                                :
                                                    <small className="text-muted">
                                                      You cannot downgrade
                                                      your account plan.
                                                    </small>
                                            }

                                        </p>

                                    </div>
                                </div>
                                <div className="card main-card">
                                    <div className="card m-1">
                                        <img className="card-img-top" src={professional} alt="Card image cap"/>
                                        <div className="card-body p-0 text-center">
                                            <p className="card-text main-card-text">€299/month</p>
                                        </div>
                                    </div>
                                    <div className="card-body text-center second-body">
                                        <p className="card-text"> <strong>20k</strong> clicks - <strong>4k</strong> leads</p>
                                        <p className="card-text">self-service - yearly cycle</p>
                                        <p className="card-text pt-md-3">
                                            <small className="text-muted">
                                                <strong>Everything in Basic, plus:</strong>
                                                Access to all games
                                                Teams up to 5 users
                                                Pop-up/page integration
                                            </small>
                                        </p>
                                        <div className="card-text pt-md-3">
                                            {this.state.plan === 'Professional'
                                              ?
                                              <small className="text-muted">
                                                  You are currently
                                                  subscribed to this plan.
                                              </small>
                                              :
                                              this.state.plan === 'Basic' || this.state.plan === 'Demo'
                                                ?
                                                <XcubeFilledButton text="Upgrade" btnState="normal"/>
                                                :
                                                <small className="text-muted">
                                                    You cannot downgrade
                                                    your account plan.
                                                </small>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="card main-card">
                                    <div className="card m-1">
                                        <img className="card-img-top" src={enterprise} alt="Card image cap"/>
                                        <div className="card-body p-0 text-center">
                                            <p className="card-text main-card-text">€299/month</p>
                                        </div>
                                    </div>
                                    <div className="card-body text-center second-body">
                                        <p className="card-text"> <strong>Unlimited</strong> clicks - <strong>4k</strong> leads</p>
                                        <p className="card-text">custom solutions</p>
                                        <p className="card-text pt-md-3">
                                            <small className="text-muted">
                                                <strong>Everything in Professional, plus:</strong>
                                                Up to 4 custom templates
                                                API Integrations
                                                Success manager (4 campaigns/year)
                                                Call support
                                            </small>
                                        </p>
                                        <div className="card-text pt-md-3">
                                            {this.state.plan === 'Enterprise'
                                              ?
                                              <small className="text-muted">
                                                  You are currently
                                                  subscribed to this plan.
                                              </small>
                                              :
                                              this.state.plan === 'Basic' || this.state.plan === 'Demo' || this.state.plan === 'Professional'
                                                ?
                                                <XcubeFilledButton
                                                  text="Book now"
                                                  btnState="normal"
                                                  onClick={() => window.location.href="/book-a-demo"}
                                                />
                                                :
                                                <small className="text-muted">
                                                    You cannot downgrade
                                                    your account plan.
                                                </small>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <hr/>
                        </div>
                    </Row>
                    <Row className="mb-2">
                        <div className="col-md-6 section-head">
                            <h1>
                                Billing
                            </h1>
                        </div>
                        <div className="col-md-6 newPaymentModal">
                            <NewPaymentModal/>
                        </div>
                    </Row>
                    <Row className="tableLead">
                        <div className="col-md-12 mt-md-3">
                            <table className="table" id="leads-table">
                                <thead>
                                <tr>
                                    <th scope="col">Product</th>
                                    <th scope="col">Date interval</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Paid</th>
                                    <th scope="col">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {this.state.billing?.length > 0 &&
                                        this.renderBilling()
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12 import-export">
                            <img src={exportImg} alt=""/>  <a>Change billing information</a> <span>-</span> <div className="expInvoices"><img src={importImg} alt=""/>  <a href={`${Constants.endPoint}/account/plan/${this.user.email}/export`}>Export invoices</a></div>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <hr/>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12 section-head">
                            <h1>
                                Close account
                            </h1>
                            <span>
                                This action will permanently remove your account & campaigns from Brame.
                            </span>
                            <p>
                                Closing your account will stop all of your running campaigns and remove any data that we have collected for and about you from our servers. Learn more.
                                Doing this action before the end of your billing cycle does not entitle you to a refund.
                            </p>
                        </div>
                    </Row>
                    <Row>
                        <div className="pt-md-3 p-3">
                            <CloseAccount />
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
}

export default DashboardAccountPlanBilling;
