import React, {Component} from 'react';
import "./engagement.scss";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";

class Engagement extends Component {
    state = {
        index: 0,
        direction: null,
        carouselItemCount: 3
    };
    handleSelect = (selectedIndex, e) => {

    };
    toggleCarousel = (direction) => {
        let index = this.state.index;
        const [min, max] = [0, this.state.carouselItemCount - 1];

        if (direction === 'next') {
            index++

        }
        else if (direction === 'prev') {
            index--
        }

        if (index > max) {
            // at max, start from top
            index = 0
        }

        if (index < min) {
            // at min, start from max
            index = max
        }
        this.setState({
            direction,
            index
        })
    };
    render() {
        return (
            <div id="engagement-why-brame-page">
                <div className="parent-div">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 hero-text">
                                <h4>Excite your community...</h4>
                                <h1>gamification <br/> provides <br/> <span>engagements.</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <Carousel controls={false} id="banner-carousel">
                        <Carousel.Item>
                            <div className="carousel-item-text">
                                <Row>
                                    <div className="col-md-6">
                                        <h1>98%</h1>
                                    </div>
                                    <div className="col-md-6">
                                        <p>of consumers are more inclined to make a purchase after an experience</p>
                                    </div>
                                </Row>
                            </div>
                            <a target="_blank" href="https://www.limelightplatform.com/blog/roi-experiential-marketing-trends-figures">Source ►</a>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="carousel-item-text">
                                <Row>
                                    <div className="col-md-6">
                                        <h1>98%</h1>
                                    </div>
                                    <div className="col-md-6">
                                        <p>of consumers are more inclined to make a purchase after an experience</p>
                                    </div>
                                </Row>
                            </div>
                            <a target="_blank" href="https://www.limelightplatform.com/blog/roi-experiential-marketing-trends-figures">Source ►</a>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="carousel-item-text">
                                <Row>
                                    <div className="col-md-6">
                                        <h1>98%</h1>
                                    </div>
                                    <div className="col-md-6">
                                        <p>of consumers are more inclined to make a purchase after an experience</p>
                                    </div>
                                </Row>
                            </div>
                            <a target="_blank" href="https://www.limelightplatform.com/blog/roi-experiential-marketing-trends-figures">Source ►</a>
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div className="container">
                    <Row>
                        <div className="col-md-5">
                            <h3>
                                how will <br/> gamification <br/> help?
                            </h3>
                        </div>
                        <div className="col-md-7">
                            <Carousel controls={false} id="help-carousel">
                                <Carousel.Item>
                                    <div className="carousel-item-text">
                                        <h1>1</h1>
                                        <h5>Place your product or service</h5>
                                        <p>
                                            Gamification gives your brand the chance to transmit useful information about your product or service in a playful way to your target audience. Moreover, interactive feedback sessions can be implemented that give your brand useful reviews instead of boring surveys that often come with low participant engagement.
                                        </p>
                                    </div>
                                </Carousel.Item>
                                 <Carousel.Item>
                                    <div className="carousel-item-text">
                                        <h1>2</h1>
                                        <h5>Place your product or service</h5>
                                        <p>
                                            Gamification gives your brand the chance to transmit useful information about your product or service in a playful way to your target audience. Moreover, interactive feedback sessions can be implemented that give your brand useful reviews instead of boring surveys that often come with low participant engagement.
                                        </p>
                                    </div>
                                </Carousel.Item>
                                 <Carousel.Item>
                                    <div className="carousel-item-text">
                                        <h1>3</h1>
                                        <h5>Place your product or service</h5>
                                        <p>
                                            Gamification gives your brand the chance to transmit useful information about your product or service in a playful way to your target audience. Moreover, interactive feedback sessions can be implemented that give your brand useful reviews instead of boring surveys that often come with low participant engagement.
                                        </p>
                                    </div>
                                </Carousel.Item>

                            </Carousel>
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Engagement;