import React, { Component, useState, useEffect, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import './dashboard-page.scss';
import XcubeFilledButton from '../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button';
import XcubeChartjsGeo from '../../../../shared/components/xcube-chartjs-geo/xcube-chartjs-geo';
import gameMemoryImg from '../dashboard-campaign/components/campaigns/assets/game-memory.svg';
import { getDataService } from '../../../../shared/services/getDataService';
import { dataHelper } from '../../../../shared/helpers/data-helper';
import { setDataService } from '../../../../shared/services/setDataService';
import { googleAnalyticsService } from '../../../../shared/services/googleAnalyticsService';
import InsertAccountIdModal from './components/insertAccountIdModal/insertAccountIdModal';
import { ClipLoader } from 'react-spinners';
import XcubeAnalyticsConversions
  from '../../../../shared/components/xcube-analytics-conversions/xcube-analytics-conversions';
import XcubeAnalyticsDevices
  from '../../../../shared/components/xcube-analytics-devices/xcube-analytics-devices';
import XcubeAnalyticsVisitors
  from "../../../../shared/components/xcube-analytics-visitors-old/xcube-analytics-visitors";
import XcubeAnalyticsLeads
  from '../../../../shared/components/xcube-analytics-leads/xcube-analytics-leads';
import XcubeAnalyticsEngagement
  from '../../../../shared/components/xcube-analytics-engagement/xcube-analytics-engagement';
import moment from 'moment';
import Col from 'react-bootstrap/Col';
import XcubeDatePicker from '../../../../shared/components/xcube-date-picker/xcube-date-picker';
import XcubeRenderCampaigns
  from '../../../../shared/components/xcube-render-campaigns/xcube-render-campaigns';
import axios from 'axios';
import { Constants } from '../../../../Constants';

function DashboardPage(props) {

  const [campaignsData, setCampaignsData] = useState('');
  const [dashboardAnalytics, setDashboardAnalytics] = useState("");
  const [analyticsConf, setAnalyticsConf] = useState({
    startDate: moment( moment().subtract(30, 'days') ).format("YYYY-MM-DD"),
    endDate: moment().format('YYYY-MM-DD')
  });

  const prevAnalyticsConf = useRef();

  function getAnalytics() {
    axios.get(`${Constants.endPoint}` + `/analytics` + '?from=' + analyticsConf.startDate + "T00:00" + "&to=" + analyticsConf.endDate + "T23:59")
      .then((resp) => {
        setDashboardAnalytics(resp.data)
      })
  }


  useEffect(() => {
    axios.get(Constants.endPoint + `/campaigns?status=active&page=1&limit=5&sortBy=createdAt:desc`)
      .then((resp) => {
        setCampaignsData(resp.data)
      });
  }, []);

  useEffect(() => {
    getAnalytics()
  }, [analyticsConf]);

  function updateState(data) {
    prevAnalyticsConf.current = analyticsConf;
    const prevAnalyticsConfRef = prevAnalyticsConf.current;
    setAnalyticsConf({...prevAnalyticsConfRef , [data.name]: moment(data.value).format('YYYY-MM-DD') });
  }

  const renderStatusInfo = () => {
    return (
      campaignsData.totalResults !== 0
        ? <h2>You have {campaignsData.totalResults} campaigns running great!</h2>
        : campaignsData.totalResults === 0 ? <h2>You have no campaigns running!</h2> : <h2>You have {campaignsData.totalResults} campaign running great!</h2>
    )
  }

  //   pauseAll = () => {
  // //     setDataService.pauseAllCampaigns().then((resp) => {
  // //       window.location.reload();
  // //     })
  // //   };
  // //

  return (
    <div>
      <div className="mt-md-2 mb-md-5 page-title">
        Dashboard
      </div>
      <div id="dashboard-page" className="pt-md-5">
        <div className="container">
          <Row className="pt-4">
            <div className="col-sm-6 col-md-6 status">
              <h1 >
                Status
              </h1>
              {campaignsData !== '' &&
                renderStatusInfo()
              }
              {/*{ campaignsStatus[0]?.lastDate != null &&*/}
              {/*<p>Last campaign ended on {dataHelper.formatDateHelper(campaignsStatus[0]?.lastDate, 'd/MM/YYYY')}.</p>*/}
              {/*}*/}
            </div>
            <div className="col-sm-6 col-md-6 pause">
              <Row>
                <div className="col-sm-12 col-md-12">
                  {/*<XcubeFilledButton*/}
                  {/*  btnState="normal"*/}
                  {/*  text="New campaign"*/}
                  {/*  onClick={() => window.open('/wizard', '_self')} />*/}
                  {/*{campaignsData.totalResults > 0 &&*/}
                  {/*<XcubeFilledButton*/}
                  {/*  btnState="negative"*/}
                  {/*  text="Pause"*/}
                  {/*  onClick={() => pauseAll()}*/}
                  {/*  />*/}
                  {/*}*/}
                </div>
              </Row>
            </div>
          </Row>

          <Row className="pb-3">
            <Col  xs={12} sm={12} md={8}></Col>
            <Col xs={12} sm={12} md={4} className="mt-4">
              <Row>
                <Col xs={6}>
                  <XcubeDatePicker
                    label="Start date"
                    onChange={updateState}
                    value={analyticsConf.startDate}
                    name="startDate"
                    date={analyticsConf.startDate}
                    maxDate={analyticsConf.endDate}
                  />
                </Col>
                <Col xs={6}>
                  <XcubeDatePicker
                    label="End date"
                    onChange={updateState}
                    value={analyticsConf.endDate}
                    name="endDate"
                    date={analyticsConf.endDate}
                    minDate={analyticsConf.startDate}
                    maxDate={new Date()}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-md-4">
            <div className="col-sm-4 col-md-6 col-lg-4">
              { dashboardAnalytics !== ''
                ?
                <XcubeAnalyticsVisitors
                  data={dashboardAnalytics.uniqueVisitors}
                />
                :
                <div style={{textAlign: "center"}}>
                  <ClipLoader
                    size={100}
                    color={"#57ad63"}
                    loading={true}
                  />
                </div>
              }
            </div>

            <div className="col-sm-4 col-md-6 col-lg-4">
              { dashboardAnalytics !== ''
                ?
                <XcubeAnalyticsDevices
                  data={dashboardAnalytics.devices}
                />
                :
                <div style={{textAlign: "center"}}>
                  <ClipLoader
                    size={100}
                    color={"#57ad63"}
                    loading={true}
                  />
                </div>
              }
            </div>

            <div className="col-sm-4 col-md-6 col-lg-4">
              { dashboardAnalytics
                ?
                <XcubeAnalyticsConversions
                  data={dashboardAnalytics}
                />
                :
                <div style={{textAlign: "center"}}>
                  <ClipLoader
                    size={100}
                    color={"#57ad63"}
                    loading={true}
                  />
                </div>
              }
            </div>

            <div className="col-sm-4 col-md-6 col-lg-4">
              { dashboardAnalytics !== ''
                ?
                <XcubeAnalyticsEngagement
                  data={dashboardAnalytics.sessionDuration}
                />
                :
                <div style={{textAlign: "center"}}>
                  <ClipLoader
                    size={100}
                    color={"#57ad63"}
                    loading={true}
                  />
                </div>
              }
            </div>

            <div className="col-sm-4 col-md-6 col-lg-4">
              { dashboardAnalytics !== ''
                ?
                <XcubeAnalyticsLeads
                  totalLeads={dashboardAnalytics.leads}
                />
                :
                <div style={{textAlign: "center"}}>
                  <ClipLoader
                    size={100}
                    color={"#57ad63"}
                    loading={true}
                  />
                </div>
              }
            </div>
          </Row>
          <Row>
            <div className="col-md-12 mt-md-4 mb-md-3">
              <hr />
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-md-6 section-head">
              <h1>
                Latest campaigns
              </h1>
            </div>
            <div className="col-md-6 pb-2 allCampaignsBtn">
              <XcubeFilledButton
                btnState="normal"
                text="All campaigns"
                onClick={() => window.location = 'campaigns'}
              />
            </div>
          </Row>
          <Row>
            <div className="col-md-12 mt-md-3 tableXoverflow">
              <table className="table" id="leads-table">
                <thead>
                <tr>
                  <th scope="col">Campaign</th>
                  <th scope="col">Game</th>
                  <th scope="col">Visitors</th>
                  <th scope="col">Conversions</th>
                  <th scope="col">Status</th>
                </tr>
                </thead>
                <tbody>
                {campaignsData !== '' &&
                  <XcubeRenderCampaigns data={campaignsData.results} />
                }
                </tbody>
              </table>
              <a href="/campaigns" className="show-more">Show more</a>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
