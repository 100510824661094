import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import startLogo from "../create-campaign-wizard-gdpr/assets/wiz-info.png";
import XcubeInput from "../../../../shared/components/xcube-input/xcube-input";
import XcubeTextButton from "../../../../shared/components/xcube-buttons/xcube-text-button/xcube-text-button";
import XcubeFilledButton from "../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import Container from "react-bootstrap/Container";
import "./create-campaign-wizard-gdpr.scss";
import XcubeSelect from "../../../../shared/components/xcube-select/xcube-select";
import XcubeCheckbox from "../../../../shared/components/xcube-checkbox/xcube-checkbox";
import XcubeInputFile from '../../../../shared/components/xcube-input-file/xcube-input-file';
import Col from 'react-bootstrap/Col';
import { Textarea } from 'react-inputs-validation';
import XcubeTextarea from '../../../../shared/components/xcube-textarea/xcube-textarea';
import alert from "./assets/alert.png";
import XcubeInputFilePdf from '../../../../shared/components/xcube-input-file-pdf/xcube-input-file-pdf';

class CreateCampaignWizardGdpr extends Component {

    constructor(props) {
        super(props);
    }

    handleChanges = (event) => {
        let policy = this.props.state.policy;
        policy[event.name] = event.value;
        
        this.setState({
            policy: policy
        });
    }

    render() {
        return (
            <div id="createCampaignWizardGdpr" className="mt-md-5 pb-md-5">
                <Container>
                    <Row>
                        <div className="col-md-12">
                            <Card className="mt-2">
                                <Card.Header>
                                    <div className="xcube-card-header mt-md-3">
                                        <Row>
                                            <div className="col-sm-2 
                                            col-md-2 col-lg-2">
                                                <img src={startLogo} alt=""/>
                                            </div>
                                            <div className="col-md-8 col-lg-6 ml-md-5">
                                                <h3>
                                                    Just some more legal info...
                                                </h3>
                                                <p>
                                                    Fill in the Privacy Policy & Terms of Use to complete the set up process.
                                                </p>
                                                <small>
                                                    You can use and fill in our default legal documents, paste your own texts, or attach your legal documents. 
                                                </small>
                                            </div>
                                        </Row>
                                    </div>
                                </Card.Header>
                                <Card.Body className="mt-md-4 p-0">

                                    <div className="xcube-card-body">
                                        <Row>
                                            <Col md={12}>
                                                <p>Settings</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="col-md-4">
                                                <XcubeSelect
                                                    label="Text sources"
                                                    options={[
                                                        {id: 'text', name: 'Plain text'},
                                                        {id: 'attachments', name: 'Attachments'},
                                                    ]}
                                                    onChange={(event) => this.handleChanges(event, 'source')}
                                                    id={'text_source'}
                                                    name={'source'}
                                                    errorName={'Text sources'}
                                                    defaultValue={this.props.state.policy.source}
                                                    required={true}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <XcubeInputFile
                                                    name="background"
                                                    onChange={this.props.updateState}
                                                    previewImg={this.props.state.policy.background}
                                                    label={'Background'}
                                                    id={'background_image'}
                                                />
                                            </div>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <p>Content</p>
                                            </Col>
                                        </Row>
                                        {this.props.state.policy.source === "text"
                                        ?   <Row>
                                                <Col md={12}>
                                                    <XcubeTextarea 
                                                        label="Privacy Policy text"
                                                        id="privacy-policy-text"
                                                        name="privacy_policy"
                                                        onChange={this.handleChanges}
                                                    />
                                                </Col>
                                                <Col md={12}>
                                                    <XcubeTextarea 
                                                        label="Terms of Use text"
                                                        id="terms-of-use-text"
                                                        name="terms_of_use"
                                                        onChange={this.handleChanges}
                                                    />
                                                </Col>
                                            </Row>
                                        
                                        :
                                            <Row>
                                                <Col md={4}>
                                                    <XcubeInputFilePdf
                                                        name="privacy_policy"
                                                        onChange={this.handleChanges}
                                                        label={'Privacy Policy'}
                                                        id={'privacy_policy'}
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <XcubeInputFilePdf
                                                        name="terms_of_use"
                                                        onChange={this.handleChanges}
                                                        label={'Terms of Use'}
                                                        id={'terms_of_use'}
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            <span>
                                               <img src={alert} /> If you’re using the pre-fill information, make sure to change the company information with your own.
                                            </span>
                                        </Row>
                                    </div>
                                </Card.Body>
                                <Card.Footer className="xcube-card-footer">
                                    <Row>
                                        <div className="col-md-12">
                                            <div className="btn-group float-md-right" role="group" aria-label="Basic example">
                                                <XcubeTextButton btnState="normal" text="< Social" onClick={this.props.previousStep}/>
                                                <XcubeFilledButton btnState="normal" text="Continue >" onClick={this.props.nextStep}/>
                                            </div>
                                        </div>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default CreateCampaignWizardGdpr;