import "./xcube-analytics-devices-details.scss";
import {Row, Col} from 'react-bootstrap'
import Chart from "react-google-charts";
import React, {useEffect, useState} from 'react';
import {dataHelper} from "../../helpers/data-helper";

function XcubeAnalyticsDevicesDetails(props) {
  const [devices, setDevices] = useState([]);
  useEffect(() => {
    let arr = [
      ["Device type", "Number of devices"]
    ];
    props.data.map((resp) => {
      arr.push([
        dataHelper.capitalizeString(resp.type ? resp.type : 'desktop') + " " + resp.count, resp.count
      ]);
    })
    setDevices(arr);
  }, [props.data])

    return (
      <Row className='devicesUsed align-items-center pt-3'>
        <Col md={6} lg={12} className="text-center">
          {devices.length > 0
          ? <Chart
              className='deviceDetails'
              style={{margin: '0 auto'}}
              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={devices}
              options={{
                chartArea: {top: '20'},
                slices: {
                  0: { color: '#9a84d8' },
                  1: { color: '#88c559' },
                  2: { color: '#f78b60' }
                },
                pieSliceText: 'value-and-percentage',
                legend: {
                  position: 'bottom',
                  alignment: 'center' ,
                  textStyle: {
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: '#8c8c8c',
                  fontName: 'Raleway'
                  }
                }
              }}
              rootProps={{ 'data-testid': '1' }}
            />
          : <p>No data to show yet</p>
          }
        </Col>
      </Row>
    )
}

export default XcubeAnalyticsDevicesDetails;
