import React, {Component} from 'react';
import './sliding-puzzle.scss';
import Row from "react-bootstrap/Row";
import XcubeFilledButton
    from "../../../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import XcubeInput from "../../../../../../../../shared/components/xcube-input/xcube-input";
import XcubeColorPicker from "../../../../../../../../shared/components/xcube-color-picker/xcube-color-picker";
import XcubeFileUpload from "../../../../../../../../shared/components/xcube-file-upload/xcube-file-upload";
import {setDataService} from "../../../../../../../../shared/services/setDataService";

class SlidingPuzzleEditor extends Component {
    constructor(props){
        // console.log(props);
        super(props);
        // this.state = props.game_settings;
        this.state = {
            gameTitle: "Sliding Puzzle",
            gameImage: 'https://www.bigstockphoto.com/images/homepage/module-6.jpg',
            time_limit: 100,
            starColor: 'gray',
            borderColor: 'green',
            tileFocuse: 'red',
            puzzleBackground: 'red',
            titleColor: 'orange',
            gameBackground: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.submitData = this.submitData.bind(this);
        this.handleTileChange = this.handleTileChange.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleTileChange(event){
        this.setState({
            'front_images': event
        });
    }

    handleColorChange(name, event){
        // console.log(name ,event);
        this.setState({
            [name]: event.hex
        });
    }

    handleImageChange(event){
        this.setState({
            'gameImage': event
        })
    }

    submitData(){
        // console.log(this.props.props?.match?.params?.id);
        setDataService.updateGameSettings(this.props.props?.match?.params?.id, this.state).then((res:any) => {
            // console.log(res);
        });
    }

    render() {
        return (
            <div id="campaignGame" className="pt-md-5 sliding-puzzle-game">
                <div className="container">
                    <Row>
                        <div className="col-md-12 section-head">
                            <h1>
                                General
                            </h1>
                            <p>Set up the basic settings related to this particular game type.<br/>
                                The template will predefine how the options look like, so you can focus on the content here.</p>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-4">
                            <XcubeInput label={'Game Title'}
                                        placeholder={this.state?.gameTitle ? this.state.gameTitle: 'Game Title'}
                                        type={'text'}
                                        onChange={this.handleChange}
                                        value={this.state?.gameTitle}
                                        name={'gameTitle'}
                                        id={'game_title'}/>
                        </div>
                        <div className="col-md-4">
                            <XcubeInput label={'Time Limit'}
                                        placeholder={this.state?.time_limit ? this.state.time_limit.toString() : 'Set time limit in seconds'}
                                        type={'number'}
                                        onChange={this.handleChange}
                                        value={this?.state?.time_limit.toString()}
                                        name={'time_limit'}
                                        id={'time_limit'}/>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <hr/>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-md-12 section-head">
                            <h1>
                                Game Colors
                            </h1>
                        </div>
                    </Row>

                    <Row>

                        <div className="col-md-4">
                            <div className="color-picker">
                                <span>Title color: </span> <XcubeColorPicker propColor={this.state.titleColor || ''} color={this.state.titleColor} onChange={(e) => this.handleColorChange('titleColor', e)} name="titleColor"/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="color-picker">
                                <span>Background color: </span> <XcubeColorPicker propColor={this.state.gameBackground || ''} color={this.state.gameBackground} onChange={(e) => this.handleColorChange('gameBackground', e)} name="gameBackground"/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="color-picker">
                                <span>Stars Color: </span> <XcubeColorPicker propColor={this.state.starColor || ''} color={this.state.starColor} onChange={(e) => this.handleColorChange('starColor', e)} name="starColor"/>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <hr/>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-4">
                            <div className="color-picker">
                                <span>Memory card color: </span> <XcubeColorPicker propColor={this.state.puzzleBackground || ''} color={this.state.puzzleBackground} onChange={(e) => this.handleColorChange('puzzleBackground', e)} name="puzzleBackground"/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="color-picker">
                                <span>Border Color: </span> <XcubeColorPicker propColor={this.state.borderColor || ''} color={this.state.borderColor} onChange={(e) => this.handleColorChange('borderColor', e)} name="borderColor"/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="color-picker">
                                <span>Tile focus color: </span> <XcubeColorPicker propColor={this.state.tileFocuse || ''} color={this.state.tileFocuse} onChange={(e) => this.handleColorChange('tileFocuse', e)} name="tileFocuse"/>
                            </div>
                        </div>

                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <hr/>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-6 section-head">
                            <h1>
                                Sliddinig puzzle image
                            </h1>
                        </div>
                        {/*<div className="col-md-6 question-btn">*/}
                        {/*    <NewQuestionModal />*/}
                        {/*</div>*/}
                    </Row>
                    <Row>
                        <div className="col-md-5 mt-md-3">
                            <XcubeFileUpload name={'gameImage'} preview={true} images={this.state.gameImage} onChange={this.handleImageChange} text="You should use a full color .PNG image that is not larger then 3 MB." head="Drag your images here to upload it." />
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <hr/>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-3 mb-3 mt-md-3">
                            <XcubeFilledButton btnState="normal" text="Save changes" onClick={this.submitData}/>
                        </div>
                    </Row>
                </div>
            </div>
        )
    }
}
export default SlidingPuzzleEditor;
