import React, {Component} from 'react';
import XcubeFilledButton
    from "../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import Row from "react-bootstrap/Row";
import "./campaign-emails.scss";
import XcubeInput from "../../../../../../shared/components/xcube-input/xcube-input";
import XcubeTinymce from "../../../../../../shared/components/xcube-tinymce/xcube-tinymce";
import {getDataService} from "../../../../../../shared/services/getDataService";
import { setDataService } from '../../../../../../shared/services/setDataService';
import { toast } from 'react-toastify';
import Col from 'react-bootstrap/Col';
import XcubeInputFile from '../../../../../../shared/components/xcube-input-file/xcube-input-file';

class CampaignEmails extends Component {
    componentDidMount() {
        this.props.changeTitle('Campaign - Emails');
    }
    constructor(props){
        super(props);
        this.state = {
            emailData: {
                sender_name: '',
                mail_to: '',
                mailchimp_api_key: '',
                email_subject: '',
                email_body: '',
                introduction: '',
                background: '',
                prize_image: '',
                notify_team: false,
                notify_sponsor: false
            },
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const value = event.value;
        const name = event.name;
        this.setState(prevState => ({
            emailData: {
                ...prevState.emailData,
                [name]: value
            }
        }));
    }

    componentWillMount(){
        this.campaignId = this.props.match.params.id;
        getDataService.campaignEmailPage(this.campaignId).then((data) => {
            this.setState({
                emailData: data.data.body
            })
        });
    }

    submitData = () => {
      setDataService.updateEmailData(this.state.emailData, this.campaignId)
        .then(data => {
           toast.success('Email preferences successfully saved!');
        });
    };

    testEmail = () => {
        setDataService.testEmail(this.state.emailData, this.campaignId)
          .then(data => {
              toast.success('Test Email successfully sent!');
          });
    };

    render() {
        return (
            <div id="campaignEmails" className="pt-md-5">
                <div className="container">
                    <Row>
                        <div className="col-md-12">
                            <Row>
                                <div className="col-md-6 section-head">
                                    <h1>
                                        Basics
                                    </h1>
                                    <p>The following information will be used in the e-mail sending process.</p>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <Row>
                                <div className="col-md-4">
                                    <XcubeInput label="Sender name" id={'sender_name'} placeholder="Company, LLC." type="text" name="sender_name" value={this.state?.emailData?.sender_name || ''} onChange={this.handleChange}/>
                                </div>
                                {/*<div className="col-md-4">*/}
                                {/*    <XcubeInput label="Reply-to email address" id={'reply_email'} placeholder="reply-to@mail.com" type="text" name="reply_to" value={this.state?.emailData?.reply_to || ''} onChange={this.handleChange}/>*/}
                                {/*</div>*/}
                                {/*<div className="col-md-4">*/}
                                {/*    <XcubeInput label="Mailchimp API Key" id={'mailchimp_api_key'} placeholder="31d254nb21" type="text" name="mailchimp_api_key" value={this.state?.emailData?.mailchimp_api_key || ''} onChange={this.handleChange}/>*/}
                                {/*</div>*/}
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <XcubeInputFile
                                      name={'background'}
                                      onChange={this.handleChange}
                                      previewImg={this.props.state?.emailData.background}
                                      label={'Email Background'}
                                      id={'background'}
                                    />
                                </Col>
                                <Col md={4}>
                                    <XcubeInputFile
                                      name={'prize_image'}
                                      onChange={this.handleChange}
                                      previewImg={this.props.state?.emailData.prize_image}
                                      label={'Email Prize Image'}
                                      id={'prize_image'}
                                    />
                                </Col>
                            </Row>
                            {/*<Row>*/}
                            {/*    <div className="col-md-4">*/}
                            {/*        <XcubeCheckbox title="Notify the team members" id="notifyTheTeam" label="Forward the winner email to team members associated with the campaign." checked={this.state?.emailData?.notify_team || false} onChange={this.handleChange} name={'notify_team'}/>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-md-4">*/}
                            {/*        <XcubeCheckbox title="Notify the sponsor" id="notifyTheSponsor" label="Forward the winner email to the sponsor you specified in the prize settings." checked={this.state?.emailData?.notify_sponsor || false} onChange={this.handleChange} name={'notify_sponsor'}/>*/}
                            {/*    </div>*/}
                            {/*</Row>*/}
                            <Row>
                                <div className="col-md-6">
                                    <XcubeFilledButton btnState="normal" text="Save changes" onClick={() => this.submitData()}/>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <hr/>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-8 section-head">
                            <h1>
                                Winner email
                            </h1>
                            <p>Fill in the folowing information to create a customized winner e-mail message.<br/>
                                The general design will be automaticaly be set to match your chosen campaign template.</p>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-8">
                            <XcubeInput label="Email subject" placeholder="Subject" type="text" name="email_subject" value={this.state?.emailData?.email_subject || ''} onChange={this.handleChange}/>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-8 introduction">
                            <span className="xcube-label mb-md-1">E-mail introduction</span>
                            <XcubeTinymce
                              initialValue={this.state?.emailData?.introduction || ''}
                              onChange={this.handleChange}
                              name={'introduction'}
                            />
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-8">
                            <span className="xcube-label mb-md-1">E-mail body</span>
                            <XcubeTinymce
                              initialValue={this.state?.emailData?.email_body || ''}
                              onChange={this.handleChange}
                              name={'email_body'}
                            />
                        </div>
                    </Row>
                    <Row className="mb-4">
                        <div className="col-md-3 col-lg-2 mt-md-3">
                            <XcubeFilledButton btnState="normal" text="Save changes" onClick={() => this.submitData()}/>
                        </div>
                        <div className="col-md-3 col-lg-2 mt-md-3">
                            <span onClick={() => this.testEmail()} className="test-email">
                                @ Test email
                            </span>
                        </div>
                    </Row>
                    {/*<Row>*/}
                        {/*<div className="col-md-12">*/}
                            {/*<hr/>*/}
                        {/*</div>*/}
                    {/*</Row>*/}
                    {/*<Row>*/}
                        {/*<div className="col-md-8 section-head">*/}
                            {/*<h1>*/}
                                {/*Administration*/}
                            {/*</h1>*/}
                            {/*<p>Send a copy of the winner e-mail to the team members or sponsors <br/>*/}
                                {/*associated with this campaigns.</p>*/}
                        {/*</div>*/}
                    {/*</Row>*/}
                    {/*<Row>*/}
                        {/*<div className="col-md-8">*/}
                            {/*<XcubeInput label="Users to send to" placeholder="John Smith"/>*/}
                        {/*</div>*/}
                    {/*</Row>*/}
                </div>
            </div>
        );
    }
}

export default CampaignEmails;
