import axios from 'axios';
import { authenticationService } from '../services/authenticationService';
import store from '../../redux/store';
import { displaySpinner, editRegistration } from '../../redux/actions';

export const interceptors = {
  reqInterceptor,
  respInterceptor,
};

function reqInterceptor() {
  axios.interceptors.request.use(
    (config) => {
      const customConfig = config;
      store.dispatch(displaySpinner({value: true}));
      if (authenticationService.isUserAuth()) {
        const tokens = JSON.parse(localStorage.getItem('tokens'));
        customConfig.headers.Authorization = `Bearer ${tokens.access.token}`;
        customConfig.headers['Content-Type'] = 'application/json';
      }
      return customConfig;
    }, (error) => {
      store.dispatch(displaySpinner({value: false}));
      return Promise.reject(error);
    }
  );
}

function respInterceptor() {
  axios.interceptors.response.use((response) => {
    store.dispatch(displaySpinner({value: false}));
    return response;
  }, (error) =>{
      store.dispatch(displaySpinner({value: false}));
      if (401 === error.response.status) {
          authenticationService.logout();
          window.location.reload();
      }
      return Promise.reject(error);
  });

}
