import React, {Component} from 'react';
import Datetime from "react-datetime";
import "./xcube-appointment-picker.scss";
import '../../../../node_modules/react-datetime/css/react-datetime.css';
import moment from 'moment';
import "../../../../node_modules/moment/locale/en-gb";
import ReactDOM from 'react-dom';

class XcubeAppointmentPicker extends Component {



    constructor() {
        super();
        this.pickerToggle = this.pickerToggle.bind(this);
        this.state = {
            date: Datetime.moment(),
            selectedDate: '',
            selectedTime: '',
            shouldHide: true
        };
    }

    pickerToggle = () => {
        // this.state.shouldHide = !this.state.shouldHide;

        // directly mutated state fix
        this.setState( prevState => ({
            shouldHide: !prevState.shouldHide
        }));
        this.forceUpdate();
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                shouldHide: true
            });
        }
    }

    onChange = data =>  {
        let formatedDate = moment(data).format('DD/MM/YYYY');
        let formatedTime = moment(data).format('HH:mm');
        this.setState(prevState => ({
            ...prevState,
            date: data,
            selectedDate: formatedDate,
            selectedTime: formatedTime
        }));
        this.props.onChange({
            name: this.props.name,
            value: formatedDate + ' ' + formatedTime
        });
    };

    render() {
        var today = Datetime.moment();
        var valid = function( current ){
            return current.isAfter( today ) && current.day() !== 0 && current.day() !== 6;
        };
        if(this.state.selectedTime === '')
        {
            while(!valid(this.state.date))
            {
                this.state.date.add(1, 'days');
                this.state.date.hours(9);
                this.state.date.minutes(0);
            }
        }

        return (
            <div id="xcubeAppointmentPicker">
                <div className="xcubeAppointmentPicker noselect">
                    <label>{this.props.label}</label>
                    <div id="button" onClick={this.pickerToggle}>
                        <div style={this.state.selectedDate !== '' ? { display: 'none' } : {} }>
                            <span className="dateLabel">Select date and time that best suits you</span>
                        </div>
                        <div style={this.state.selectedDate !== '' ? {} : { display: 'none' }}>
                            <span className="dateLabel">Call me on </span><span className="date">{this.state.selectedDate}</span>
                            <span className="timeLabel"> at </span><span className="time">{this.state.selectedTime}</span>
                        </div>
                    </div>
                    <div style={{visibility: this.state.shouldHide ? "hidden" : "visible"}}>
                        <Datetime value={this.state.date} input={false} onChange={this.onChange} locale={"en-gb"} isValidDate={ valid } dateFormat={"DD/MM/YYYY"} timeFormat={"HH:mm"} timeConstraints={ { hours : { min: 9, max: 17, step: 1}, minutes : { step: 5}}} />
                    </div>
                </div>
            </div>
        );
    }
}

export default XcubeAppointmentPicker;
