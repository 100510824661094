import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./interactive.scss";

class Interactive extends Component {
    render() {
        return (
            <div id="interactive-why-brame">
                <Container>
                    <Row>
                        <Col md={12} className="mt-md-5 pt-md-5">
                            <h3>interactive content generates</h3>
                            <h1>400-500%</h1>
                            <h3>page views than static content</h3>
                            <a target="_blank" href="https://www.startups.com/library/expert-advice/how-interactive-content-can-skyrocket-marketing" rel="noopener noreferrer">Source ►</a>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Interactive;
