import React from 'react';
import './create-campaign-wizard-nav.scss';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import normalDot from "../../assets/normalDot.svg";
import passedDot from "../../assets/passedDot.svg";
import activeDot from "../../assets/activeDot.svg";
import Steps, { Step } from 'rc-steps';
const CreateCampaignWizardNav = (props) => {
    const dots = [];
    let normalIcon = <img src={normalDot} alt="Normal"/>;
    let passedIcon = <img src={passedDot} alt="Passed"/>;
    let activeIcon = <img src={activeDot} alt="Active"/>;

    for (let i = 1; i <= props.totalSteps; i += 1) {
        dots.push((
            <Step title={props.steps[i - 1]} icon={i > props.currentStep ? normalIcon : i < props.currentStep ? passedIcon : activeIcon} className={i > props.currentStep ? 'normalIcon' : i < props.currentStep ? 'passedIcon' : 'activeIcon'} key={'step-'+i}/>
        ));
    }

    return (
            <Steps labelPlacement="vertical" current={1}>
                {dots}
            </Steps>
    );
};

export default CreateCampaignWizardNav;
