import React from 'react';
import "./xcube-analytics-cta.scss";
function XcubeAnalyticsCTA(props) {
  return (
    <div id="campaignCta">
      <div className="card">
        <div className="card-body">
          <div className="row no-gutters">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <h5 className="card-title">CTA</h5>
                </div>
              </div>
            </div>
            {props.data.slice(0, 4).map((cta) => {
              return (
                <React.Fragment key={cta.key}>
                  <div className="col-8">
                    {cta.name.length > 27 ?
                      <p className="card-text">{cta.name.substring(0, 20) + '...'}</p>
                      :
                      <p className="card-text">{cta.name}</p>
                    }
                  </div>
                  <div className="col-4 text-right">
                    {cta.count &&
                    <p className="card-text"><strong>{cta.count}</strong></p>
                    }
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default XcubeAnalyticsCTA;
