import React, {Component} from 'react';
import XcubeInput from "../../../../../shared/components/xcube-input/xcube-input";
import XcubeFilledButton from "../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import XcubeTextarea from "../../../../../shared/components/xcube-textarea/xcube-textarea";
import "./contact.scss";
import { toast } from 'react-toastify';

class Contact extends Component {

    constructor(props){
        super(props);
        this.state = {
            contactForm: {
                name: '',
                email: '',
                message: ''
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        let name = event.name;
        let value = event.value;
        this.setState(prevState => ({
            contactForm: {
                ...prevState.contactForm,
                [name]: value
            }
        }));
    }

    handleSubmit(event) {
        event.preventDefault();
        if (
          this.state.contactForm.name !== '' &&
          this.state.contactForm.email !== '' &&
          this.state.contactForm.message !== ''
        ) {
            /*authenticationService.login({email: this.state.loginForm.email, password: this.state.loginForm.password}).then((res) => {
                      window.location.reload();
                  }).catch((onerror) => {
                      toast.error("Invalid credentials!");
                  });*/
            toast.info("All ok!");
        }else{
            toast.error("You must fill in all required fields!");
        }
    }

    render() {
        return (
            <div id="contact-about-page">
                <div className="container">
                    <div className="row">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-row">
                            <div className="col-md-6">
                                <XcubeInput type="text" label="Your name" onChange={this.handleChange} value={this.state.contactForm.name || ''} name="name" required={true}/>
                                <XcubeInput type="email" label="Email" onChange={this.handleChange} value={this.state.contactForm.email || ''} name="email" required={true}/>
                                <XcubeTextarea name="message" label="Message" onChange={this.handleChange} value={this.state.contactForm.message || ''} required={true}/>
                            </div>
                            <div className="col-md-6 hero-text" style={{alignSelf: "center"}}>
                                <h3>We'd love to hear <br/> from you</h3>
                                <p>
                                    We love a good chat! Please contact us if you have any questions or if you need further information about us and our platform.
                                </p>
                                <XcubeFilledButton btnState="normal" text="Send message" type="submit"/>
                            </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;