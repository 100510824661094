import React from 'react';
import "./x-cube-analytics-best-time.scss";
import logo from './assets/wiz-engagement.png'
import * as moment from 'moment';


function XcubeAnalyticsBestTime(props) {

        return (
            <div id="campaign-leads">
                <div className="card">
                    <div className="card-title mt-3 ml-3">Best time</div>
                    <div className="row no-gutters">
                        <div className="col-6">
                            <div className="card-body pt-0">
                                <p className="card-text"><strong className="strongTime">{props.campaignData.leads[0]?.time ? moment.duration(parseInt(props.campaignData.leads[0]?.time), 'seconds').format("mm:ss") : null}</strong></p>
                                <p className="card-text">{props.campaignData.leads[0]?.name ? props.campaignData.leads[0]?.name : <span style={{fontSize: '14px'}}>No data to show yet</span> }</p>
                                <p className="card-text"><small>  {props.campaignData.leads[1]?.time ? ` 2nd best ${moment.duration(parseInt(props.campaignData.leads[1]?.time), 'seconds').format("mm:ss")}` : null}</small></p>
                            </div>
                        </div>
                        <div className="col-6 text-right pr-4 pb-2">
                           <img src={logo} className="bestTimeImg" alt="" style={{width: '100px'}}/>
                        </div>
                    </div>
                </div>
            </div>
        );

}

export default XcubeAnalyticsBestTime;
