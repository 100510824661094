import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "./assets/brame-logo.svg";
import Nav from "react-bootstrap/Nav";
import "./public-navbar.scss";
import {
    NavLink, Router,
} from 'react-router-dom';
import XcubeFilledButton from "../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";

class PublicNavbar extends Component {
    render() {
        return (
            <div id="public-navbar">
                <Navbar>
                    <Container>
                        <Navbar.Brand>
                            <Nav>
                                <NavLink to="/">
                                    <img
                                        alt=""
                                        src={logo}
                                        width="30"
                                        height="30"
                                        className="d-inline-block align-top"
                                    />
                                </NavLink>
                            </Nav>
                        </Navbar.Brand>
                        {/*<Navbar.Collapse id="basic-navbar-nav">*/}
                        {/*    <Nav className="ml-auto">*/}
                        {/*        <NavLink activeClassName="active" className="nav-link" to="/login">Login</NavLink>*/}
                        {/*        <NavLink activeClassName="active" className="nav-link" to="/why-brame">Why Brame?</NavLink>*/}
                        {/*        <NavLink activeClassName="active" className="nav-link" to="/inspiration">Inspiration</NavLink>*/}
                        {/*        <NavLink activeClassName="active" className="nav-link" to="/about-us">About us</NavLink>*/}
                        {/*        <Nav.Link href="/book-a-demo"><XcubeFilledButton btnState="normal" text="Book a demo"/></Nav.Link>*/}
                        {/*    </Nav>*/}
                        {/*</Navbar.Collapse>*/}
                        {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />*/}
                    </Container>
                </Navbar>
            </div>
        );
    }
}

export default PublicNavbar;
