import React, {Component, useEffect, useState} from 'react';
import "./xcube-analytics-details-leads.scss";
import {lead, Col} from 'react-bootstrap'
import Chart from "react-google-charts";
import moment from 'moment';


function XcubeAnalyticsDetailsLeads(props) {
    const [data, setData] = useState([]);
    const headers = ['Date', 'Leads'];

    const showDateFormat = () => {
        return props.showDateFormat
    }

    const getAnalytics = () => {
        let dataArr = [];

        if (props.data.length) {
            props.data.map((lead) => {
                if (moment.duration(moment(props.end_date).diff(moment(props.start_date))).asDays() === 0) {
                    dataArr.push([
                        moment(`${lead.year}-${lead.month}-${lead.day} ${lead.hour}:00`).format('HH'),
                        lead.count
                    ]);
                } else if (moment.duration(moment(props.end_date).diff(moment(props.start_date))).asDays() <= 31) {
                    dataArr.push([
                        moment(`${lead.year}-${lead.month}-${lead.day}`).format('DD MMM'),
                        lead.count
                    ]);
                } else if (moment.duration(moment(props.end_date).diff(moment(props.start_date))).asDays() > 31) {
                    dataArr.push([
                        moment(`${lead.year}-${lead.month}-${lead.day}`).format('MMM'),
                        lead.count
                    ]);
                }
                setData([headers, ...dataArr]);
            });
        }
        if(!props.data.length){
            setData([]);
        }
    };

    useEffect(() => {
        getAnalytics()
    }, [props.data])

    return (
        <lead>
            <Col lg={12} className="text-center pt-3 mb-lg-4">
                <h5>Leads</h5>
                {data.length
                    ? <Chart
                        className='visitorsDetails'
                        chartType="AreaChart"
                        style={{margin: '0 auto'}}
                        loader={<div>Loading Chart</div>}
                        data={data}
                        options={{
                            legend: {
                                position: 'top'
                            },
                            hAxis: {
                                title: ` ${showDateFormat()} \n From ${moment(props.start_date).format('MM-DD-YYYY')} to ${moment(props.end_date).format('MM-DD-YYYY')}`,
                                titleTextStyle: {color: '#333', bold: true}
                            },
                            vAxis: {minValue: 0, title: 'Leads', titleTextStyle: {bold: true, fontSize: 14}},
                            chartArea: {width: '80%', height: '70%', bottom: '25%'},
                        }}
                        rootProps={{'data-testid': '1'}}
                        chartPackages={['corechart', 'controls']}
                    />
                    : <p>No data to show yet</p>
                }
            </Col>
        </lead>
    )
}

export default XcubeAnalyticsDetailsLeads;
