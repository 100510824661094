import React from 'react';
import './builder.scss';

function Builder() {
    return(
        <div id={'builder'}>
            <label className={'novi'}>
                aslkfalksf
            </label>
        </div>
    )
}

export default Builder;
