import {useState}
  from "react";
import Modal
  from "react-bootstrap/Modal";
import React
  from "react";
import XcubeFilledButton
  from '../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button';
import Col
  from 'react-bootstrap/Col';
import XcubeInput
  from '../../../../../../shared/components/xcube-input/xcube-input';
import Row
  from 'react-bootstrap/Row';
import { CardElement, Elements, useElements, useStripe }
  from '@stripe/react-stripe-js';
import { loadStripe }
  from '@stripe/stripe-js';
import { Constants }
  from '../../../../../../Constants';
import "./payment-popup.scss";
import XcubeCheckbox
  from '../../../../../../shared/components/xcube-checkbox/xcube-checkbox';
import store
  from '../../../../../../redux/store';
import { editRegistration }
  from '../../../../../../redux/actions';
import { setDataService } from '../../../../../../shared/services/setDataService';
import { toast } from 'react-toastify';

function PaymentPopup() {
  const [show, setShow] = useState(false);
  const [reg, setReg] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleRegForm = (data) => setReg(data);
  const stripePromise = loadStripe(Constants.API_KEYS.STRIPE.stripe_pk_test_key);

  store.subscribe(() => handleRegForm(store.getState()));
  function onInputChange(data){
    let name = data.name;
    let value = data.value;
    store.dispatch(editRegistration({name: name, value: value}));
  }

  function paymentPage(){
    onInputChange({name: 'validate', value: true});
    let state = store.getState();
    if (
      state.registrationForm.first_name.length > 0 &&
      state.registrationForm.last_name.length > 0 &&
      state.registrationForm.email.length > 0 &&
      state.registrationForm.password.length > 0 &&
      state.registrationForm.company.length > 0
    ) {
      handleShow();
    }else{
      toast.error("You must fill in all required fields!");
    }
  }

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }
      const cardElement = elements.getElement(CardElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        console.log('[error]', error);
      } else {
        store.dispatch(editRegistration({name: 'stripe_payment_method', value: paymentMethod}));
        let data = store.getState();
        setDataService.setSignUpData(data.registrationForm).then((resp) => {
          if(resp.data.message === 'success'){
            localStorage.setItem('currentUser', JSON.stringify(resp.data.body));
            window.location.assign('/wizard');
          }
        });
      }
    };
    return(
      <form onSubmit={handleSubmit}>
        <Row>
          <Col md={12}>
            <div id={'xcube-input'}>
              <label className={'xcube-input-label'}>Card</label>
              <div className={'stripe-card-element xcube-input form-control'}>
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <XcubeCheckbox id="save-card" title="Save billing info" label="Save my billing information and use it for any recurring expenditures (such as renewing the current subscription plan)."/>
          </Col>
        </Row>
        <Modal.Footer>
          <XcubeFilledButton btnState={'normal'} text={'Pay now'} type="submit" disabled={!stripe} />
        </Modal.Footer>
      </form>
    )
  };

  return (
    <Row>
      <XcubeFilledButton btnState="normal" text="Payment >" onClick={ () => paymentPage()}/>
      <Modal show={show} onHide={handleClose}
         aria-labelledby="contained-modal-title-vcenter" id="paymentPopup"
         centered>
        <Modal.Header closeButton>
          <span>
              Payment
          </span>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <XcubeInput placeholder={'John Doe'} label={'Name'} type={'text'} name={'stripe_name'} id={'stripe_name'} onChange={onInputChange} value={reg?.registrationForm?.stripe_name} />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <XcubeInput placeholder={'email@email.com'} label={'E-mail'} type={'email'} name={'stripe_email'} id={'stripe_email'} onChange={onInputChange} value={reg?.registrationForm?.stripe_email} />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <XcubeInput placeholder={'+1-234-567-890'} label={'Phone'} type={'phone'} name={'stripe_phone'} onChange={onInputChange} value={reg?.registrationForm?.stripe_phone} />
            </Col>
          </Row>
          <Elements stripe={stripePromise}>
            <CheckoutForm/>
          </Elements>
        </Modal.Body>
      </Modal>
    </Row>
  );
}

export default PaymentPopup;
