import React from 'react';
import "./xcube-analytics-details-visitors-per-country.scss";
import {Row, Col} from 'react-bootstrap'
import XcubeChartjsGeo from "../xcube-chartjs-geo/xcube-chartjs-geo";

function XcubeAnalyticsDetailsVisitorsPerCountry(props) {
    return(
      <Row className='visitors_per_country'>
        <Col  lg={12} className="pt-3 mb-lg-4">
          <h5 className='mb-4'>Visitors per country</h5>
          {props.data.length ?
            <Row>
              <Col  md={4} lg={2} className="visitors d-none d-sm-block">
                {props.data.map((country, index) => {
                  console.log(country, 'country');

                  return country.country !== null ?
                    <h6 style={{color: '#8c8c8c'}} key={index}>{country.country}: {country.count}</h6>
                    : <h6 style={{color: '#8c8c8c'}} key={index}>Unknown : {country.count}</h6>
                })}

              </Col>
              <Col md={8} lg={10}>
                <XcubeChartjsGeo
                  data={props.data}
                />
              </Col>
            </Row>
            : <p>No data to show yet</p>
          }
        </Col>
      </Row>
    )
}

export default XcubeAnalyticsDetailsVisitorsPerCountry;
