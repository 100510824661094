import axios from "axios";
import {Constants} from "../../Constants";

export const setDataService = {
  setWizardData,
  deleteCampaignData,
  updateCampaignData,
  updateGameSettings,
  setSignUpData,
  pauseAllCampaigns,
  createNewPrize,
  removePrize,
  copyPrize,
  updatePrize,
  importPrizes,
  updateEmailData,
  testEmail,
  updateUser,
  updateCompany,
  copyCampaign,
  updateCampaignAnalytics,
  updateCompanyAnalytics,
  newClientCompany,
  uploadImage,
  updatePayment,
  closeAccount,
  createGroup,
  addEmployee,
  editEmployee,
  deleteEmployee,
  uploadPdf
};

function setWizardData(data: any){
  // console.log(data);
  return axios.post(Constants.endPoint + Constants.WIZARD.putData, data)
    .then(resp => {
        return resp;
    });
}

function deleteCampaignData(id: string){
  return axios.delete(Constants.endPoint + '/campaign/' + id)
    .then(resp => {
      return resp;
    });
}

function updateCampaignData(data, campaignId) {
  return axios.put(Constants.endPoint + '/campaign/' + campaignId, data)
    .then(resp => {
        return resp;
    });
}

function setSignUpData(data: any){
  return axios.post(Constants.endPoint + Constants.AUTHORIZATION.signUp, data)
    .then(resp => {
      return resp;
    });
}

function pauseAllCampaigns(){
  return axios.post(Constants.endPoint + Constants.CAMPAIGNS.pauseAllCampaigns)
    .then(resp => {
      return resp;
    });
}

function updateGameSettings(campaign_id : any, data: any) {
    return axios.put(Constants.endPoint + '/campaign/' + campaign_id + Constants.GAMES.updateGame, data)
        .then(resp => {
            return resp;
    });
}

function createNewPrize(data: any) {
  return axios.post(Constants.endPoint + Constants.PRIZES.newPrize, data)
    .then(resp => {
      return resp;
    });
}

function removePrize(prize_id: any){
  return axios.delete(Constants.endPoint + Constants.PRIZES.newPrize + '/' + prize_id)
    .then(resp => {
      return resp;
    })
}

function copyPrize(prize_id: any){
  return axios.post(Constants.endPoint + Constants.PRIZES.newPrize + '/' + prize_id + '/copy')
    .then(resp => {
      return resp;
    })
}

function updatePrize(data, prize_id: any){
  return axios.put(Constants.endPoint + Constants.PRIZES.newPrize + '/' + prize_id, data)
    .then(resp => {
      return resp;
    })
}

function importPrizes(file, campaignId){
  const data = new FormData();
  data.append('file', file);
  return axios.post(Constants.endPoint + `/dashboard/${campaignId}/prizes/import`, data)
    .then(resp => {
      return resp;
    })
}

function updateEmailData(data, campaignId) {
  return axios.post(Constants.endPoint + `/dashboard/${campaignId}/emails`, data)
    .then(resp => {
      return resp;
    })
}

function testEmail(data, id) {
  return axios.post(Constants.endPoint + Constants.EMAIL.test(id), data)
    .then(resp => {
      return resp;
    })
}

function updateUser(data, id) {
  return axios.patch(`${ Constants.endPoint + Constants.USER.getUser(id)}`, data)
    .then(resp => {
      return resp;
    });
}

function updateCompany(data) {
  return axios.put(`${ Constants.endPoint + Constants.ACCOUNT.updateCompany}`, data)
    .then(resp => {
      return resp;
    });
}

function copyCampaign(campaignId) {
  return axios.post(`${ Constants.endPoint + Constants.CAMPAIGNS.copyCampaign(campaignId)}`)
    .then(resp => {
      return resp;
    });
}

function updateCompanyAnalytics(companyId, data) {
  return axios.post(`${Constants.endPoint + Constants.COMPANY.companyAnalytics(companyId)}`, data)
    .then(resp => {
      return resp;
    });
}

function updateCampaignAnalytics(campaignId, data) {
  return axios.post(`${Constants.endPoint + Constants.CAMPAIGNS.campaignAnalytics(campaignId)}`, data)
    .then(resp => {
      return resp;
    });
}

function newClientCompany(data) {
  return axios.post(`${Constants.endPoint + Constants.COMPANY.client}`, data)
    .then(resp => {
      return resp;
    });
}

function uploadImage(data) {
  return axios.post(`${Constants.endPoint + Constants.IMAGES.upload}`, {image: data})
    .then(resp => {
      return resp;
    });
}

function updatePayment(data) {
  return axios.post(`${Constants.endPoint + Constants.ACCOUNT.updatePayment}`, data)
    .then(resp => {
      return resp;
    });
}

function closeAccount(id) {
  return axios.delete(`${Constants.endPoint}/company/${id}`)
    .then(resp => {
      return resp;
    });
}

function createGroup(data) {
  return axios.post(`${Constants.endPoint + Constants.GROUP.newGroup}`, data)
    .then(resp => {
      return resp;
    });
}

function addEmployee(data) {
  return axios.post(`${Constants.endPoint + Constants.ACCOUNT.addEmployee}`, data)
    .then(resp => {
      return resp;
    });
}

function editEmployee(data) {
  return axios.put(`${Constants.endPoint + Constants.ACCOUNT.editDeleteEmployee(data.id)}`, data)
    .then(resp => {
      return resp;
    });
}

function deleteEmployee(id) {
  return axios.delete(`${Constants.endPoint + Constants.ACCOUNT.editDeleteEmployee(id)}`)
    .then(resp => {
      return resp;
    });
}

function uploadPdf(data) {
  return axios.post(`${Constants.endPoint + Constants.UPLOAD.pdf}`, data)
    .then(resp => {
      return resp;
    });
}
