import { Constants } from '../../Constants';
import { authenticationService } from './authenticationService';
import { getDataService } from './getDataService';
import store from '../../redux/store';
import { editGAData } from '../../redux/actions';
import * as moment from 'moment';

export const googleAnalyticsService = {
  insertAccount,
  insertProperty,
  insertView,
  loadAnalytics,
  isGAReady,
  readAnalyticVisits,
  readAnalyticVisitsPerMonth,
  authenticate,
  getAllAnalytics,
  readVisitsArrayPerCampaign
};

/*
 * This gives the user a popup so he can auth and grant permissions.
 */
function authenticate() {
  return window.gapi.auth2.getAuthInstance()
    .signIn({scope: "https://www.googleapis.com/auth/analytics.manage.users https://www.googleapis.com/auth/analytics.edit https://www.googleapis.com/auth/analytics.readonly"})
    .then(function(res) {
        // console.log("Sign-in successful");
        return loadClient();
      },
      function(err) {
        console.error("Error signing in", err);
        return false;
      });
}

/*
 * This loads client gapi library.
 */
function loadClient() {
  window.gapi.client.setApiKey(Constants.API_KEYS.GA.apiKey);
  return window.gapi.client.load("https://content.googleapis.com/discovery/v1/apis/analytics/v3/rest")
    .then(
      function() {
        // console.log("GAPI client loaded for API");
        return true;
    },
      function(err) {
        console.error("Error loading GAPI client for API", err);
        return false;
      });
}

/*
 * This loads Client Library if user has successfully authorized and gave needed permissions.
 * If not, it will give user an auth popup.
 */
function handleAuthResult(authResult) {
  if (authResult && !authResult.error) {
    return loadClient();
  } else {
    return authenticate();
  }
}

/*
 * This checks if user is authorized and has granted needed permissions.
 */
function checkAuth() {
  return new Promise(((resolve, reject) => {
    let status = window.gapi.auth2.getAuthInstance().isSignedIn.get();
    if(!status){
      window.gapi.auth.authorize({
        client_id: Constants.API_KEYS.GA.clientId,
        scope: 'https://www.googleapis.com/auth/analytics.manage.users https://www.googleapis.com/auth/analytics.edit https://www.googleapis.com/auth/analytics.readonly',
        immediate: true
      }, (authResult) => {
        resolve(handleAuthResult(authResult));
      });
    }else{
      resolve(true);
    }

  }));
}

/*
 * This will load Google auth2 and Google Analytics library,
 * give the Auth popup to the user and grant us needed permissions.
 */
function loadAnalytics() {
  return new Promise((resolve, reject) => {
    window.gapi.load("client:auth2", function () {
      window.gapi.auth2.init({ client_id: Constants.API_KEYS.GA.clientId });
      checkAuth().then((res) => {
        resolve(res);
      });
    });
  });
}

/*
 * This request creates a new Account User Link.
 */
function insertAccount(companyId, accountId) {
  return new Promise((resolve, reject) => {
    return window.gapi.client.analytics.management.accountUserLinks.insert({
      'accountId': accountId,
      'resource': {
        'permissions': {
          'local': [
            'EDIT',
            'MANAGE_USERS'
          ]
        },
        'userRef': {
          'email': 'brameag@gmail.com',
          'name': 'xCube Development'
        }
      }
    })
    .then(function(response) {
        resolve(response);
      },
      function(err) {
        reject(err);
        console.error("Execute error", err);
      });
  });
}

/*
 * This request creates a new Property.
 */
function insertProperty(accountId, websiteUrl, name) {
  return new Promise((resolve, reject) => {
    return window.gapi.client.analytics.management.webproperties.insert(
      {
        'accountId': accountId,
        'resource': {
          'name': name
        }
      }).then(function(response) {
        resolve(response);
      },
      function(err) {
        reject(err);
        console.error("Execute error", err);
      });
  });
}

/*
 * This request creates a new View (Profile).
 */
function insertView(accountId, webPropertyId, name, websiteUrl) {
  return new Promise((resolve, reject) => {
    return window.gapi.client.analytics.management.profiles.insert(
      {
        'accountId': accountId,
        'webPropertyId': webPropertyId,
        'resource': {
          'name': name,
        }
      }).then(function(response) {
        resolve(response);
      },
      function(err) {
        reject(err);
        console.error("Execute error", err);
      });
  });
}

/*
 * This request gets an existing property.
 */
// function getProperty(accountId, webPropertyId) {
//   return new Promise((resolve, reject) => {
//     return window.gapi.client.analytics.management.webproperties.get({
//       'accountId': accountId,
//       'webPropertyId': webPropertyId,
//     }).then(function(response) {
//         resolve(response);
//       },
//       function(err) {
//         reject(err);
//         console.error("Execute error", err);
//       });
//   });
// }

/*
 * This checks if GA is successfully loaded and authorized.
 */
function isGAReady() {
  return new Promise((resolve, reject) => {
    let companyId = authenticationService.currentUserValue.company_id;
    googleAnalyticsService.loadAnalytics().then((res) => {
      if(res) { // If GA is successfully loaded and authorized.
        let gaData = store.getState().editGAData;
        if(gaData.accountId){ // If we already loaded GAData
          if(gaData.accountId !== ''){ // If AccountId is added.
            if(gaData.userRef !== ''){ // If our account is Added to users GA
              resolve({status: true, GAData: gaData});
            }else { // If our account is not added to users GA
              resolve({status: false});
            }
          }else{ // If AccountId is not added.
            resolve({status: false});
          }
        }else{
          getDataService.getCompanyAnalytics(companyId).then((companyAnalytics) => { // Fetch AccountId, SelfRef, defaultProperty and defaultView from DB.
            store.dispatch(editGAData({value: companyAnalytics.data.body}));
            if(companyAnalytics.data.body.accountId !== ''){ // If AccountId is added.
              if(companyAnalytics.data.body.userRef !== ''){ // If our account is Added to users GA
                resolve({status: true, GAData: companyAnalytics.data.body});
              }else { // If our account is not added to users GA
                resolve({status: false});
              }
            }else{ // If AccountId is not added.
              resolve({status: false});
            }
          });
        }
      }
    });
  });
}

/*
 * This will get all needed GA data for campaign status or dashboard page.
 */

function getAllAnalytics(viewId, startDate, endDate) {
  return new Promise((resolve, reject) => {
    const batch = window.gapi.client.newBatch();
    let customDimensions = [
      {
        name: 'ga:day',
      }
    ];
    if(startDate === endDate){
      customDimensions = [
        {
          name: 'ga:hour',
        }
      ]
    } else if(moment.duration(moment(endDate).diff(moment(startDate))).asDays() <= 31){
      customDimensions = [
        {
          name: 'ga:date',
        }
      ]
    }
    else if(moment.duration(moment(endDate).diff(moment(startDate))).asDays() > 31){
      customDimensions = [
        // {
        //   name: 'ga:date'
        // },
        {
          name: 'ga:month',
        }
      ]
    }

  /*
   * GA pageviews.
   */

    batch.add(
      window.gapi.client.request({
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: viewId,
              metrics: [
                {
                  expression: 'ga:pageviews',
                },
              ],
              dateRanges: [
                {
                  startDate: startDate,
                  endDate: endDate
                }
              ],
            },
          ],
        },
      }), { id: 'visits' },
    );


    // GA pageviews per month

    batch.add(
      window.gapi.client.request({ // This will get total Users
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: viewId,
              "includeEmptyRows": true,
              metrics: [
                {
                  expression: 'ga:pageviews',
                },
              ],
              dimensions: customDimensions,
              dateRanges: [
                {
                  "startDate": startDate,
                  "endDate": endDate
                }
              ],
            },
          ],
        },
      }), { id: 'visitsPerMonth' },
    );

    /*
     * GA pageviews per device.
     */

    batch.add(
      window.gapi.client.request({
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: viewId,
              metrics: [
                {
                  expression: 'ga:pageviews',
                },
              ],
              dimensions: [
                {
                  name: 'ga:deviceCategory',
                },
              ],
              dateRanges: [
                {
                  "startDate": startDate,
                  "endDate": endDate
                }
              ],
            },
          ],
        },
      }), { id: 'devices' },
    );

    /*
   * GA pageviews per country.
   */

    batch.add(
      window.gapi.client.request({ // usersPerCountry
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: viewId,
              metrics: [
                {
                  expression: 'ga:pageviews',
                },
              ],
              dimensions: [
                {
                  name: 'ga:country',
                },
              ],
              orderBys:[
                {
                  fieldName: "ga:pageviews",
                  orderType: "VALUE",
                  sortOrder: "DESCENDING"
                }],
              dateRanges: [
                {
                  "startDate": startDate,
                  "endDate": endDate
                }
              ],
            },
          ],
        },
      }), { id: 'usersPerCountry' },
    );

    /*
   * GA session duration per month.
   */

    batch.add(
      window.gapi.client.request({
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: viewId,
              "includeEmptyRows": true,
              metrics: [
                {
                  expression: 'ga:sessionDuration',
                },
                {
                  expression: 'ga:avgSessionDuration',
                },
              ],
              dimensions: customDimensions,
              dateRanges: [
                {
                  "startDate": startDate,
                  "endDate": endDate
                }
              ],
            },
          ],
        },
      }), { id: 'sessionDurationPerDate' },
    );

    /*
     * GA total session duration.
     */

    batch.add(
      window.gapi.client.request({
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: viewId,
              metrics: [
                {
                  expression: 'ga:sessionDuration',
                },
                {
                  expression: 'ga:avgSessionDuration',
                },
              ],
              dateRanges: [
                {
                  "startDate": startDate,
                  "endDate": endDate
                }
              ],
            },
          ],
        },
      }), { id: 'totalSessionDuration' },
    );

    /*
   * GA new users.
   */

    batch.add(
      window.gapi.client.request({
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: viewId,
              metrics: [
                {
                  expression: 'ga:newUsers',
                }
              ],
              dateRanges: [
                {
                  "startDate": startDate,
                  "endDate": endDate
                }
              ],
            },
          ],
        },
      }), { id: 'newUsers' },
    );

    batch.execute((response) => {
      if(response.usersPerCountry.result.error?.code === 403){
        authenticate();
      }else{
        resolve(response);
        console.log(response);
      }
    });

  });
}

/*
 * This request reads "visits" analytics for the specific view and date.
 */
function readAnalyticVisitsPerMonth(viewId, startDate, endDate, dimension) {
  // console.log(viewId, startDate, endDate, dimension);
  return new Promise((resolve, reject) => {

    const batch = window.gapi.client.newBatch();
    batch.add(
      window.gapi.client.request({ // This will get total Users
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: viewId,
              "includeEmptyRows": true,
              metrics: [
                {
                  expression: 'ga:pageviews',
                },
              ],
              dimensions: [
                {
                  name: dimension,
                }
              ],
              dateRanges: [
                {
                  "startDate": startDate,
                  "endDate": endDate
                }
              ],
            },
          ],
        },
      }), { id: 'visitsPerMonth' },
    );

    batch.execute((response) => {
      if(response.visitsPerMonth.result.error?.code === 403){
        authenticate();
      }else{
        resolve(response);
      }
    });
  });
}

/*
 * This request reads "engagement" analytics for the specific view and date.
 */
// function readAnalyticEngagement(viewId, startDate, endDate) {
//   return new Promise((resolve, reject) => {
//
//     const batch = window.gapi.client.newBatch();
//     batch.add(
//       window.gapi.client.request({
//         path: '/v4/reports:batchGet',
//         root: 'https://analyticsreporting.googleapis.com/',
//         method: 'POST',
//         body: {
//           reportRequests: [
//             {
//               viewId: viewId,
//               metrics: [
//                 {
//                   expression: 'ga:sessionDuration',
//                 },
//                 {
//                   expression: 'ga:avgSessionDuration',
//                 },
//               ],
//               dateRanges: [
//                 {
//                   "startDate": startDate,
//                   "endDate": endDate
//                 }
//               ],
//             },
//           ],
//         },
//       }), { id: 'totalSessionDuration' },
//     );
//
//     batch.execute((response) => {
//       // console.log(response);
//       if(response.visits.result.error?.code === 403){
//         authenticate();
//       }else{
//         resolve(response);
//       }
//     });
//   });
// }

/*
 * This request reads "devices" analytics for the specific view and date.
 */
// function readAnalyticDevices(viewId, startDate, endDate) {
//   return new Promise((resolve, reject) => {
//
//     const batch = window.gapi.client.newBatch();
//     batch.add(
//       window.gapi.client.request({ // This will get devices
//         path: '/v4/reports:batchGet',
//         root: 'https://analyticsreporting.googleapis.com/',
//         method: 'POST',
//         body: {
//           reportRequests: [
//             {
//               viewId: viewId,
//               metrics: [
//                 {
//                   expression: 'ga:pageviews',
//                 },
//               ],
//               dimensions: [
//                 {
//                   name: 'ga:deviceCategory',
//                 },
//               ],
//               dateRanges: [
//                 {
//                   "startDate": startDate,
//                   "endDate": endDate
//                 }
//               ],
//             },
//           ],
//         },
//       }), { id: 'devices' },
//     );
//
//     batch.execute((response) => {
//       if(response.devices.result.error?.code === 403){
//         authenticate();
//       }else{
//         resolve(response);
//       }
//     });
//   });
// }

/*
 * This request reads "Users per Country" analytics for the specific view and date.
 */
// function readAnalyticUsersPerCountry(viewId, startDate, endDate) {
//   return new Promise((resolve, reject) => {
//
//     const batch = window.gapi.client.newBatch();
//     batch.add(
//       window.gapi.client.request({ // This will get devices
//         path: '/v4/reports:batchGet',
//         root: 'https://analyticsreporting.googleapis.com/',
//         method: 'POST',
//         body: {
//           reportRequests: [
//             {
//               viewId: viewId,
//               metrics: [
//                 {
//                   expression: 'ga:pageviews',
//                 },
//               ],
//               dimensions: [
//                 {
//                   name: 'ga:country',
//                 },
//               ],
//               orderBys:[
//                 {
//                   fieldName: "ga:pageviews",
//                   orderType: "VALUE",
//                   sortOrder: "DESCENDING"
//                 }],
//               dateRanges: [
//                 {
//                   "startDate": startDate,
//                   "endDate": endDate
//                 }
//               ],
//             },
//           ],
//         },
//       }), { id: 'usersPerCountry' },
//     );
//
//     batch.execute((response) => {
//       if(response.usersPerCountry.result.error?.code === 403){
//         authenticate();
//       }else{
//         resolve(response);
//       }
//     });
//   });
// }

/*
 * This will fetch Total Session Duration + AvgSessionDuration
 */
//
// function readAnalyticSessions(viewId, startDate, endDate) {
//   return new Promise((resolve, reject) => {
//     const batch = window.gapi.client.newBatch();
//     batch.add(
//       window.gapi.client.request({
//         path: '/v4/reports:batchGet',
//         root: 'https://analyticsreporting.googleapis.com/',
//         method: 'POST',
//         body: {
//           reportRequests: [
//             {
//               viewId: viewId,
//               metrics: [
//                 {
//                   expression: 'ga:sessionDuration',
//                 },
//                 {
//                   expression: 'ga:avgSessionDuration',
//                 },
//               ],
//               dateRanges: [
//                 {
//                   "startDate": startDate,
//                   "endDate": endDate
//                 }
//               ],
//             },
//           ],
//         },
//       }), { id: 'totalSessionDuration' },
//     );
//
//     batch.execute((response) => {
//       if(response.totalSessionDuration.result.error?.code === 403){
//         authenticate();
//       }else{
//         resolve(response);
//       }
//     });
//   });
// }


/*
* This will fetch New Users
*/

// function readAnalyticNewUsers(viewId, startDate, endDate) {
//   return new Promise((resolve, reject) => {
//     const batch = window.gapi.client.newBatch();
//     batch.add(
//       window.gapi.client.request({
//         path: '/v4/reports:batchGet',
//         root: 'https://analyticsreporting.googleapis.com/',
//         method: 'POST',
//         body: {
//           reportRequests: [
//             {
//               viewId: viewId,
//               metrics: [
//                 {
//                   expression: 'ga:newUsers',
//                 }
//               ],
//               dateRanges: [
//                 {
//                   "startDate": startDate,
//                   "endDate": endDate
//                 }
//               ],
//             },
//           ],
//         },
//       }), { id: 'newUsers' },
//     );
//
//     batch.execute((response) => {
//       if(response.newUsers.result.error?.code === 403){
//         authenticate();
//       }else{
//         resolve(response);
//       }
//     });
//   });
// }


function readVisitsArrayPerCampaign(campaignsArray) {
  return new Promise((resolve, reject) => {
    const batch = window.gapi.client.newBatch();
    campaignsArray.forEach(campaign => {
      batch.add(
        window.gapi.client.request({ // This will get total Users
          path: '/v4/reports:batchGet',
          root: 'https://analyticsreporting.googleapis.com/',
          method: 'POST',
          body: {
            reportRequests: [
              {
                viewId: campaign.analytics.viewId,
                metrics: [
                  {
                    expression: 'ga:pageviews',
                  },
                ],
                dateRanges: [
                  {
                    startDate: '2015-01-01',
                    endDate: 'today'
                  }
                ],
              },
            ],
          },
        }), {id: campaign.id},
      );

    });
    batch.execute((response) => {
      // console.log(response);
      if (response[campaignsArray[0].id].result.error?.code === 403) {
        authenticate();
      } else {
        resolve(response);
      }
    });

  })}

/*
 * This request reads "visits" analytics for the specific view and date.
 */
function readAnalyticVisits(viewId, startDate, endDate) {
  return new Promise((resolve, reject) => {

    const batch = window.gapi.client.newBatch();
    batch.add(
      window.gapi.client.request({ // This will get total Users
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: viewId,
              metrics: [
                {
                  expression: 'ga:pageviews',
                },
              ],
              dateRanges: [
                {
                  startDate: startDate,
                  endDate: endDate
                }
              ],
            },
          ],
        },
      }), { id: 'visits' },
    );

    batch.execute((response) => {
      // console.log(response);
      if(response.visits.result.error?.code === 403){
        authenticate();
      }else{
        resolve(response);
      }
    });
  });
}

/*
* This will fetch Pageviews per given Country by City
*/

// function readAnalyticPageViewsPerCountryPerCity(viewId, startDate, endDate) {
//   return new Promise((resolve, reject) => {
//     const batch = window.gapi.client.newBatch();
//     batch.add(
//       window.gapi.client.request({
//         path: '/v4/reports:batchGet',
//         root: 'https://analyticsreporting.googleapis.com/',
//         method: 'POST',
//         body: {
//           reportRequests: [
//             {
//               viewId: viewId,
//               metrics: [
//                 {
//                   expression: 'ga:pageviews',
//                 }
//               ],
//               dimensions: [
//                 {
//                   name: 'ga:city'
//                 },
//                 {
//                   name: 'ga:country'
//                 }
//               ],
//               sort:
//                 '-ga:pageviews'
//               ,
//               filters: [
//                 'ga:country==Switzerland'
//               ],
//               dateRanges: [
//                 {
//                   "startDate": startDate,
//                   "endDate": endDate
//                 }
//               ],
//             },
//           ],
//         },
//       }), { id: 'newUsers' },
//     );
//
//     batch.execute((response) => {
//       if(response.newUsers.result.error?.code === 403){
//         authenticate();
//       }else{
//         resolve(response);
//       }
//     });
//   });
// }




