import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import startLogo from '../create-campaign-wizard-template/assets/wiz-template.png';
import XcubeTextButton
  from '../../../../shared/components/xcube-buttons/xcube-text-button/xcube-text-button';
import XcubeFilledButton
  from '../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import "./create-campaing-wizard-prizes.scss";
import Table from 'react-bootstrap/Table';
import NewPrizeWizardModal from './components/new-prize-modal';
import removeImg
  from '../../../dashboard/components/dashboard-campaign/components/campaign-game/assets/remove.svg';
import XcubeInput from '../../../../shared/components/xcube-input/xcube-input';
import XcubeTinymce from '../../../../shared/components/xcube-tinymce/xcube-tinymce';
import XcubeInputFile from '../../../../shared/components/xcube-input-file/xcube-input-file';


class CreateCampaignWizardPrizes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      prizes: [],
    };
  }

  nextStep(){
    if(this.state.prizes.length > 0 &&
      this.props.state.general_sender_name.length > 0 &&
      this.props.state.winner_email_body.length > 0 &&
      this.props.state.winner_email_subject.length > 0
    ){
      return this.props.nextStep();
    }else{
      toast.error("You must complete all steps.");
    }
  }

  onSubmit = (data) => {
    this.setState(prevState => ({
      prizes: [...this.state.prizes, data]
    }), () => {
      this.props.updateState({name: 'prizes', value: this.state.prizes});
    });
  };

  removePrize = (index) => {
    let items = [];
    this.state.prizes.map((item, i ) => {
      if(i !== index) {
        items.push(item);
      }
    });
    this.setState({prizes: items});
  };

  generatePrizes = () => {
    let prizes = [];
    this.state.prizes.map((prize, index) => {
      prizes.push(
        <tr key={prize.name + 'prize'}>
          <td>
            <img src={prize.image.thumbnail_url} alt=""/> {prize.name}
          </td>
          <td>{prize.value_type}</td>
          <td>{prize.draw_type}</td>
          <td>
            <a style={{cursor: "pointer", color: "#f78b60"}} className="deleteBtn" onClick={() => this.removePrize(index)}>
              <img src={removeImg} alt="" />
              Delete
            </a>
          </td>
        </tr>
      );
    });
    return prizes;
  };

  render() {
    return (
      <div id="createCampaignWizardPrizes" className="mt-md-5 pb-md-5">
        <Container>
          <Row>
            <div className="col-md-12">
              <Card className="mt-2">
                <Card.Header>
                  <div className="xcube-card-header mt-md-3">
                    <Row>
                      <div className="divImg col-sm-2 col-md-6 col-lg-2">
                        <img src={startLogo} alt=""/>
                      </div>
                      <div className="col-sm-10 col-md-6 ml-lg-4">
                        <h3>
                          Reward your users
                        </h3>
                        <p>
                          Set up prizes and winning e-mails for your users.
                        </p>
                        <small>
                          You have chosen to implement a Quiz into your campaign. Here, you can change how your game will appear to the end user,
                          how it will work and what content it will contain.
                        </small>
                      </div>
                      {/*<div className="col-md-4 mt-md-5">*/}
                      {/*    <XcubeSelect label="Template style"/>*/}
                      {/*</div>*/}
                    </Row>
                  </div>
                </Card.Header>
                <Card.Body className="mt-md-4 p-0">
                  <div className="xcube-card-body">
                    <Row>
                      <Col md={12}>
                        <Accordion defaultActiveKey="2">
                          <Card>
                            <Card.Header>
                              <Accordion.Toggle as={Button} variant="link" eventKey="0" >
                                Winner e-mail
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0" className="winner-email">
                              <Card.Body>
                                <div className='col-md-12'>
                                  <Row>
                                    <div className="offset-md-2 col-md-8">
                                      <XcubeInput
                                        label="Email subject"
                                        placeholder="Subject"
                                        type="text"
                                        name="winner_email_subject"
                                        value={this.props.state?.winner_email_subject || ''}
                                        onChange={this.props.updateState}
                                      />
                                    </div>
                                  </Row>
                                  <Row>
                                    <div className="offset-md-2 col-md-8 introduction">
                                      <span className="xcube-label">Introduction</span>
                                      <XcubeTinymce
                                        initialValue={this.props.state?.winner_email_introduction || ''}
                                        onChange={this.props.updateState}
                                        name={'winner_email_introduction'}
                                      />
                                    </div>
                                  </Row>
                                  <Row>
                                    <div className="offset-md-2 col-md-8 pt-md-3">
                                      <span className="xcube-label mb-md-1">E-mail body</span>
                                      <XcubeTinymce
                                        initialValue={this.props.state?.winner_email_body || ''}
                                        onChange={this.props.updateState}
                                        name={'winner_email_body'}
                                      />
                                    </div>
                                  </Row>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card>
                            <Card.Header>
                              <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                E-mail settings
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body>
                                <div className="col-md-12">
                                  <Row>
                                    <div className="offset-md-2 col-md-4">
                                      <XcubeInput
                                        label="Sender name"
                                        id={'sender_name'}
                                        placeholder="Company, LLC."
                                        type="text"
                                        name="general_sender_name"
                                        value={this.props.state?.general_sender_name || ''}
                                        onChange={this.props.updateState}
                                      />
                                    </div>
                                    {/*<div className="col-md-4">*/}
                                    {/*  <XcubeInput*/}
                                    {/*    label="Reply-to email address"*/}
                                    {/*    id={'reply_email'}*/}
                                    {/*    placeholder="reply-to@mail.com"*/}
                                    {/*    type="text"*/}
                                    {/*    name="general_reply_to"*/}
                                    {/*    value={this.props.state?.general_reply_to || ''}*/}
                                    {/*    onChange={this.props.updateState}*/}
                                    {/*  />*/}
                                    {/*</div>*/}
                                  </Row>
                                  <Row>
                                    <div className="offset-md-2 col-md-4">
                                      <XcubeInputFile
                                        name={'winner_email_background'}
                                        onChange={this.props.updateState}
                                        previewImg={this.props.state?.winner_email_background}
                                        label={'Winner Email Background'}
                                        id={'winner_email_background'}
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <XcubeInputFile
                                        name={'winner_email_prize_image'}
                                        onChange={this.props.updateState}
                                        previewImg={this.props.state?.winner_email_prize_image}
                                        label={'Winner Email Prize Image'}
                                        id={'winner_email_prize_image'}
                                      />
                                    </div>
                                  </Row>

                                  {/* <Row>
                                    <div className="offset-md-2 col-md-4">
                                      <XcubeCheckbox
                                        title="Notify the team members"
                                        id="notifyTheTeam"
                                        label="Forward the winner email to team members associated with the campaign."
                                        checked={this.props.state?.general_notify_team || false}
                                        onChange={this.props.updateState}
                                        name={'general_notify_team'}
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <XcubeCheckbox
                                        title="Notify the sponsor"
                                        id="notifyTheSponsor"
                                        label="Forward the winner email to the sponsor you specified in the prize settings."
                                        checked={this.props.state?.general_notify_sponsor || false}
                                        onChange={this.props.updateState}
                                        name={'general_notify_sponsor'}
                                      />
                                    </div>
                                  </Row> */}
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card>
                            <Card.Header>
                              <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                Prizes
                              </Accordion.Toggle>
                              <NewPrizeWizardModal onSubmit={this.onSubmit}/>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                              <Card.Body>
                                {/*{ this.state.prizes.length > 0 &&*/}
                                  <Table striped hover>
                                    <thead>
                                    <tr>
                                      <th>Prize</th>
                                      <th>Value</th>
                                      <th>Draw type</th>
                                      <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.generatePrizes()}
                                    </tbody>
                                  </Table>
                                {/*}*/}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
                <Card.Footer className="xcube-card-footer">
                  <Row>
                    <div className="col-md-9">
                      <div className="btn-group float-md-right" role="group" aria-label="Basic example">
                        <XcubeTextButton btnState="normal" text="< Brand" onClick={this.props.previousStep}/>
                        <XcubeFilledButton btnState="normal" text="Continue >" onClick={() => this.nextStep()}/>
                      </div>
                    </div>
                  </Row>
                </Card.Footer>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

export default CreateCampaignWizardPrizes;
