import {useState} from "react";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { toast } from 'react-toastify';
import { setDataService } from '../../../../../../shared/services/setDataService';
import { googleAnalyticsService } from '../../../../../../shared/services/googleAnalyticsService';
import { authenticationService } from '../../../../../../shared/services/authenticationService';
import XcubeFilledButton
  from '../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button';
import XcubeInput from '../../../../../../shared/components/xcube-input/xcube-input';
import Row from 'react-bootstrap/Row';
import "./insertAccountIdModal.scss";

function InsertAccountIdModal() {
  const companyId = authenticationService.currentUserValue.company_id;
  const [show, setShow] = useState(false);
  const [newAccountId, setNewAccountId] = useState({
    accountId: '',
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function handleChange(event) {
    const value = event.value;
    const name = event.name;
    setNewAccountId(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  function submitForm(){
    if( newAccountId.accountId.length > 0 ) {
      googleAnalyticsService.insertAccount(companyId, newAccountId.accountId).then((res) => {
        googleAnalyticsService.insertProperty(
          newAccountId.accountId,
          "sass.brame.ch",
          "Brame Default Property"
        ).then((resProperty) => {
            googleAnalyticsService.insertView(
              newAccountId.accountId,
              resProperty.result.id,
              "Brame Default Property View",
              "sass.brame.ch"
              ).then((resView) => {
              setDataService.updateCompanyAnalytics(companyId, {
                accountId: newAccountId.accountId,
                defaultPropertyId: resProperty.result.id,
                defaultViewId: resView.result.id,
                userRef: res.result.userRef.id
              }).then((resp) => {
                window.location.reload();
              });
            }).catch(onerror => console.log(onerror));
        }).catch(onerror => console.log(onerror));
      }).catch((onerror) => {
        toast.error(onerror.result.error.message);
      });
    }else{
      toast.error('You must fill in all required fields!');
    }
  }

  return (
    <div>
      <XcubeFilledButton
        btnState="normal"
        text="AccountId"
        onClick={handleShow}
        id="GAAccountId"
      />

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        id="newAccountIdModal"
        centered>
        <Modal.Header closeButton>
          <span>
              Google Analytics Account ID
          </span>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="col-md-6">
              <XcubeInput
                label="Account Id"
                placeholder="16222811"
                id={'accountId'}
                onChange={handleChange}
                value={newAccountId.accountId || ''}
                type="text"
                required={true}
                name={'accountId'}
              />
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <XcubeFilledButton
            btnState="normal"
            text="Save"
            onClick={submitForm}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default InsertAccountIdModal;
