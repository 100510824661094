import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import {getDataService} from "../../../../../shared/services/getDataService";
import cardImg from "../../../../create-campaign-wizard/components/create-campaign-wizard-template/assets/card-img.svg";
import "./use-cases-inspiration.scss";

class UseCasesInspiration extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardsData: []
        };
    }

    componentWillMount(){
        getDataService.getAllTemplates().then((resp) => {
            // console.log(resp.data.body);
            resp.data.body.forEach((data) => {
                data.img = cardImg;
                this.setState({
                    cardsData: [data]
                });
            });
        });
    }

    createCards = () => {
        let cards = [];

        this.state.cardsData.map((data) => {
            cards.push(
                <div className="col-md-3 mt-md-3" key={data.id}>
                    <div className='card'>
                        <img className="card-img-top" alt="" src={data.img}/>
                        <div className="card-footer">
                            {data.name}
                        </div>
                    </div>
                </div>
            )
        });
        return cards;
    };

    render() {
        return (
            <div id="use-cases-inspiration">
                <Container>
                    <Row>
                        <Col md={6}>
                            <h3>
                                use cases
                            </h3>
                            <p>
                                Check out our case studies to learn how Brame is helping brands take their content to the next level.
                            </p>
                        </Col>
                        <Col md={6}/>
                    </Row>
                    <Row className="mt-md-5">
                        {this.createCards()}
                    </Row>
                </Container>
            </div>
        );
    }
}

export default UseCasesInspiration;
