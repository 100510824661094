import React, {Component} from 'react';
import "./create-campaign-wizard-start.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card"
import startLogo from "./assets/image.svg";
import XcubeFilledButton from "../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import XcubeInput from "../../../../shared/components/xcube-input/xcube-input";
import XcubeDatetimePicker
    from '../../../../shared/components/xcube-datetime-picker/xcube-datetime-picker';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import XcubeSelect from '../../../../shared/components/xcube-select/xcube-select';
import { getDataService } from '../../../../shared/services/getDataService';

class CreateCampaignWizardStart extends Component {
    nextStep(){
        if(this.props.state.name.length > 0){
            return this.props.nextStep();
        }else{
            toast.error("You must fill in all required fields!");
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            client_companies: [],
            client_id: ''
        };
    }
    componentDidMount(): void {
        getDataService.getClientCompanies().then((resp) => {
            resp.data.body.clients.unshift({
                name: 'My Company',
                id: ''
            });
            this.setState({
                client_companies: resp.data.body.clients
            });
        });
    }

    updateState = (data) => {
        // console.log(data);
        this.setState({
            client_id: data.value
        });
        this.props.updateState(data);
    };

    render() {
        return (
            <div id="createCampaignWizardStart" className="mt-md-5 mt-2 pb-md-5">
                <Container>
                    <Row>
                       <div className="col-md-7 ml-md-auto mr-md-auto">
                           <Card>
                               <Card.Body className="mt-md-4">
                                   <img src={startLogo} alt=""/>
                                   <h3>
                                       Let's get started!
                                   </h3>
                                   <p>
                                       Enter the name for this campaign and describe it.
                                   </p>
                                   <small className="mb-md-3">
                                       Your customers won’t be able to see this.
                                   </small>
                                   <form action="">
                                       <XcubeInput
                                         label="Campaign name"
                                         onChange={this.props.updateState}
                                         name="name"
                                         value={this.props.state.name}
                                         required={true}
                                       />
                                       <XcubeInput
                                         label="Campaign description (optional)"
                                         onChange={this.props.updateState}
                                         name="description"
                                         value={this.props.state.description || ''}
                                       />
                                       {this.state.client_companies.length > 0 &&
                                        <XcubeSelect
                                           label="Company"
                                           options={this.state.client_companies}
                                           name="client_id"
                                           id="client_id"
                                           onChange={this.updateState}
                                           required={false}
                                           defaultValue={this.state.client_id}
                                        />
                                       }
                                       <Row>
                                           <Col md={6}>
                                               <XcubeDatetimePicker
                                                 label={'Start date'}
                                                 onChange={this.props.updateClearState}
                                                 name="start_date"
                                                 value={this.props.state.start_date}
                                                 minDate={this.props.state.start_date}
                                               />
                                           </Col>
                                           <Col md={6}>
                                               <XcubeDatetimePicker
                                                 label={'End date'}
                                                 onChange={this.props.updateClearState}
                                                 name="end_date"
                                                 value={this.props.state.end_date}
                                                 minDate={this.props.state.start_date}
                                               />
                                           </Col>
                                       </Row>
                                   </form>
                                   <XcubeFilledButton
                                     btnState="normal"
                                     text="Continue >"
                                     onClick={() => this.nextStep()}
                                   />
                               </Card.Body>
                           </Card>
                       </div>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default CreateCampaignWizardStart;
