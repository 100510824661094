import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import XcubeInput from "../../../shared/components/xcube-input/xcube-input";
import XcubeFilledButton from "../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import imac from "./assets/imac.png";
import XcubeSelect from "../../../shared/components/xcube-select/xcube-select";
import XcubeAppointmentPicker from "../../../shared/components/xcube-appointment-picker/xcube-appointment-picker";
import XcubeCheckbox from "../../../shared/components/xcube-checkbox/xcube-checkbox";
import XcubeTextarea from "../../../shared/components/xcube-textarea/xcube-textarea";
import {NavLink} from "react-router-dom";
import "./book-a-demo.scss";
import { toast } from 'react-toastify';

class BookADemo extends Component {
    constructor(props){
        super(props);
        this.state = {
            bookADemoForm: {
                email: '',
                name: '',
                phone: '',
                company: '',
                country: '',
                preferred_contact: '',
                notes: '',
                policy: false
            },
            countries: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount = () => {
        let countryList = [];
        let list = [];
        list = [ "United States", "Switzerland", "Germany" ];
        for (var i = 0; i < list.length; i++) {
            countryList.push({
            name: list[i],
            id: list[i]
          });
        }
        this.setState({
          countries: countryList
        });

        window.scrollTo(0, 0);
    }

    handleChange(event) {
        let name = event.name;
        let value = event.type === 'checkbox' ? event.checked : event.value;
        this.setState(prevState => ({
            bookADemoForm: {
                ...prevState.bookADemoForm,
                [name]: value
            }
        }));
    }

    handleSubmit(event) {
        event.preventDefault();
        if (
          this.state.bookADemoForm.email !== '' &&
          this.state.bookADemoForm.name !== '' &&
          this.state.bookADemoForm.phone !== '' &&
          this.state.bookADemoForm.company !== '' &&
          this.state.bookADemoForm.country !== ''&&
          this.state.bookADemoForm.preferred_contact !== ''
        ) {
            if (
                this.state.bookADemoForm.policy !== false
              ) {
                  /*authenticationService.login({email: this.state.loginForm.email, password: this.state.loginForm.password}).then((res) => {
                      window.location.reload();
                  }).catch((onerror) => {
                      toast.error("Invalid credentials!");
                  });*/
                  toast.info("All ok!");
            }else{
                toast.error("You must agree to our privacy policy!");
            }
        }else{
            toast.error("You must fill in all required fields!");
        }
    }

    render() {
        const policyLabel = ( <div> <span>We will keep the data you provide here safe and will not share it with other companies and individuals. <NavLink activeClassName='active' className='nav-link' to='/privacy-policy' target='_blank'>Learn more</NavLink> about how we deal with data.</span> </div> )

        return (
            <div id="book-a-demo">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-7 hero-text">
                            <Row>
                                <div className="col-md-12">
                                    <h1>
                                        See why Brame<br/> is the best.
                                    </h1>
                                    <p className="col-md-10">
                                        Get a live tour of the Brame platform! Explore available game concepts, see an overview of key features, see some example campaigns with one of our gamification advisors, and get expert advice on suitable game concepts that match your goals.
                                    </p>
                                </div>
                            </Row>
                            <Row className="form-row">
                                <div className="col-md-10">
                                    <form onSubmit={this.handleSubmit}>
                                        <XcubeInput label="Your name" onChange={this.handleChange} value={this.state.bookADemoForm.name || ''} type="text" name="name" required={true}/>
                                        <XcubeInput label="Phone" onChange={this.handleChange} value={this.state.bookADemoForm.phone || ''} type="number" name="phone" required={true}/>
                                        <XcubeInput label="E-mail" onChange={this.handleChange} value={this.state.bookADemoForm.email || ''} type="email" name="email" required={true}/>
                                        <XcubeInput label="Company" onChange={this.handleChange} value={this.state.bookADemoForm.company || ''} type="text" name="company" required={true}/>
                                        <XcubeSelect label="Country" onChange={this.handleChange} value={this.state.bookADemoForm.country} defaultValue="Select your country" options={this.state.countries} name="country" errorName="country" required={true}/>
                                        <XcubeAppointmentPicker label="Preferred contact" onChange={this.handleChange} name="preferred_contact" required={true}/>
                                        <XcubeTextarea label="Notes" onChange={this.handleChange} value={this.state.bookADemoForm.notes || ''} name="notes" required={false}/>
                                        <XcubeCheckbox onChange={this.handleChange} title="Accept the Brame Privacy Policy" label={policyLabel} type="checkbox" name="policy" id="policy"/>
                                        <XcubeFilledButton text="Book a demo" btnState="normal" type="submit"/>
                                    </form>
                                </div>
                            </Row>
                        </div>
                        <div className="col-md-5 d-mob-none">
                            <img src={imac} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BookADemo;
