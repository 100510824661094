import React, {Component} from 'react';
import {
    Route,
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom';
import PublicNavbar from "./components/navbar/public-navbar";
import "./public-pages.scss";
import Home from "./home/home";
import PublicFooter from "./components/footer/public-footer";
import Login from "./login/login";
import AboutPage from "./about/about-page";
import WhyBrame from "./why-brame/why-brame";
import Inspiration from "./inspiration/inspiration";
import BookADemo from "./book-a-demo/book-a-demo";
import Registration from './registration/registration';

class PublicPages extends Component {
    render() {
        return (
            <div id="public-pages">
                <div className="container-fluid p-0">
                    <Router>
                        <PublicNavbar/>
                        <Switch>
                            <Route path='/' exact component={Login} />
                            <Route path='/login' component={Login} />
                            <Route path='/about-us' component={AboutPage} />
                            <Route path='/why-brame' component={WhyBrame} />
                            <Route path='/inspiration' component={Inspiration} />
                            <Route path='/book-a-demo' component={BookADemo} />
                            <Route path='/register' component={Registration} />
                        </Switch>
                        <PublicFooter/>
                    </Router>
                </div>
            </div>
        );
    }
}

export default PublicPages;
