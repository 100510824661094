import React, {Component} from 'react';
// import CampaignGeneral from "../../../dashboard-campaign/components/campaign-general/campaign-general";
import {
    Route,
    NavLink,
    Switch,
} from 'react-router-dom';
import DashboardAccountGeneral from "../dashboard-account-general/dashboard-account-general";
import DashboardAccountTeam from "../dashboard-account-team/dashboard-account-team";
import DashboardAccountCompanies from "../dashboard-account-companies/dashboard-account-companies";
import DashboardAccountPlanBilling from "../dashboard-account-plan-billing/dashboard-account-plan-billing";
import { Nav, Dropdown } from "react-bootstrap";
import {Link} from 'react-router-dom'

class DashboardAccountsNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: 'Account'
        };
    }

    changePageTitle = (pageTitle) => {
        this.setState({
            pageTitle: pageTitle
        });
    };
    render() {

        return (
            <div id="campaign-navbar">
                {/* <p className="page-title">{this.state.pageTitle}</p> */}


                <Nav className="dropdownLinks mb-2">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                {this.state.pageTitle}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as="span">
                    {" "}
                    <Link to="/account/general">General</Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="span">
                    {" "}
                    <Link to="/account/plan-billing">Plan & billing</Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="span">
                    {" "}
                    <Link to="/account/team">Team</Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="span">
                    {" "}
                    <Link to="/account/companies">Companies</Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>


                <ul className="stats nav nav-tabs justify-content-center">
                    <li className="nav-item">
                        <NavLink activeClassName="active" className="nav-link" to="/account/general">General</NavLink>
                    </li>
                    {/*<li className="nav-item">*/}
                    {/*    <NavLink activeClassName="active" className="nav-link" to="/account/plan-billing">Plan & billing</NavLink>*/}
                    {/*</li>*/}
                    {/*<li className="nav-item">*/}
                    {/*    <NavLink activeClassName="active" className="nav-link" to="/account/team">Team</NavLink>*/}
                    {/*</li>*/}
                    {/*<li className="nav-item">*/}
                    {/*    <NavLink activeClassName="active" className="nav-link" to="/account/companies">Companies</NavLink>*/}
                    {/*</li>*/}
                </ul>
                <Switch>
                    <Route path="/account" exact={true} render={(props) => <DashboardAccountGeneral {...props} changeTitle={this.changePageTitle} />} />
                    <Route path="/account/general" render={(props) => <DashboardAccountGeneral {...props} changeTitle={this.changePageTitle} />} />
                    <Route path="/account/plan-billing" render={(props) => <DashboardAccountPlanBilling {...props} changeTitle={this.changePageTitle} />} />
                    <Route path="/account/team" render={(props) => <DashboardAccountTeam {...props} changeTitle={this.changePageTitle} />} />
                    <Route path="/account/companies" render={(props) => <DashboardAccountCompanies {...props} changeTitle={this.changePageTitle} />} />
                </Switch>
            </div>
        );
    }
}

export default DashboardAccountsNavbar;
