import React, {Component} from 'react';
import Hero from "./components/hero/hero";
import Intro from "./components/intro/intro";
import "./why-brame.scss";
import Engagement from "./components/engagement/engagement";
import Interactive from "./components/interactive/interactive";
import GetOnTop from "./components/get-on-top/get-on-top";
import Action from "./components/action/action";

class WhyBrame extends Component {
    render() {
        return (
            <div id="why-brame-page">
                <Hero/>
                <Intro/>
                <Engagement/>
                <Interactive/>
                <GetOnTop/>
                <Action/>
            </div>
        );
    }
}

export default WhyBrame;