import {useState} from "react";
import Modal from "react-bootstrap/Modal";
import React from "react";
import "./new-user.scss";
import XcubeFilledButton
  from '../../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button';
import XcubeInput from '../../../../../../../shared/components/xcube-input/xcube-input';
import Row from 'react-bootstrap/Row';
import { authenticationService } from '../../../../../../../shared/services/authenticationService';
import XcubeSelect from '../../../../../../../shared/components/xcube-select/xcube-select';
import Container from "react-bootstrap/Container";
import {setDataService} from "../../../../../../../shared/services/setDataService";
import { toast } from "react-toastify";
import XcubeInputFile from "../../../../../../../shared/components/xcube-input-file/xcube-input-file";
import editImg from "../../../../dashboard-campaign/components/campaign-game/assets/edit.svg";


function EditUser(props) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({
    first_name: props.data.first_name,
    last_name: props.data.last_name,
    group_id: props.data.groups[0] ? props.data.groups[0].id : '',
    company_id: authenticationService.currentUserValue.company_id,
    user_image: props.data.user_image
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function handleChange(event) {
    // console.log(event);
    const value = event.value;
    const name = event.name;
    setData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  function submitForm(){
    if(
      data.first_name.length > 0 &&
      data.last_name.length > 0 &&
      data.user_image !== '' &&
      data.group_id.length > 0
    ){
      data.id = props.data.id;
      setDataService.editEmployee(data).then((resp) => {
        if(resp.data.message === 'success'){
          handleClose();
          window.location.reload();
        }
      });
    }else{
      toast.error('You must fill in all required fields!');
    }
  }

  return (
    <div className="edit-user">
      <a className="edit-user-btn" onClick={handleShow}><img src={editImg} />Edit</a>

      <Modal show={show} onHide={handleClose}
             aria-labelledby="contained-modal-title-vcenter"
             id="new-user"
             centered
             >
        <Modal.Header closeButton>
          New User
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <div className="col-md-12">
                <XcubeInput
                  label="First name"
                  placeholder="First name"
                  id="first_name"
                  type="text"
                  name="first_name"
                  value={data?.first_name || ''}
                  onChange={handleChange}
                />
              </div>
            </Row>
            <Row>
              <div className="col-md-12">
                <XcubeInput
                  label="Last name"
                  placeholder="Last name"
                  id="last_name"
                  type="text"
                  name="last_name"
                  value={data?.last_name || ''}
                  onChange={handleChange}
                />
              </div>
            </Row>
            <Row>
              <div className="col-md-12">
                <XcubeInputFile
                  name={'user_image'}
                  onChange={handleChange}
                  previewImg={data?.user_image}
                  label={'Avatar image'}
                  id={'user_image'}
                />
              </div>
            </Row>
            <Row>
              <div className="col-md-12">
                <XcubeSelect
                  label="Group"
                  options={props.groups}
                  name="group_id"
                  id="group_id"
                  onChange={handleChange}
                  required={false}
                  defaultValue={data?.group_id || ''}
                />
              </div>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
        <XcubeFilledButton
            btnState="normal"
            text="Save"
            onClick={submitForm}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditUser;
