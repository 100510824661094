import React, {Component} from 'react';
import "./create-campaign-wizard-info.scss";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import startLogo from "../create-campaign-wizard-info/assets/logo2.svg";
import XcubeFilledButton from "../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import XcubeTextButton from "../../../../shared/components/xcube-buttons/xcube-text-button/xcube-text-button";
import Container from "react-bootstrap/Container";
import XcubeInput from "../../../../shared/components/xcube-input/xcube-input";
import XcubeInputFile from "../../../../shared/components/xcube-input-file/xcube-input-file";

class CreateCampaignWizardInfo extends Component {
    updateState;
    constructor(props) {
        super(props);

        this.updateState = function (data, name){
            props.updateState(
                {
                    name: name,
                    value: data
                }
            );
        }
    }
    render() {
        return (
            <div id="createCampaignWizardInfo" className="mt-md-5 pb-md-5">
                <Container>
                    <Row>
                        <div className="col-md-12">
                            <Card className="mt-2">
                                <Card.Header>
                                    <div className="xcube-card-header mt-md-3">
                                        <Row>
                                            <div className="col-sm-2 
                                            col-md-2 col-lg-2">
                                                <img src={startLogo} alt=""/>
                                            </div>
                                            <div className="seo col-md-6 col-lg-6 ml-md-4">
                                                <h3>
                                                    Social media & SEO
                                                </h3>
                                                <p>
                                                    Optimize your campaign for sharing and social presence.
                                                </p>
                                                <small>
                                                    This will be used to represent your campaign in web search results and when the page is shared via the social media.
                                                    You can also add your social media accounts here if you want to list them in the Connect section.
                                                </small>
                                            </div>
                                        </Row>
                                    </div>
                                </Card.Header>
                                <Card.Body className="mt-md-4 p-0">
                                    <div className="xcube-card-body">
                                        <Row>
                                            <div className="col-md-12">
                                                <form action="">
                                                    <Row>
                                                        <div className="col-md-4">
                                                            <XcubeInput
                                                              label="Title"
                                                              placeholder="My awesome campaign"
                                                              onChange={this.props.updateState}
                                                              name="seo_title"
                                                              value={this.props.state.seo_title}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <XcubeInput
                                                              label="Description"
                                                              placeholder="Join the fun!"
                                                              onChange={this.props.updateState}
                                                              name="seo_description"
                                                              value={this.props.state.seo_description}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <XcubeInputFile
                                                              name="seo_image"
                                                              onChange={this.props.updateState}
                                                              previewImg={this.props.state.seo_image}
                                                              label={'Seo image'}
                                                              id={'seo_image'}
                                                            />
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div className="col-md-4">
                                                            <XcubeInput
                                                              label="Instagram"
                                                              placeholder="@brand"
                                                              onChange={this.props.updateState}
                                                              name="instagram"
                                                              value={this.props.state.instagram}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <XcubeInput
                                                              label="Facebook"
                                                              placeholder="facebook.com/brand"
                                                              onChange={this.props.updateState}
                                                              name="facebook"
                                                              value={this.props.state.facebook}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <XcubeInput
                                                              label="Twitter"
                                                              placeholder="twitter.com/brand"
                                                              onChange={this.props.updateState}
                                                              name="twitter"
                                                              value={this.props.state.twitter}
                                                            />
                                                        </div>
                                                    </Row>
                                                </form>
                                            </div>
                                        </Row>
                                    </div>
                                </Card.Body>
                                <Card.Footer className="xcube-card-footer">
                                    <Row>
                                        <div className="col-md-12">
                                            <div className="btn-group float-md-right"
                                                 role="group"
                                                 aria-label="Basic example">
                                                <XcubeTextButton
                                                  btnState="normal"
                                                  text="< Brand"
                                                  onClick={this.props.previousStep}
                                                />
                                                <XcubeFilledButton
                                                  btnState="normal"
                                                  text="Continue >"
                                                  onClick={this.props.nextStep}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default CreateCampaignWizardInfo;
