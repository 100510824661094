import React, { useState } from 'react';
import {
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import XcubeFilledButton
  from '../../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button';
import XcubeInput from '../../../../../../../shared/components/xcube-input/xcube-input';
import XcubeInputFile from '../../../../../../../shared/components/xcube-input-file/xcube-input-file';
import XcubeDatePicker from '../../../../../../../shared/components/xcube-date-picker/xcube-date-picker';
import './new-prize-modal.scss';
import editImg from '../../campaign-game/assets/edit.svg';
import Row from 'react-bootstrap/Row';
import { setDataService } from '../../../../../../../shared/services/setDataService';

function EditPrizeModal(data) {
  const [show, setShow] = useState(false);
  const [newPrize, setNewPrize] = useState({
    campaign_id: useParams().id,
    name: data.prize.name,
    description: data.prize.description,
    value: data.prize.value,
    image: data.prize.image.id,
    draw_date: data.prize.draw_date,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function handleChange(event) {
    let value = event.value;
    let name = event.name;
    setNewPrize((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    data.prize[name] = value;
  }

  function submitForm() {
    if (
      newPrize.name.length > 0
      && newPrize.description.length
      && newPrize.value.length
      && newPrize.image
      !== newPrize.draw_date.length > 0
    ) {
      setDataService.updatePrize(newPrize, data.prize.id).then((resp) => {
        if (resp.data.message === 'success') {
          window.location.reload();
        }
      });
    } else {
      toast.error('You must fill in all required fields!');
    }
  }

  return (
    <div id="edit-prize-modal">
      <a onClick={() => handleShow()}><img src={editImg} />Edit</a>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        id="newPrizeModal"
        centered
      >
        <Modal.Header closeButton>
          <span>
            Edit prize
          </span>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="col-md-6">
              <XcubeInput
                label="Name"
                placeholder="Prize name"
                id={'name'}
                onChange={handleChange}
                value={data.prize.name || ''}
                type="text"
                required={true}
                name={'name'}
              />
            </div>
            <div className="col-md-6">
              <XcubeInput
                label="Number of prizes"
                placeholder="20"
                id={'value'}
                onChange={handleChange}
                value={data.prize.value || ''}
                type="text"
                required={true}
                name={'value'}
              />
            </div>
          </Row>
          <Row>
            <div className="col-md-12">
              <XcubeInput
                label="Description"
                placeholder="Prize description"
                id={'description'}
                onChange={handleChange}
                value={data.prize.description || ''}
                type="text"
                required={true}
                name={'description'}
              />
            </div>
          </Row>
          <Row>
            <div className="col-md-6">
              <div className="datePicker">
                <XcubeDatePicker
                  label="Prize available from"
                  onChange={handleChange}
                  value={data.prize.draw_date}
                  date={data.prize.draw_date}
                  name={'draw_date'}
                />
              </div>
            </div>
            <div className="col-md-6">
              <XcubeInputFile
                name={'image'}
                onChange={handleChange}
                previewImg={data.prize.image.thumbnail_url}
                label={'Attachment'}
                id={'prize_image'}
              />
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <XcubeFilledButton
             btnState="normal"
             text="Save"
             onClick={submitForm}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditPrizeModal;
