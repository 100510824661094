import React, {Component} from 'react';
import "./xcube-analytics-visitors.scss";
import visitor from './assets/wiz-visitor.png';

class XcubeAnalyticsVisitorsOld extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalVisitors: '0'
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.getAnalytics();
        }
    }

    getAnalytics = () => {
        this.setState({
            totalVisitors: this.props.data
        });
    };

    componentDidMount() {
        this.getAnalytics();
    }

    render() {
        return (
            <div id="dashboard-visitors">
                <div className="card">
                    <div className="card-title-visitors mt-3 ml-3">Visitors</div>
                    <div className="row no-gutters">
                        <div className="col-7">
                            <div className="card-body pt-4">
                                <p className="card-text"><strong className="strongVisitors">{this.state.totalVisitors}</strong></p>
                                <p className="card-text"><small>saw the game</small></p>
                            </div>
                        </div>
                        <div className="col-4 text-right pr-4 pb-2 pt-2">
                           <img src={visitor} className="visitorImg" alt="" style={{width: '100px'}}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default XcubeAnalyticsVisitorsOld;
