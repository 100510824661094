import React, {Component} from 'react';
import "./culture.scss";
import Row from "react-bootstrap/Row";

class Culture extends Component {
    render() {
        return (
            <div id="culture-about-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 hero-text">
                            <h3>our culture</h3>
                            <p>
                                We are committed to delivering the best experience at all times, so you can focus on doing what you do best. We hold these values with utmost respect.
                            </p>
                        </div>
                    </div>
                    <Row className="card-culture mt-md-3">
                        <div className="col-md-4">
                            <p>1</p>
                            <h5>We exceed expectations</h5>
                        </div>
                        <div className="col-md-4 card-text-center">
                            <p>2</p>
                            <h5>We respect every client</h5>
                        </div>
                        <div className="col-md-4 card-text-right">
                            <p>3</p>
                            <h5>We find a way</h5>
                        </div>
                    </Row>
                    <Row className="card-culture pb-md-5">
                        <div className="col-md-4 mt-md-5">
                            <p>4</p>
                            <h5>We improve constantly</h5>
                        </div>
                        <div className="col-md-4 card-text-center mt-md-5">
                            <p>5</p>
                            <h5>We break new grounds</h5>
                        </div>
                        <div className="col-md-4 card-text-right mt-md-5">
                            <p>6</p>
                            <h5>We want to delight you</h5>
                        </div>
                    </Row>

                </div>
            </div>
        );
    }
}

export default Culture;