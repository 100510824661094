import React, {Component} from 'react';
import DashboardAccountsNavbar from "./components/dashboard-account-navbar/dashboard-accounts-navbar";

class DashboardAccounts extends Component {
    render() {
        return (
            <div id="dashboard-accounts">
                <DashboardAccountsNavbar/>
            </div>
        );
    }
}

export default DashboardAccounts;
