import React, {Component} from 'react';
import image from "./assets/intro.png";
import "./intro.scss";
import Row from "react-bootstrap/Row";
class Intro extends Component {
    render() {
        return (
            <div id="about-page-intro">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 intro-text">
                            <Row className="intro-text-white">
                                <div className="col-md-12">
                                    <h3>
                                        ABOUT US
                                    </h3>
                                    <p>
                                        As a team of innovative entrepreneurs with experience in different marketing fields, we asked ourselves how brands can distinguish themselves with more than just the price? A way how they can interact with their communities and send out a desired message in an entertaining way? So we needed a real GAME changer!
                                    </p>
                                </div>
                            </Row>
                            <Row className="mt-md-5">
                                <div className="col-md-12">
                                    <h3>
                                        We waNt to change <br/>
                                        the marketing <br/> landscape
                                    </h3>
                                    <p>
                                        This concept of a gamification software applied with our infinite passion for marketing, the drive to find innovative unconventional ways to break the cluster and the satisfaction that results after a successful marketing campaign drives BRAME every day to reach new heights!
                                    </p>
                                </div>
                            </Row>
                        </div>
                        <div className="col-md-7 image-col">
                            <img src={image} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Intro;