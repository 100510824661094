import {useState} from "react";
import Modal from "react-bootstrap/Modal";
import React from "react";

import Row from "react-bootstrap/Row";
import share from './assets/share.svg';
import "./xcube-share-modal.scss";
import frame from "./assets/frame.svg";
// import link from "./assets/link.svg";

function XcubeShareModal(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <a className="share" onClick={handleShow}>
        <img src={share} alt=""/> {props.linkText}
      </a>

      <Modal show={show} onHide={handleClose}
             aria-labelledby="contained-modal-title-vcenter" id="linkDialog"
             centered>
        <Modal.Header closeButton>
          <div>
            <Row>
              <div className="col-md-10">
                <span>
                    Campaign link
                </span>
              </div>
            </Row>
            <Row>
              <div className="col-md-8">
                <small>
                  You can access the campaign using the link
                  provided below.
                </small>
              </div>
            </Row>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="col-md-12">
              {/* <img src={props.qrCode} alt=""/> */}
              <i className="icn-share"></i>
              <div className="input-field">
                <input type="text" value={props.linkShare} readOnly/>
              </div>
              {/*<p>click to copy link</p>*/}
            </div>
          </Row>
        </Modal.Body>

      </Modal>
    </div>
  );
}

export default XcubeShareModal;
