import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import statistics from '../../assets/statistics.svg';
import "./statsticsIntegration.scss";

class StatisticsIntegration extends Component {
    render() {
        return (
            <div id="statisticsIntegration">
                <Row>
                    <div className="col-md-3">
                        <div className="card" onClick={() => {this.props.setStep('step3')}}>
                            <div className="row no-gutters">
                                <div className="col-md-6 card-img-col">
                                    <img src={statistics} className="card-img" alt=""/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <h5 className="card-title">Google Analytics</h5>
                                        <p className="card-text">Integrate Google Analytics</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        );
    }
}

export default StatisticsIntegration;