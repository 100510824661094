import React, {Component} from 'react';
import image from "./assets/intro.png";
import "./intro.scss";
import XcubeFilledButton from "../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
class Intro extends Component {
    render() {
        return (
            <div id="intro-home-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 intro-text">
                            <h3>
                                Enter a new era <br/>
                                of marketing <br/>
                                with engaging content.
                            </h3>
                            <p>
                                Nowadays, consumers get bombarded with so many direct and indirect marketing techniques that they have developed a filter that cancels out irrelevant distractions. Gamification is an innovative marketing tool that overcomes this filter.
                            </p>
                            <XcubeFilledButton text="What is gamification?" btnState="normal"/>
                        </div>
                        <div className="col-md-7 image-col">
                            <img src={image} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Intro;