import React, {Component} from 'react';
import "./xcube-input-file-pdf.scss";
import previewImg from "./assets/upload.png";
import { setDataService } from '../../services/setDataService';

class XcubeInputFilePdf extends Component {
    pdf;
    constructor(props) {
        super(props);
        this.state = {
          pdf: ''
        }
    }

    handleChange = function(e){
        let reader = new FileReader();
        let _this = this;
        reader.readAsDataURL(e[0]);
        reader.onloadend = () => {
            _this.pdf = reader.result;
            // console.log(_this.pdf);
            setDataService.uploadPdf({pdf: _this.pdf}).then((result) => {
               if(result.data.message === 'success') {
                 _this.props.onChange({'value': result.data.body, 'name': _this.props.name});
                //  _this.setState({
                //    pdf: result.data.body.thumbnail_url
                //  });
                // console.log(result);
               }
            });
        };
    };

    render() {
        return (
            <div id="xCubeInputFilePdf">
                <label className="xcube-input-label">{this.props.label}</label>
                <label htmlFor={this.props.id} className="xcube-file-upload-pdf">
                    {this.props.label}
                    <img src={this.state.image ? this.state.image : this.props.previewImg ? this.props.previewImg : previewImg} alt=""/>
                </label>
                <input id={this.props.id} type="file" name={this.props.name} onChange={ (e) => this.handleChange(e.target.files) }/>
            </div>
        );
    }
}

export default XcubeInputFilePdf;
