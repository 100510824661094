import React, {useState} from 'react';
import SweetAlert from 'react-bootstrap-sweetalert/dist';
import "./xcube-sweetalert.scss";
import store from "../../../redux/store";
import {displaySweetAlert} from "../../../redux/actions";

function XcubeSweetAlert(props) {
    const [sweetAlert, setSweetAlert] = useState(false);
    store.subscribe(() => {
        let localStore = store.getState();
        setSweetAlert(localStore.sweetAlert)
    });

    const alertResponse = (response) => {
        switch (sweetAlert.type) {
            case 'removeCampaign':
                return response === 'confirm' ? removeCampaign() : store.dispatch(displaySweetAlert({
                    value: {
                        display: false,
                        id: '',
                        type: ''
                    }
                }));
            default :
                store.dispatch(displaySweetAlert({
                    value: {
                        display: false,
                        id: '',
                        type: ''
                    }
                }));
        }
    }

    const removeCampaign = () => {
        console.log('remove campaign');
    }

    return (
        <div id="xcube-sweetalert">
            <SweetAlert
                title={props.title}
                showCancel
                confirmBtnText={props.confirmBtnText}
                confirmBtnBsStyle={props.confirmBtnBsStyle}
                focusCancelBtn
                onConfirm={() => alertResponse('confirm')}
                onCancel={() => alertResponse('cancel')}
                cancelBtnCssClass={"alert_cancel_btn"}
                confirmBtnCssClass={"alert_confirm_btn"}
                show={sweetAlert.display}
            >
                {props.text}
            </SweetAlert>
        </div>
    );
}

export default XcubeSweetAlert;
