import React, { Component } from 'react';
import {
  Route, Switch,
} from 'react-router-dom';
import CampaignNavbar from './components/campaign-navbar/campaign-navbar';
import Campaigns from './components/campaigns/campaigns';

class DashboardCampaign extends Component {
  render() {
    return (
      <div id="dashboardCampaign">
        <Switch>
          <Route path="/campaigns" exact render={(props) => <Campaigns {...props} changeTitle={this.changePageTitle} />} />
          <Route path="/campaigns" render={(props) => <CampaignNavbar {...props} changeTitle={this.changePageTitle} />} />
        </Switch>
      </div>
    );
  }
}

export default DashboardCampaign;
