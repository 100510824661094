import React, { Component} from 'react';
import {Row, Col, Table} from 'react-bootstrap'
import "./xcube-analytics-cta-details.scss"
class XcubeAnalyticsCtaDetails extends Component {
  renderTableRows() {
    let rows = [];
    if(this.props.data?.length > 0) {
      this.props.data.slice(0,10).map((cta) => {
        rows.push(
          <tr key={cta.name}>
            <td><span className='bullet' style={{backgroundColor: `${this.getRandomColor()}`}}></span> {cta.name}</td>
            <td style={{textAlign: 'center'}}>{cta.count}</td>
          </tr>
        )
      })
    }
    return rows
  };


  getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    return (
      <Row className='bestTimeDetails align-items-center pt-3'>
        <Col md={12} lg={12} className="text-center mb-5">
          {this.props.data.length > 0
            ?
            <Table className='playersTime text-left' hover>
              <thead style={{borderBottom: 'none'}} >
              <tr>
                {<th>Name</th>}
                {<th>Clicks</th>}
              </tr>
              </thead>
              <tbody>
              {this.renderTableRows()}
              </tbody>
            </Table>
            : <p>No info available</p>
          }
        </Col>
      </Row>
    )
  }
}

export default XcubeAnalyticsCtaDetails;
