import {useState} from "react";
import Modal from "react-bootstrap/Modal";
import React from "react";
import XcubeFilledButton
  from "../../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import "./new-payment-modal.scss";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { setDataService } from '../../../../../../../shared/services/setDataService';
function CloseAccount() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const closeAccount = () => {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    setDataService.closeAccount(user.company_id)
      .then((resp) => {
        if (resp.data.message === 'success') {
          localStorage.clear();
          window.location.reload();
        }
      });
  }

  return (
    <div>
      <XcubeFilledButton text="Close account" btnState="negative" onClick={handleShow}/>

      <Modal show={show} onHide={handleClose}
             aria-labelledby="contained-modal-title-vcenter" id="closeAccount"
             centered>
        <Modal.Header closeButton>
          <span>
              Are you sure?
          </span>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <span>
            This action will permanently remove your account & campaigns from Brame.
          </span>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <XcubeFilledButton
            btnState="negative"
            text="Yes, close account"
            onClick={() => closeAccount()}
          />
          <XcubeFilledButton
            btnState="normal"
            text="No"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CloseAccount;
