import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import "./dashboard-account-companies.scss";
import { getDataService } from '../../../../../../shared/services/getDataService';
import AddCompanyModal from './components/add-company-modal';
import SeeAllCampaigns from './components/see-all-campaigns';
class DashboardAccountCompanies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companies: ''
        };
    }

    componentDidMount() {
        this.props.changeTitle('Account - Companies');
        getDataService.getClientCompanies().then((companies) => {
           this.setState({
               companies: companies.data.body
           });
        });
    }

    generateCompanies = () => {
      let companies = [];
      if(this.state.companies !== ''){
        this.state.companies.clients.forEach((comp, i) => {
          companies.push(
            <div className="col-md-3 mt-md-4" key={comp.name + i}>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">{comp.name}</h5>
                  <p className="card-text">{comp.live_campaigns_count} campaigns live</p>
                  <SeeAllCampaigns id={comp.id}/>
                </div>
                <img className="card-img-top" src={comp.logo.url} alt="Company logo"/>
              </div>
            </div>
          );
        });
      }
      return companies;
    };

    render() {
        return (
            <div id="dashboardAccountCompanies" className="pt-md-5">
                <div className="container">
                    <Row>
                        <div className="col-md-12 section-head">
                            <h1>
                                Companies
                            </h1>
                            <span>
                                Your account is publishing campaigns for the following companies.
                            </span>
                            <p>
                                You have {this.state.companies.total_campaigns} live campaigns.
                            </p>
                        </div>
                    </Row>
                    <Row>
                        {this.generateCompanies()}
                        <div className="col-md-3 mt-md-4">
                          <AddCompanyModal />
                       </div>
                    </Row>
                </div>
            </div>
        );
    }
}

export default DashboardAccountCompanies;
