import {useState} from "react";
import Modal from "react-bootstrap/Modal";
import React from "react";
import XcubeFilledButton
    from "../../../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button";
import Row from "react-bootstrap/Row";
import "./integrationDialog.scss";
import MainIntegration from "./components/mainIntegration/mainIntegration";
import EmailIntegration from "./components/emailIntegration/emailIntegration";
import StatisticsIntegration from "./components/statisticsIntegration/statisticsIntegration";
import NotificationsIntegration from "./components/notificationsIntegration/notificationsIntegration";
import Step3 from "./components/step3/step3";

function IntegrationDialog(props) {
    const [show, setShow] = useState(false);
    const [step, setStep] = useState('main');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let showComponent;
    let activeStep;

    switch (step) {
        case 'main':
            showComponent = <MainIntegration setStep={setStep}/>;
            activeStep = 1;
            break;
        case 'email-integration':
            showComponent = <EmailIntegration setStep={setStep}/>;
            activeStep = 2;
            break;
        case 'statistics-integration':
            showComponent = <StatisticsIntegration setStep={setStep}/>;
            activeStep = 2;
            break;
        case 'notifications-integration':
            showComponent = <NotificationsIntegration setStep={setStep}/>;
            activeStep = 2;
            break;
        case 'step3':
            showComponent = <Step3 setStep={setStep}/>;
            activeStep = 3;
            break;
        default:
            break;
    }

    return (
        <div>
            <XcubeFilledButton btnState="normal" text="New integration" onClick={handleShow}/>

            <Modal show={show} onHide={handleClose}
                   aria-labelledby="contained-modal-title-vcenter" id="integrationDialog"
                   centered>
                <Modal.Header closeButton>
                    <div>
                        <Row>
                            <div className="col-md-10">
                            <span>
                                New integration
                            </span>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-md-8">
                                <small>
                                    Click on the tile to pick the integration module
                                    you would like to add to your campaign.
                                </small>
                            </div>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {showComponent}
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button className={`step-btn ${activeStep === 1 ? "active " : ""}`} onClick={() => {setStep('main')}}>
                            <div>
                                <small>step</small>
                                <span>1</span>
                            </div>
                        </button>
                        <button className={`step-btn ${activeStep === 2 ? "active " : ""}`}>
                            <div>
                                <small>step</small>
                                <span>2</span>
                            </div>
                        </button>
                        <button className={`step-btn ${activeStep === 3 ? "active " : ""}`}>
                            <div>
                                <small>step</small>
                                <span>3</span>
                            </div>
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default IntegrationDialog;