import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import './register-payment.scss';
import XcubeInput from '../../../../../shared/components/xcube-input/xcube-input';
import XcubeFilledButton
  from '../../../../../shared/components/xcube-buttons/xcube-filled-button/xcube-filled-button';
import XcubeSelect from '../../../../../shared/components/xcube-select/xcube-select';
import XcubeCheckbox from '../../../../../shared/components/xcube-checkbox/xcube-checkbox';
import PaymentPopup from './components/payment-popup';
import store from '../../../../../redux/store';
import { editRegistration } from '../../../../../redux/actions';
import { setDataService } from '../../../../../shared/services/setDataService';
import { toast } from 'react-toastify';

class RegisterPayment extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    store.subscribe(() => this.setState(store.getState()));
  }

  onInputChange(data){
    let name = data.name;
    let value = data.value;
    store.dispatch(editRegistration({name: name, value: value}));
  }

  register = () => {
    let data = store.getState();
        setDataService.setSignUpData(data.registrationForm).then((resp) => {
          if(resp.data.message === 'success'){
            localStorage.setItem('currentUser', JSON.stringify(resp.data.body));
            window.location.assign('/dashboard');
          } else if(resp.data.message === 'error'){
            toast.error(resp.data.body);
          }
        });
  }

  render() {
    return (
      <div id={'register-payment'}>
        <div className={'backgroundGreen'}>
          <div className="container-fluid">
            <Row>
              <div className="col-md-7 hero-text">
                <Row>
                  <div className="col-md-12">
                    <h1>
                      Glad to have you <br/> on board.
                    </h1>
                    <p>
                      You’ve made the right choice - Brame’s gamified approach to marketing will help you bring out the best of your creative skills and help you get more quality leads than ever before! Now, let’s get on with your registration.
                    </p>
                  </div>
                </Row>
              </div>
            </Row>
            <Row>
              <div className="col-md-6 section-head">
                <h1>
                  Basics
                </h1>
                <p>
                  Set up the basic account information.
                </p>
              </div>
            </Row>
            <Row>
              <div className="col-md-3">
                <XcubeInput
                  label="First Name"
                  placeholder="John"
                  id={'first_name'}
                  name={'first_name'}
                  type={'text'}
                  onChange={this.onInputChange}
                  value={this.state.registrationForm?.first_name}
                  required={true}/>
              </div>
              <div className="col-md-3">
                <XcubeInput
                  label="Last Name"
                  placeholder="Smith"
                  id={'last_name'}
                  name={'last_name'}
                  type={'text'}
                  onChange={this.onInputChange}
                  value={this.state.registrationForm?.last_name}
                  required={true}/>
              </div>
              <div className="col-md-3">
                <XcubeInput
                  label="E-mail"
                  placeholder="johnsmith@company.com"
                  id={'email'}
                  name={'email'}
                  type={'text'}
                  onChange={this.onInputChange}
                  value={this.state.registrationForm?.email}
                  required={true}/>
              </div>
              <div className="col-md-3">
                <XcubeInput
                  label="Password"
                  placeholder="********"
                  id={'password'}
                  name={'password'}
                  type={'password'}
                  onChange={this.onInputChange}
                  value={this.state.registrationForm?.password}
                  required={true}/>
              </div>
            </Row>
            <Row>
              <div className="col-md-12">
                <hr/>
              </div>
            </Row>
            <Row>
              <div className="col-md-6 section-head">
                <h1>
                  Profile
                </h1>
                <p>
                  Enter your full contact information here. This information can also be used to prefill certain sections and pages,
                  such as the Connect section or Terms of Service.
                </p>
              </div>
            </Row>
            <Row>
              <div className="col-md-4">
                <XcubeInput
                  label="Company"
                  placeholder="Company, LLC."
                  id={'company'}
                  name={'company'}
                  type={'text'}
                  onChange={this.onInputChange}
                  value={this.state.registrationForm?.company}
                  required={true}/>
              </div>
              <div className="col-md-4">
                <XcubeInput
                  label="Website"
                  placeholder="company.com"
                  id={'website'}
                  name={'website'}
                  type={'text'}
                  onChange={this.onInputChange}
                  value={this.state.registrationForm?.website}/>
              </div>
              <div className="col-md-4">
                <XcubeInput
                  label="Contact e-mail"
                  placeholder="johnsmith@company.com"
                  id={'contact_email'}
                  name={'contact_email'}
                  type={'email'}
                  onChange={this.onInputChange}
                  value={this.state.registrationForm?.contact_email}
                  required={true}/>
              </div>
            </Row>
            <Row>
              <div className="col-md-4">
                <XcubeInput
                  label="Address"
                  placeholder="24 Street"
                  id={'address'}
                  name={'address'}
                  type={'text'}
                  onChange={this.onInputChange}
                  value={this.state.registrationForm?.address}/>
              </div>
              <div className="col-md-4">
                <XcubeInput
                  label="City"
                  placeholder="City Name"
                  id={'city'}
                  name={'city'}
                  type={'text'}
                  onChange={this.onInputChange}
                  value={this.state.registrationForm?.city}/>
              </div>
              <div className="col-md-4">
                <XcubeInput
                  label="Zip Code"
                  placeholder="18000"
                  id={'zip_code'}
                  name={'zip_code'}
                  type={'text'}
                  onChange={this.onInputChange}
                  value={this.state.registrationForm?.zip_code}/>
              </div>
            </Row>
            <Row>
              <div className="col-md-4">
                <XcubeSelect
                  label="Country"
                  options={[
                    {id: '1', name: 'United States'},
                    {id: '2', name: 'Switzerland'},
                    {id: '3', name: 'Germany'},
                  ]}
                  onChange={(event) => this.onInputChange(event, 'company')}
                  id={'country'}
                  name={'country'}
                  errorName={'country'}
                  defaultValue={this.state.registrationForm?.country}
                />
              </div>
              <div className="col-md-4">
                <XcubeInput
                  label="Phone"
                  placeholder="+1-012-345-678"
                  id={'phone'}
                  name={'phone'}
                  type={'text'}
                  onChange={this.onInputChange}
                  value={this.state.registrationForm?.phone}/>
              </div>
            </Row>
            <Row>
              <div className="col-md-12">
                <hr/>
              </div>
            </Row>
            <Row>
              <div className="col-md-9 section-head">
                <h1>
                  Privacy & data
                </h1>
                <span>We value your trust and keep your data safe.</span>
                <p>
                  Your data is kept safe on our platform’s data storage units in compliance with General Data Protection Regulation directives.
                  Read our <a href="#">Terms & Conditions, Data Processing Agreement</a>  &  <a href="#">Privacy Policy</a> to find out more.
                </p>
              </div>
            </Row>
            <Row>
              <div className="col-md-3">
                <XcubeCheckbox
                  id="terms-of-use"
                  title="I accept the Brame terms of use"
                  label="Data supplier contract has been accepted."
                  onChange={this.onInputChange} />
              </div>
            </Row>
            <Row>
              <div className="col-md-12 mt-md-5 publish">
                <hr/>
                <div className="btn-group" role="group" aria-label="Basic example">
                  <XcubeFilledButton
                    text="< Plan"
                    btnState={'white'}
                    onClick={() => this.props.history.push('/register/plan')}/>
                     <XcubeFilledButton
                    text="Register"
                    btnState={'normal'}
                    onClick={() => this.register()}/>
                  {/* <PaymentPopup/> */}
                </div>
              </div>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterPayment;
