import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import EmailInsertModal
    from "../../../pages/dashboard/components/dashboard-campaign/components/campaign-emails/components/insert-modal";

class XcubeTinymce extends React.Component {
    handleEditorChange = (e) => {
        this.props.onChange({name: this.props.name, value: e.target.getContent()});
    };

    render() {
        return (
            <div className="tinymce-editor">
                <EmailInsertModal/>
                <Editor
                    apiKey="lzes347n8k3012k6ydjhyob2qy2q0uwxl21lmb9snnpf4ck1"
                    initialValue={this.props.initialValue}
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                            ' bold italic | formatselect | alignleft aligncenter alignright alignjustify | \
                             undo redo | Insert | forecolor backcolor',

                        setup: (editor) => {
                            editor.ui.registry.addButton('Insert', {
                                text: 'Insert',
                                onAction: function () {
                                    // editor.insertContent('&nbsp;<strong>It\'s my button!</strong>&nbsp;');
                                    document.getElementById('emailInsert').click();
                                }
                            });
                        },
                        branding: false
                    }}
                    onChange={this.handleEditorChange}
                />
            </div>

        );
    }
}

export default XcubeTinymce;
