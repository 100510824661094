import Moment from 'react-moment';
import React from 'react';
import * as moment from 'moment';

export const dataHelper = {
    formatDateHelper,
    daysLeftHelper,
    capitalizeString,
    isJsonString,
    conversionCalculator,
    secondsToTime,
    validURL,
    periodToHMS
};

function formatDateHelper(dateToFormat, format) {
    return (
        moment(dateToFormat).format(format)
    );
}

function daysLeftHelper(toDate) {
    return (
        <Moment fromNow>
            {toDate}
        </Moment>
    );
}

function capitalizeString(s) {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1)
}

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function conversionCalculator(players, leads) {
    let result = ((parseInt(leads) / parseInt(players)) * 100).toFixed(1);
    return result === 'Infinity' || result === 'NaN' ? 0 : result;
}

function secondsToTime(seconds, format) {
    let duration = moment.duration(Math.round(seconds), 'seconds');
    return duration.format(format);
}

function validURL(str) {
    let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

function periodToHMS(startDate, endDate) {
    let days = moment.duration(moment(endDate).diff(moment(startDate))).asDays();
    if (days === 0) return "hours"
    else if (days <= 31) return "days"
    else return "months";
}
